import accounting from 'accounting'
import { useContext } from 'react'
import Context from '@context/App'

export default function useAccounting() {

  const { currency } = useContext(Context)

  /**
   * Format a number with accounting money
   * @param {Float} number Number to format
   * @param {Object} options Object with options:
   *  - symbol: The currency symbol
   *  - format: A specific format for accounting
   *  - decimal: Symbol to divide the decimal digits
   *  - thousand: Symbol to divide each three digits
   *  - precision: Number of decimals
   *  - startSymbol: Symbol to show at start, before accounting object,
   *  - endSymbol: Symbol to show at the end, after accounting object,
   *  - templateFormat: Format for the complete string (%s for startSymbol, %a for accounting string 
   *      %e for endSymbol)
   *  - calculateExchange: Bool (NOT IMPLEMENTED YET)
   *  - inferStartSymbol: Bool. Infers the start symbol given the end symbol is one of the common currencies
   * @returns String
   */
  const format = (number, options = {}) => {
    const commonCurrencies = {
      'usd': '$',
      'mxn': '$',
    }

    const defaultOptions = {
      symbol : '$',
      format: '%s%v',
      decimal : '.',
      thousand: ',',
      precision : 2,
      ...options,
    }

    if(!options.startSymbol && options.inferStartSymbol &&
      Object.keys(commonCurrencies).find(c => c === options?.endSymbol?.toLowerCase())) {
      options.startSymbol = commonCurrencies[options.endSymbol.toLowerCase()]
    }

    const moneyFormatted = accounting.formatMoney(/*options.calculateExchange ? number * currency.exchange :*/ number, defaultOptions)

    const templateFormat = options.templateFormat ??
      (options.startSymbol
        ? (options.endSymbol ? '%s%a %e' : '%s%a')
        : (options.endSymbol ? '%a %e' : '%a'))

    const data = {
      s: options.startSymbol,
      a: moneyFormatted,
      e: options.endSymbol,
    }

    return templateFormat.replace(/%(\w+)/g, (match, key) => data[key] || match)
  }

  const formatNumber = (number, options) => {
    const defaultOptions = {
      precision : 3,
      thousand : ' ',
      decimal: '.',
      ...options,
    }
    return accounting.formatNumber(number, defaultOptions)
  }

  return {
    format,
    formatNumber,
  }
}
