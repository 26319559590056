/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getVenueZonesListService } from '@services/venue/venueZone/getVenueZoneList'

export default function useVenueZone() {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(false)

  const getVenueZones = useCallback(
    (variables, options = {}) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          setLoadingSource('list')
          const response = await getVenueZonesListService(apolloClient, { variables, options })
          resolve(response.forumZones)
          setLoading(false)
          setLoadingSource(null)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  return {
    loading,
    loadingSource,
    getVenueZones,
  }
}
