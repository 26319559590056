import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const VenueLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7 5L3 7V3L7 5ZM18 3V7L22 5L18 3ZM11 2V6L15 4L11 2ZM5 10C6.4 10.5 8.8 11 12 11C15.2 11 17.6 10.5 19 10C19 9.8 16.2 9 12 9C7.8 9 5 9.9 5 10ZM15 17H9V21.9C4.9 21.5 2 20.4 2 19V10C2 8.3 6.5 7 12 7C17.5 7 22 8.3 22 10V19C22 20.3 19.1 21.5 15 21.9V17Z'
      fill={color}
    />
  </SvgIcon>
)

const VenueDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7 5L3 7V3L7 5ZM18 3V7L22 5L18 3ZM11 2V6L15 4L11 2ZM5 10C6.4 10.5 8.8 11 12 11C15.2 11 17.6 10.5 19 10C19 9.8 16.2 9 12 9C7.8 9 5 9.9 5 10ZM15 17H9V21.9C4.9 21.5 2 20.4 2 19V10C2 8.3 6.5 7 12 7C17.5 7 22 8.3 22 10V19C22 20.3 19.1 21.5 15 21.9V17Z'
      fill={color}
    />
  </SvgIcon>
)

const VenueIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <VenueDarkIcon {...props} /> : <VenueLightIcon {...props} />
}

export default VenueIcon
