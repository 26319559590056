import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const FacebookLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M15.7174 12.4938H12.9904V21.9705H8.92229V12.4938H7V9.15422H8.92229V6.98565C8.91564 6.40066 9.02177 5.81962 9.23522 5.27248C9.46982 4.63592 9.89863 4.08376 10.4646 3.68943C11.2174 3.20345 12.1092 2.96058 13.0128 2.99549L16.0303 3.01716V6.27002H13.8398C13.6384 6.26306 13.4408 6.32441 13.281 6.44351C13.1788 6.53748 13.0996 6.65252 13.0493 6.78013C12.999 6.90774 12.9789 7.04467 12.9904 7.18083V9.15422H16.075L15.7174 12.4938Z'
      fill={color}
    />
  </SvgIcon>
)

const FacebookDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M15.7174 12.4938H12.9904V21.9705H8.92229V12.4938H7V9.15422H8.92229V6.98565C8.91564 6.40066 9.02177 5.81962 9.23522 5.27248C9.46982 4.63592 9.89863 4.08376 10.4646 3.68943C11.2174 3.20345 12.1092 2.96058 13.0128 2.99549L16.0303 3.01716V6.27002H13.8398C13.6384 6.26306 13.4408 6.32441 13.281 6.44351C13.1788 6.53748 13.0996 6.65252 13.0493 6.78013C12.999 6.90774 12.9789 7.04467 12.9904 7.18083V9.15422H16.075L15.7174 12.4938Z'
      fill={color}
    />
  </SvgIcon>
)

const FacebookIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <FacebookDarkIcon {...props} /> : <FacebookLightIcon {...props} />
}

export default FacebookIcon
