import { Fragment }  from 'react'
import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import Slider from 'react-slick'

import useTheme from '@hooks/useTheme'
import ChevronLeftIcon from '@icons/ChevronLeftIcon'
import ChevronRightIcon from '@icons/ChevronRightIcon'

const StyledSlider = styled(props => <Slider {...props}/>)(({ theme }) => ({
  width: '100%',
  '& .slick-track': {
    display: 'flex',
    gap: theme.spacing(2),
  },
  '& .slick-slide': {
    padding: `${theme.spacing(2)} 0`,
    minWidth: '0px',
  },
  '& .slick-arrow': {
    '& path': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover path': {
      stroke: theme.palette.gray[700],
    },
  },
}))

const ContainerMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  overflowX: 'scroll',
  width: '100vw',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  scrollSnapType: 'x mandatory',
  '&>*': {
    scrollSnapAlign: 'center',
  },
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
    backgroundColor: 'transparent',
  },
}))

const CustomSlider = ({ children, ...props }) => {

  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const settings = {
    dots: false,
    speed: 500,
    arrows: true,
    nextArrow: <ChevronRightIcon/>,
    prevArrow: <ChevronLeftIcon/>,
  }

  return <Fragment>
    {
      isMd
        ? <StyledSlider {...settings} {...props}>
          {children}
        </StyledSlider>
        : <ContainerMobile>
          { children }
        </ContainerMobile>
    }
  </Fragment>
}

export default CustomSlider
