/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const FilterLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M10 18.7207H14V16.7207H10V18.7207ZM3 6.7207V8.7207H21V6.7207H3ZM6 13.7207H18V11.7207H6V13.7207Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const FilterDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M10 18.7207H14V16.7207H10V18.7207ZM3 6.7207V8.7207H21V6.7207H3ZM6 13.7207H18V11.7207H6V13.7207Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const FilterIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <FilterDarkIcon {...props} />
    : <FilterLightIcon {...props} />
}

export default FilterIcon
