/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const GoogleLightIcon = () => (
  <SvgIcon width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M47.5325 24.5525C47.5325 22.921 47.4002 21.2808 47.118 19.6758H24.4805V28.9177H37.4439C36.906 31.8985 35.1775 34.5352 32.6466 36.2108V42.2075H40.3806C44.9222 38.0274 47.5325 31.8544 47.5325 24.5525Z' fill='#4285F4'/>
    <path d='M24.4802 48.0026C30.9531 48.0026 36.4119 45.8773 40.3891 42.2088L32.6551 36.2121C30.5034 37.676 27.7255 38.5049 24.489 38.5049C18.2278 38.5049 12.9189 34.2808 11.0141 28.6016H3.0332V34.7834C7.10743 42.8878 15.4058 48.0026 24.4802 48.0026Z' fill='#34A853'/>
    <path d='M11.0055 28.6014C10.0002 25.6207 10.0002 22.3931 11.0055 19.4124V13.2305H3.03347C-0.370533 20.012 -0.370533 28.0017 3.03347 34.7833L11.0055 28.6014Z' fill='#FBBC04'/>
    <path d='M24.4802 9.49932C27.9018 9.44641 31.2088 10.7339 33.6869 13.0973L40.539 6.24523C36.2002 2.17101 30.4416 -0.068932 24.4802 0.00161733C15.4058 0.00161733 7.10743 5.11644 3.0332 13.2296L11.0053 19.4115C12.9013 13.7235 18.2189 9.49932 24.4802 9.49932Z' fill='#EA4335'/>
  </SvgIcon>
)

const GoogleDarkIcon = () => (
  <SvgIcon width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M47.5325 24.5525C47.5325 22.921 47.4002 21.2808 47.118 19.6758H24.4805V28.9177H37.4439C36.906 31.8985 35.1775 34.5352 32.6466 36.2108V42.2075H40.3806C44.9222 38.0274 47.5325 31.8544 47.5325 24.5525Z' fill='#4285F4'/>
    <path d='M24.4802 48.0026C30.9531 48.0026 36.4119 45.8773 40.3891 42.2088L32.6551 36.2121C30.5034 37.676 27.7255 38.5049 24.489 38.5049C18.2278 38.5049 12.9189 34.2808 11.0141 28.6016H3.0332V34.7834C7.10743 42.8878 15.4058 48.0026 24.4802 48.0026Z' fill='#34A853'/>
    <path d='M11.0055 28.6014C10.0002 25.6207 10.0002 22.3931 11.0055 19.4124V13.2305H3.03347C-0.370533 20.012 -0.370533 28.0017 3.03347 34.7833L11.0055 28.6014Z' fill='#FBBC04'/>
    <path d='M24.4802 9.49932C27.9018 9.44641 31.2088 10.7339 33.6869 13.0973L40.539 6.24523C36.2002 2.17101 30.4416 -0.068932 24.4802 0.00161733C15.4058 0.00161733 7.10743 5.11644 3.0332 13.2296L11.0053 19.4115C12.9013 13.7235 18.2189 9.49932 24.4802 9.49932Z' fill='#EA4335'/>
  </SvgIcon>
)

const GoogleIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <GoogleDarkIcon {...props} /> : <GoogleLightIcon {...props} />
}

export default GoogleIcon
