import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const SeatLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M3.99963 21H6.99963V18H16.9996V21H19.9996V15H3.99963V21ZM18.9996 10H21.9996V13H18.9996V10ZM1.99963 10H4.99963V13H1.99963V10ZM16.9996 13H6.99963V5C6.99963 3.9 7.89963 3 8.99963 3H14.9996C16.0996 3 16.9996 3.9 16.9996 5V13Z'
      fill={color}
    />
  </SvgIcon>
)

const SeatDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M3.99963 21H6.99963V18H16.9996V21H19.9996V15H3.99963V21ZM18.9996 10H21.9996V13H18.9996V10ZM1.99963 10H4.99963V13H1.99963V10ZM16.9996 13H6.99963V5C6.99963 3.9 7.89963 3 8.99963 3H14.9996C16.0996 3 16.9996 3.9 16.9996 5V13Z'
      fill={color}
    />
  </SvgIcon>
)

const SeatIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <SeatDarkIcon {...props} /> : <SeatLightIcon {...props} />
}

export default SeatIcon
