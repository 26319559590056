import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import CustomCalendarPicker from '@components/CustomCalendarPicker'

/**
 * @param onRangeChange Function which receives an object with properties startDate and endDate
 * @param initialRange Objects with properties startDate and endDate
 * @param props
 * @returns {Element}
 * @constructor
 */
const DateRangePickerFlat = ({ onRangeChange, initialRange, ...props }) => {

  const [dateRange, setDateRange] = useState({
    from: initialRange.from ? initialRange.from.$d : null,
    to: initialRange.to ? initialRange.to.$d : null,
  })

  const dateRangeChangeHandler = useCallback( newRange => {
    if(newRange) {
      const _newRange = {
        from: dayjs(newRange.from),
        to: newRange.to ? dayjs(newRange.to).endOf('day') : dayjs(newRange.from).endOf('day'),
      }
      setDateRange({ from: _newRange.from.$d, to: _newRange.to.$d })
      onRangeChange(_newRange)
    }
  }, [setDateRange])

  useEffect(() => {
    setDateRange({
      from: initialRange.from ? initialRange.from.$d : null,
      to: initialRange.to ? initialRange.to.$d : null,
    })
  }, [initialRange])

  return <CustomCalendarPicker selected={dateRange} setSelected={dateRangeChangeHandler}/>
}

export default DateRangePickerFlat
