import { styled, useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import CustomButton from './CustomButton'

const StyledButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  '& svg': {
    //fill: 'transparent',
  },
  height: '100%',
  color: theme.palette.gray['600'],
  border: '1px solid',
  borderColor: theme.palette.gray['600'],
  borderRadius: '5px',
  padding: `${theme.spacing(0.4)} ${theme.spacing(0.8)}`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& .MuiBox-root': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '&:hover': {
    //backgroundColor: theme.palette.gray['600'], //'#eee',
    borderColor: theme.palette.gray.main,
    //color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('md')]: {
    font: theme.font.roboto.paragraph.small,
  },
}))

const BoxLabel = styled(props => <Box {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  color: theme.palette.gray[600],
  fontWeight: '550',
  '&:hover':{
    color: theme.palette.primary.contrastText,
  },
}))

const FilterChipButton = ({ label, icon, onClick, onIconClick, highlighted = false, ...props }) => {

  const theme = useTheme()

  return <StyledButton onClick={onClick}
    sx={ highlighted ?
      {
        '& svg': {
          fill: theme.palette.gray['600'],
        },
        '& fieldset': {
          border: '1px solid',
        },
        backgroundColor: 'transparent',
        color: theme.palette.gray['600'],
        '&:hover': {
          backgroundColor: theme.palette.gray['500'],
        },
      }
      : {}
    }
  >
    { label && <BoxLabel>{label}</BoxLabel> }
    { icon && <Box onClick={e => {
      if(onIconClick) onIconClick(e)
    }}>{icon}</Box> }
  </StyledButton>
}
export default FilterChipButton
