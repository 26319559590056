import { styled } from '@mui/material/styles'
import { AccordionDetails } from '@mui/material'

const StyledAccordionDetails = styled(props => <AccordionDetails {...props}/>)(
  ({ theme }) => ({
    padding: '0',
  })
)

const CollapsableDetails = props => {

  return <StyledAccordionDetails
    {...props}
  />
}

export default CollapsableDetails
