'use client'
import { ThemeProvider } from '@mui/material/styles'
import { createContext, useCallback, useMemo, useState } from 'react'
import { DarkTheme, LightTheme } from '@features/Theme'
import { CssBaseline } from '@mui/material'

const THEMES_KEYS = {
  light: LightTheme,
  dark: DarkTheme,
}

const LIGHT_THEME = 'light'
const DARK_THEME = 'dark'

export const ThemeCustomContext = createContext({})

export default function ThemeContextProvider({ children }) {

  const isDarkMode = useCallback(() => false, [])
  const getTheme = useCallback(() => (isDarkMode() ? DARK_THEME : LIGHT_THEME), [isDarkMode])

  const [activeTheme, setActiveTheme] = useState(THEMES_KEYS[getTheme()])
  const [selectedTheme, setSelectedTheme] = useState(getTheme())

  const toggleTheme = useCallback(() => {
    if (selectedTheme === LIGHT_THEME) {
      setActiveTheme(THEMES_KEYS[DARK_THEME])
      setSelectedTheme(DARK_THEME)
    } else {
      setActiveTheme(THEMES_KEYS[LIGHT_THEME])
      setSelectedTheme(LIGHT_THEME)
    }
  }, [activeTheme, selectedTheme])

  const memorizedValue = useMemo(
    () => ({
      theme: selectedTheme,
      darkMode: activeTheme === THEMES_KEYS[DARK_THEME],
      toggleTheme,
    }),
    [selectedTheme, toggleTheme, activeTheme]
  )

  return <ThemeCustomContext.Provider value={memorizedValue}>
    <ThemeProvider theme={activeTheme}>
      <CssBaseline/>
      { children }
    </ThemeProvider>
  </ThemeCustomContext.Provider>

}
