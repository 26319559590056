import { useState } from 'react'
import { Box, Menu, MenuItem } from '@mui/material'
import { FilterChipDropdown } from '@App/Components'
import { useGlossary } from '@App/hooks'

const BoxEventStatusFilter = ({ initialStatus, onStatusChange }) => {

  const { glossary } = useGlossary()
  const [anchorEl, setAnchorEl] = useState(null)
  const [status, setStatus] = useState(initialStatus)
  const open = Boolean(anchorEl)

  const allStatus = [
    'published',
    'paused',
    'draft',
    'reserved',
    'confirmed',
    'all',
  ]

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  return <Box>
    <FilterChipDropdown
      onClick={handleOpen}
      open={open}
      label={status ? glossary(status) : glossary('Status')}
      highlighted={true}
    />
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    > {
        allStatus.map(s =>
          <MenuItem
            key={s}
            selected={ status === s }
            onClick={() => {
              if (status === s) {
                setStatus(undefined)
                onStatusChange(undefined)
              } else {
                setStatus(s)
                onStatusChange(s)
              }
              handleClose()
            }}
          >
            {glossary(s)}
          </MenuItem>
        )
      }
    </Menu>
  </Box>
}

export default BoxEventStatusFilter
