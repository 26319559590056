import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ShoppingOutlinedLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='18' height='17' viewBox='0 0 18 17' fill='none'>
    <path d="M7.00065 15.6663C7.36884 15.6663 7.66732 15.3679 7.66732 14.9997C7.66732 14.6315 7.36884 14.333 7.00065 14.333C6.63246 14.333 6.33398 14.6315 6.33398 14.9997C6.33398 15.3679 6.63246 15.6663 7.00065 15.6663Z" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
    <path d="M14.3337 15.6663C14.7018 15.6663 15.0003 15.3679 15.0003 14.9997C15.0003 14.6315 14.7018 14.333 14.3337 14.333C13.9655 14.333 13.667 14.6315 13.667 14.9997C13.667 15.3679 13.9655 15.6663 14.3337 15.6663Z" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
    <path d="M1.66699 1.6665H4.33366L6.12033 10.5932C6.18129 10.9001 6.34826 11.1758 6.59202 11.372C6.83578 11.5683 7.14079 11.6725 7.45366 11.6665H13.9337C14.2465 11.6725 14.5515 11.5683 14.7953 11.372C15.0391 11.1758 15.206 10.9001 15.267 10.5932L16.3337 4.99984H5.00033" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
  </SvgIcon>
)

const ShoppingOutlinedDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='18' height='17' viewBox='0 0 18 17' fill='none'>
    <path d="M7.00065 15.6663C7.36884 15.6663 7.66732 15.3679 7.66732 14.9997C7.66732 14.6315 7.36884 14.333 7.00065 14.333C6.63246 14.333 6.33398 14.6315 6.33398 14.9997C6.33398 15.3679 6.63246 15.6663 7.00065 15.6663Z" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
    <path d="M14.3337 15.6663C14.7018 15.6663 15.0003 15.3679 15.0003 14.9997C15.0003 14.6315 14.7018 14.333 14.3337 14.333C13.9655 14.333 13.667 14.6315 13.667 14.9997C13.667 15.3679 13.9655 15.6663 14.3337 15.6663Z" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
    <path d="M1.66699 1.6665H4.33366L6.12033 10.5932C6.18129 10.9001 6.34826 11.1758 6.59202 11.372C6.83578 11.5683 7.14079 11.6725 7.45366 11.6665H13.9337C14.2465 11.6725 14.5515 11.5683 14.7953 11.372C15.0391 11.1758 15.206 10.9001 15.267 10.5932L16.3337 4.99984H5.00033" 
      stroke="#A3B7C8" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round"/>
  </SvgIcon>
)

const ShoppingOutlinedIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ShoppingOutlinedDarkIcon {...props} /> : <ShoppingOutlinedLightIcon {...props} />
}

export default ShoppingOutlinedIcon
