import { styled } from '@mui/material/styles'
import { Popover, Box } from '@mui/material'

const PopoverStyled = styled(props => <Popover {...props}/>)(({ theme }) => ({
  '& .MuiPaper-root': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.background[90],
    backdropFilter: 'blur(2px)',
  },
}))

const PopoverContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const CustomPopover = ({ children, ...props }) => {

  return <PopoverStyled
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    elevation={1}
    {...props}
  >
    <PopoverContent>
      { children }
    </PopoverContent>
  </PopoverStyled>
}

export default CustomPopover
