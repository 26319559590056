import { useMemo } from 'react'

import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, split } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import baseConfig from '@baseConfig'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import withApollo from 'next-with-apollo'

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`)
})

const httpLink = new HttpLink({
  fetch,
  uri: baseConfig.httpsUri,
  credentials: 'include',
  withCredentials: true,
})

const retryLink = new RetryLink({
  delay: {
    initial: 300, // Start retrying after 300ms
    max: 10000, // Maximum retry delay of 10 seconds
    jitter: true, // Add some randomness to retry delays
  },
  attempts: {
    max: 5, // Retry up to 5 times
    retryIf: (error, operation) => !!error, // Retry on any error
  },
})

/**
 * Crea clientes apollo
 *
 * @param {String|null} agentId ID de agente
 * @returns Apollo Client
 */
const createApolloClient = (agentId = null, ssrMode = false) => {
  const _inMemoryCache = new InMemoryCache({
    typePolicies: {
      EventCategory: {
        keyFields: false,
      },
    },
  })

  if (!agentId?.length) {
    return new ApolloClient({
      ssrMode,
      link: ApolloLink.from([retryLink, errorLink, httpLink]),
      cache: _inMemoryCache,
    })
  }

  const wsLink = new WebSocketLink(
    new SubscriptionClient(
      baseConfig.wssUri,
      {
        reconnect: true, // Automatically reconnect on disconnection
        connectionParams: {
          authToken: agentId,
        },
      },
      W3CWebSocket
    )
  )

  const apolloLink = ApolloLink.from([
    retryLink,
    errorLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      httpLink
    ),
  ])

  return new ApolloClient({
    link: apolloLink,
    ssrMode,
    cache: _inMemoryCache,
  })
}

const useApollo = (agentId = null) => {
  const apolloClient = useMemo(() => createApolloClient(agentId), [agentId])
  return {
    apolloClient,
  }
}

const createWithApollo = app => {
  const ssrLink = ApolloLink.from([errorLink, httpLink])

  return withApollo(
    () =>
      new ApolloClient({
        ssrMode: true,
        link: ssrLink,
        cache: new InMemoryCache(),
      })
  )(app)
}

export { createApolloClient, useApollo, createWithApollo }
