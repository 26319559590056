import { createContext } from 'react'

const initialState = {
  state: true,
  filter: '',
  uploads: [],
  uploadFiles: (files = [], path = '') => {
    files.push(path)
  },
}

const UploadContext = createContext(initialState)

export default UploadContext
