import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const TransferLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='43' height='46' viewBox='0 0 43 46' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M40.85 17.5231C41.1324 17.5232 41.412 17.4666 41.6729 17.3566C41.9337 17.2465 42.1708 17.0851 42.3704 16.8817C42.5701 16.6783 42.7285 16.4368 42.8365 16.171C42.9445 15.9052 43.0001 15.6203 43 15.3326V8.76103C43.0002 8.30141 42.8583 7.85339 42.5945 7.48055C42.3307 7.10771 41.9583 6.82897 41.5303 6.68389L22.1803 0.112307C21.7387 -0.0374357 21.2613 -0.0374357 20.8197 0.112307L1.46972 6.68389C1.04166 6.82897 0.669313 7.10771 0.405503 7.48055C0.141692 7.85339 -0.000185183 8.30141 1.81404e-07 8.76103V15.3326C-7.32615e-05 15.6203 0.0554882 15.9052 0.16351 16.171C0.271531 16.4368 0.429896 16.6783 0.629557 16.8817C0.829218 17.0851 1.06626 17.2465 1.32714 17.3566C1.58803 17.4666 1.86764 17.5232 2.15 17.5231H4.3V33.2605C3.04598 33.7105 1.95944 34.545 1.18952 35.6494C0.419614 36.7537 0.00409032 38.0738 1.81404e-07 39.4284V43.8095C-7.32615e-05 44.0972 0.0554882 44.382 0.16351 44.6478C0.271531 44.9136 0.429896 45.1552 0.629557 45.3586C0.829218 45.562 1.06626 45.7234 1.32714 45.8334C1.58803 45.9435 1.86764 46.0001 2.15 46H40.85C41.1324 46.0001 41.412 45.9435 41.6729 45.8334C41.9337 45.7234 42.1708 45.562 42.3704 45.3586C42.5701 45.1552 42.7285 44.9136 42.8365 44.6478C42.9445 44.382 43.0001 44.0972 43 43.8095V39.4284C42.9959 38.0738 42.5804 36.7537 41.8105 35.6494C41.0406 34.545 39.954 33.7105 38.7 33.2605V17.5231H40.85ZM38.7 41.6189H4.3V39.4284C4.30057 38.8476 4.52727 38.2908 4.93035 37.8801C5.33343 37.4694 5.87996 37.2385 6.45 37.2379H36.55C37.12 37.2385 37.6666 37.4694 38.0696 37.8801C38.4727 38.2908 38.6994 38.8476 38.7 39.4284V41.6189ZM8.6 32.8568V17.5231H12.9V32.8568H8.6ZM17.2 32.8568V17.5231H25.8V32.8568H17.2ZM30.1 32.8568V17.5231H34.4V32.8568H30.1ZM4.3 13.1421V10.3397L21.5 4.49761L38.7 10.3397V13.1421H4.3Z' 
      fill={color}
    />
  </SvgIcon>
)

const TransferDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M40.85 17.5231C41.1324 17.5232 41.412 17.4666 41.6729 17.3566C41.9337 17.2465 42.1708 17.0851 42.3704 16.8817C42.5701 16.6783 42.7285 16.4368 42.8365 16.171C42.9445 15.9052 43.0001 15.6203 43 15.3326V8.76103C43.0002 8.30141 42.8583 7.85339 42.5945 7.48055C42.3307 7.10771 41.9583 6.82897 41.5303 6.68389L22.1803 0.112307C21.7387 -0.0374357 21.2613 -0.0374357 20.8197 0.112307L1.46972 6.68389C1.04166 6.82897 0.669313 7.10771 0.405503 7.48055C0.141692 7.85339 -0.000185183 8.30141 1.81404e-07 8.76103V15.3326C-7.32615e-05 15.6203 0.0554882 15.9052 0.16351 16.171C0.271531 16.4368 0.429896 16.6783 0.629557 16.8817C0.829218 17.0851 1.06626 17.2465 1.32714 17.3566C1.58803 17.4666 1.86764 17.5232 2.15 17.5231H4.3V33.2605C3.04598 33.7105 1.95944 34.545 1.18952 35.6494C0.419614 36.7537 0.00409032 38.0738 1.81404e-07 39.4284V43.8095C-7.32615e-05 44.0972 0.0554882 44.382 0.16351 44.6478C0.271531 44.9136 0.429896 45.1552 0.629557 45.3586C0.829218 45.562 1.06626 45.7234 1.32714 45.8334C1.58803 45.9435 1.86764 46.0001 2.15 46H40.85C41.1324 46.0001 41.412 45.9435 41.6729 45.8334C41.9337 45.7234 42.1708 45.562 42.3704 45.3586C42.5701 45.1552 42.7285 44.9136 42.8365 44.6478C42.9445 44.382 43.0001 44.0972 43 43.8095V39.4284C42.9959 38.0738 42.5804 36.7537 41.8105 35.6494C41.0406 34.545 39.954 33.7105 38.7 33.2605V17.5231H40.85ZM38.7 41.6189H4.3V39.4284C4.30057 38.8476 4.52727 38.2908 4.93035 37.8801C5.33343 37.4694 5.87996 37.2385 6.45 37.2379H36.55C37.12 37.2385 37.6666 37.4694 38.0696 37.8801C38.4727 38.2908 38.6994 38.8476 38.7 39.4284V41.6189ZM8.6 32.8568V17.5231H12.9V32.8568H8.6ZM17.2 32.8568V17.5231H25.8V32.8568H17.2ZM30.1 32.8568V17.5231H34.4V32.8568H30.1ZM4.3 13.1421V10.3397L21.5 4.49761L38.7 10.3397V13.1421H4.3Z' 
      fill={color}
    />
  </SvgIcon>
)

const TransferIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <TransferDarkIcon {...props} /> : <TransferLightIcon {...props} />
}

export default TransferIcon
