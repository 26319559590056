/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CoffeeLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.4762 8.64999H18.381C19.3408 8.64999 20.2613 9.05035 20.94 9.76299C21.6187 10.4756 22 11.4422 22 12.45C22 13.4578 21.6187 14.4244 20.94 15.137C20.2613 15.8496 19.3408 16.25 18.381 16.25H17.4762'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M3 8.64999H17.4762V17.2C17.4762 18.2078 17.0949 19.1744 16.4162 19.887C15.7375 20.5996 14.817 21 13.8571 21H6.61905C5.65922 21 4.7387 20.5996 4.05999 19.887C3.38129 19.1744 3 18.2078 3 17.2V8.64999Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M6.61905 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M10.2381 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M13.8571 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const CoffeeDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.4762 8.64999H18.381C19.3408 8.64999 20.2613 9.05035 20.94 9.76299C21.6187 10.4756 22 11.4422 22 12.45C22 13.4578 21.6187 14.4244 20.94 15.137C20.2613 15.8496 19.3408 16.25 18.381 16.25H17.4762'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M3 8.64999H17.4762V17.2C17.4762 18.2078 17.0949 19.1744 16.4162 19.887C15.7375 20.5996 14.817 21 13.8571 21H6.61905C5.65922 21 4.7387 20.5996 4.05999 19.887C3.38129 19.1744 3 18.2078 3 17.2V8.64999Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M6.61905 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M10.2381 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M13.8571 2V4.85'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const CoffeeIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <CoffeeDarkIcon {...props} />
    : <CoffeeLightIcon {...props} />
}

export default CoffeeIcon
