/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const VerticalDotsLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M13.8 11.6586C13.8 12.6527 12.9941 13.4586 12 13.4586C11.0059 13.4586 10.2 12.6527 10.2 11.6586C10.2 10.6645 11.0059 9.85859 12 9.85859C12.9941 9.85859 13.8 10.6645 13.8 11.6586Z M13.8 17.2586C13.8 18.2527 12.9941 19.0586 12 19.0586C11.0059 19.0586 10.2 18.2527 10.2 17.2586C10.2 16.2645 11.0059 15.4586 12 15.4586C12.9941 15.4586 13.8 16.2645 13.8 17.2586Z M13.8 6.0586C13.8 7.05271 12.9941 7.8586 12 7.8586C11.0059 7.8586 10.2 7.05271 10.2 6.0586C10.2 5.06448 11.0059 4.2586 12 4.2586C12.9941 4.2586 13.8 5.06449 13.8 6.0586Z'
      fill={color}
    />
  </SvgIcon>
)

const VerticalDotsDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M13.8 11.6586C13.8 12.6527 12.9941 13.4586 12 13.4586C11.0059 13.4586 10.2 12.6527 10.2 11.6586C10.2 10.6645 11.0059 9.85859 12 9.85859C12.9941 9.85859 13.8 10.6645 13.8 11.6586Z M13.8 17.2586C13.8 18.2527 12.9941 19.0586 12 19.0586C11.0059 19.0586 10.2 18.2527 10.2 17.2586C10.2 16.2645 11.0059 15.4586 12 15.4586C12.9941 15.4586 13.8 16.2645 13.8 17.2586Z M13.8 6.0586C13.8 7.05271 12.9941 7.8586 12 7.8586C11.0059 7.8586 10.2 7.05271 10.2 6.0586C10.2 5.06448 11.0059 4.2586 12 4.2586C12.9941 4.2586 13.8 5.06449 13.8 6.0586Z'
      fill={color}
    />
  </SvgIcon>
)

const VerticalDotsIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <VerticalDotsDarkIcon {...props} />
    : <VerticalDotsLightIcon {...props} />
}

export default VerticalDotsIcon
