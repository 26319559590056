import { GoogleMap, withGoogleMap, withScriptjs, Marker } from 'react-google-maps'

const Map = ({ coordinates }) => {

  return <GoogleMap defaultZoom={17}
    center={{ lat: coordinates ? coordinates.lat : 19.432608,
      lng: coordinates ? coordinates.lng: -99.133209 }}>

    <Marker position={{
      lat: coordinates ? coordinates.lat : 19.432608,
      lng: coordinates ? coordinates.lng: -99.133209,
    }} draggable={true}
    />
  </GoogleMap>
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

export default WrappedMap
