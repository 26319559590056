import { useEffect, useState, useCallback } from 'react'
import { Chip, TextField } from '@mui/material'

import useDebounce from '@hooks/useDebounce'
import useVenue from '@hooks/useVenue'
import useGlossary from '@hooks/useGlossary'
import CustomAutocomplete from '@components/CustomAutocomplete'
import { getLocationQuery } from '@helpers/location'

const ForumFilter = ({ initialSelectedForums=[], onSelectForums, selectedLocation, maxSelected = 5  }) => {

  const { glossary } = useGlossary()
  const { getVenues } = useVenue()

  const [selectedForums, setSelectedForums] = useState(initialSelectedForums)
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [queryVenues, setQueryVenues] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const debouncedQuery = useDebounce(query, 500)

  const fetchOptions = useCallback( query => {
    if(query.length === 0) return
    setIsLoading(true)
    const filter = {
      num: 12,
      pag: 0,
      filter: {
        ...getLocationQuery(selectedLocation),
        query,
      },
    }
    getVenues(filter).then(resp => {
      setQueryVenues(resp.edges.map(edge => edge.node))
    }).finally(() => {
      setIsLoading(false)
    })
  }, [selectedLocation])

  const handleSelectionChange = (event, newSelection) => {
    event.stopPropagation()
    const _val = []
    newSelection.map( f => _val.push(f))
    setSelectedForums(_val)
    setIsDisabled(_val.length >= maxSelected)
    if(onSelectForums) onSelectForums(_val)
  }

  const handleTagDelete = useCallback((event, option) => {
    const newSelection = selectedForums.filter(f => f.id !== option.id)
    handleSelectionChange(event, newSelection)
  }, [selectedForums])

  const handleInputChange = (event, newInput) => {
    setQuery(newInput)
  }

  useEffect(() => {
    setSelectedForums(initialSelectedForums)
  }, [initialSelectedForums])

  useEffect(() => {
    fetchOptions(debouncedQuery)
  }, [debouncedQuery])

  return <CustomAutocomplete
    multiple
    id={'forum-filter-autocomplete'}
    filterSelectedOptions={true}
    options={queryVenues}
    freeSolo={true}
    value={selectedForums}
    getOptionLabel={option => option.name}
    onChange={handleSelectionChange}
    onInputChange = {handleInputChange}
    renderInput={params => (
      <TextField {...params}
        variant='outlined'
        label={glossary('SelectForum')}
      />
    )}
    disabled={isDisabled}
    limitTags={maxSelected}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          key={index}
          label={option.name}
          sx={{ maxWidth: '140px' }}
          onDelete={e => handleTagDelete(e, option)}
        />
      ))
    }
    loading={debouncedQuery.length === 0 || isLoading}
    loadingText={isLoading ? glossary('Searching') : glossary('TypeToSearch')}
    noOptionsText={glossary('NoOptions')}
    sx={{ width:'100%', height:'100%' }}
  />
}

export default ForumFilter
