/* eslint-disable no-console */
import { logout as logoutQuery } from '@graphql/auth.graphql'

export const logoutService = async apolloClient => {
  try {
    const resp = await apolloClient.mutate({
      mutation: logoutQuery,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
