import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomAutocomplete from './CustomAutocomplete'

const Container = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
  flexDirection: 'column',
}))

const Label = styled(props => <Box {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const CustomInputAutocomplete = ({ label, ...props }) => {

  return <Container sx={props.sx}>
    <Label>
      { label }
    </Label>
    <CustomAutocomplete
      {...props}
    />
  </Container>
}

export default CustomInputAutocomplete
