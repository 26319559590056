/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CopyLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path d='M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z' 
      stroke='#5062D3' 
      strokeWidth='2' 
      strokeLinecap='round' 
      strokeLinejoin='round'
    />
    <path d='M4.1665 12.5003H3.33317C2.89114 12.5003 2.46722 12.3247 2.15466 12.0122C1.8421 11.6996 1.6665 11.2757 1.6665 10.8337V3.33366C1.6665 2.89163 1.8421 2.46771 2.15466 2.15515C2.46722 1.84259 2.89114 1.66699 3.33317 1.66699H10.8332C11.2752 1.66699 11.6991 1.84259 12.0117 2.15515C12.3242 2.46771 12.4998 2.89163 12.4998 3.33366V4.16699' 
      stroke='#5062D3' 
      strokeWidth='2' 
      strokeLinecap='round' 
      strokeLinejoin='round'
    />

  </SvgIcon>
) 

const CopyDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path d='M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z' 
      stroke='#5062D3' 
      strokeWidth='2' 
      strokeLinecap='round' 
      strokeLinejoin='round'
    />
    <path d='M4.1665 12.5003H3.33317C2.89114 12.5003 2.46722 12.3247 2.15466 12.0122C1.8421 11.6996 1.6665 11.2757 1.6665 10.8337V3.33366C1.6665 2.89163 1.8421 2.46771 2.15466 2.15515C2.46722 1.84259 2.89114 1.66699 3.33317 1.66699H10.8332C11.2752 1.66699 11.6991 1.84259 12.0117 2.15515C12.3242 2.46771 12.4998 2.89163 12.4998 3.33366V4.16699' 
      stroke='#5062D3' 
      strokeWidth='2' 
      strokeLinecap='round' 
      strokeLinejoin='round'
    />

  </SvgIcon>
)

const CopyIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <CopyDarkIcon {...props} />
    : <CopyLightIcon {...props} />
}

export default CopyIcon
