/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getPublicationsListService } from '@services/publication/getPublicationList'
import { getPublicationService } from '@services/publication/getPublication'
import { getMinMaxMeanPriceService } from '@services/publication/getMinMaxMeanPrice'
import { updatePublicationService } from '@services/publication/updatePublication'

export default function usePublication() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getPublications = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getPublicationsListService(apolloClient, { variables, options })
        resolve(response.boxEvents)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getPublication = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPublicationService(apolloClient, { id, options })
        resolve(response.boxEventId)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getMinMaxMeanPrice = useCallback((eventId, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getMinMaxMeanPriceService(apolloClient, { eventId, options })
        resolve(response.getMinMaxAndMean)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updatePublication = useCallback(({ input }) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = { input }
        const response = await updatePublicationService(apolloClient, { variables })
        resolve(response.updateBoxEvent)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updatePublicationConsistent = useCallback(({ input, oldPublication }) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const current = await getPublicationService(apolloClient, {
          id: input.id,
          options: { fetchPolicy: 'network-only' },
        })
        if(current.boxEventId.rentedSeats !== oldPublication.rentedSeats ||
          (current.boxEventId.rentedSeats>0 && parseInt(current.boxEventId.type.id)===1)) {
          throw new Error('Attempted to update a publication that had already changed')
        }
        const variables = { input }
        const response = await updatePublicationService(apolloClient, { variables })
        resolve(response.updateBoxEvent)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    loadingSource,
    getPublications,
    getPublication,
    getMinMaxMeanPrice,
    updatePublication,
    updatePublicationConsistent,
  }
}
