import { Box } from '@mui/material'
import baseConfig from '@baseConfig'
import { ActionBox } from '../DocumentUploader/styles'

const ImagePreview = ({ fileId, moreActions }) => {

  return <Box
    sx={{
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      borderRadius: '8px',
    }}
  >
    <img
      src={`${baseConfig.previewRoot}/${fileId}`}
      alt=''
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    />
    <ActionBox>
      {
        moreActions
      }
    </ActionBox>
    {/* <DeleteButton onClick={deleteImage}/> */}
  </Box>
}

export default ImagePreview
