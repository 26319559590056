/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CircleCheckIconLight = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='18' height='19' viewBox='0 0 18 19' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd' 
      clipRule='evenodd' 
      d='M9 18.5C10.1819 18.5 11.3522 18.2672 12.4442 17.8149C13.5361 17.3626 14.5282 16.6997 15.364 15.864C16.1997 15.0282 16.8626 14.0361 17.3149 12.9442C17.7672 11.8522 18 10.6819 18 9.5C18 8.3181 17.7672 7.14778 17.3149 6.05585C16.8626 4.96392 16.1997 3.97177 15.364 3.13604C14.5282 2.30031 13.5361 1.63738 12.4442 1.18508C11.3522 0.732792 10.1819 0.5 9 0.5C6.61305 0.5 4.32387 1.44821 2.63604 3.13604C0.948212 4.82387 0 7.11305 0 9.5C0 11.8869 0.948212 14.1761 2.63604 15.864C4.32387 17.5518 6.61305 18.5 9 18.5ZM8.768 13.14L13.768 7.14L12.232 5.86L7.932 11.019L5.707 8.793L4.293 10.207L7.293 13.207L8.067 13.981L8.768 13.14Z' 
      fill={color}
    />
  </SvgIcon>
)

const CircleCheckIconDark = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='18' height='19' viewBox='0 0 18 19' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd' 
      clipRule='evenodd' 
      d='M9 18.5C10.1819 18.5 11.3522 18.2672 12.4442 17.8149C13.5361 17.3626 14.5282 16.6997 15.364 15.864C16.1997 15.0282 16.8626 14.0361 17.3149 12.9442C17.7672 11.8522 18 10.6819 18 9.5C18 8.3181 17.7672 7.14778 17.3149 6.05585C16.8626 4.96392 16.1997 3.97177 15.364 3.13604C14.5282 2.30031 13.5361 1.63738 12.4442 1.18508C11.3522 0.732792 10.1819 0.5 9 0.5C6.61305 0.5 4.32387 1.44821 2.63604 3.13604C0.948212 4.82387 0 7.11305 0 9.5C0 11.8869 0.948212 14.1761 2.63604 15.864C4.32387 17.5518 6.61305 18.5 9 18.5ZM8.768 13.14L13.768 7.14L12.232 5.86L7.932 11.019L5.707 8.793L4.293 10.207L7.293 13.207L8.067 13.981L8.768 13.14Z' 
      fill={color}
    />
  </SvgIcon>
)

const CircleCheckIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CircleCheckIconDark {...props} /> : <CircleCheckIconLight {...props} />
}

export default CircleCheckIcon
