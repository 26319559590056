import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Root = styled(Box)(({ theme, color = 'gray' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(0.5),
  '&>span': {
    width: '100%',
    '& .react-loading-skeleton': {
      '--base-color': `${theme.palette[color][300]}40`,
      '--highlight-color': `${theme.palette[color][300]}10`,
      '--animation-duration': '3s',
      '--animation-direction': 'normal',
      backdropFilter: 'blur(3px)',
      '&::after': {
        width: '200%',
        transform: 'skewX(-40deg) translateX(-100%) scaleX(0.2)',
      },
    },
  },
}))

const LoadingSkeleton = props => {
  return <Root color={props.color} {...props}>
    <Skeleton/>
  </Root>
}

export default LoadingSkeleton
