import { styled } from '@mui/material/styles'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'

// eslint-disable-next-line react/display-name,max-len
const StyledSwipeableViews = styled(React.forwardRef((props, ref) => <SwipeableViews ref={ref} {...props}/>))(({ theme }) => ({
  overflowX: 'hidden',
  flexGrow: 1,
}))

const CustomSwipeableViews = ({ children, ...props }) => {

  /*const boxRef = useRef(null)
  const [height, setHeight] = useState('100%')
  useEffect(() => {
    if (boxRef.current) {
      const newHeight = `${boxRef.current.clientHeight}px`
      if (height !== newHeight) {
        setHeight(newHeight)
      }
    }
  }, [height])*/

  /*
  (1) The warning thrown is:
  react-swipeable-view: You are setting animateHeight to true but you are
  also providing a custom height.
  The custom height has a higher priority than the animateHeight property.
  So animateHeight is most
  */
  
  return <StyledSwipeableViews
    animateHeight={false}
    containerStyle={{ // To fix transition bug
      transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
      // This next line will throw a react warning (1), but if we don't add it, the
      // scroll in the new address form in the checkout will break:
      //height: height,
    }}
    //ref={boxRef}
    {...props}
  >
    { children }
  </StyledSwipeableViews>
}

export default CustomSwipeableViews
