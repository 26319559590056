import { styled } from '@mui/material/styles'
import { Slider } from '@mui/material'

const StyledSlider = styled(props => <Slider {...props}/>)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(4)})`,
  color: theme.palette.tertiary.main,
  height: theme.spacing(0.5),
  '& .MuiSlider-valueLabel': {
    boxShadow: theme.shadows.elevation.solid,
    font: theme.font.roboto.paragraph.minimum,
    fontWeight: 'normal',
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.tertiary.main,
    transform: 'translateY(-95%) scale(1)',
    padding: theme.spacing(0.5),
  },
  '& .MuiSlider-thumb': {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.palette.gray[100],
    boxShadow: theme.shadows.elevation.solid,
  },
}))

const CustomSliderPicker = ({ children, ...props }) => {

  return <StyledSlider valueLabelDisplay={'on'} {...props}>
    { children }
  </StyledSlider>
}

export default CustomSliderPicker
