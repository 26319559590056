import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledBlueButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  '&:hover': {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.gray[15],
  },
}))

const GreenOutlinedButton = ({ children, ...props }) => {

  return <StyledBlueButton {...props} variant={'outlined'}>
    { children }
  </StyledBlueButton>
}

export default GreenOutlinedButton
