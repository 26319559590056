import { createTheme } from '@mui/material/styles'
import BaseTheme from './baseTheme'

const LightTheme = createTheme({
  ...BaseTheme,
  palette: {
    ...BaseTheme.palette,
    type: 'light',
  },
  components: {
    ...BaseTheme.components,
    MuiDivider: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          background: '#DEE5EC',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // eslint-disable-next-line max-len
          color: '#212121',
        },
      },
    },
  },
})

export default LightTheme
