import { cloneElement } from 'react'
import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import useTheme from '@hooks/useTheme'

const StyledChip = styled(props => <Chip {...props}/>)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  maxHeight: theme.spacing(3),
  width: 'fit-content',
  '& .MuiChip-label': {
    padding: `0 ${theme.spacing(1.25)}`,
  },
}))

const CustomChip = ({ onMoreClick,
  label,
  color = 'green',
  icon,
  endIcon,
  style = 'filled',
  size = 'small',
  ...props }) => {

  const { theme } = useTheme()

  return <StyledChip
    {...props}
    icon={
      icon
        ? cloneElement(icon, {
          ...icon.props,
          color:
            style === 'solid' ?
              theme.palette.primary.contrastText
              : theme.palette.components.chips[color],
          sx: icon.props.width || icon.props.height
            ? {
              width: icon.props.width || icon.props.height,
              height: icon.props.width || icon.props.height,
            }
            : {},
        })
        : undefined
    }
    deleteIcon={
      endIcon
        ? cloneElement(endIcon, {
          ...endIcon.props,
          color: theme.palette.components.chips[color],
          sx: endIcon.props.width || endIcon.props.height
            ? {
              width: endIcon.props.width || endIcon.props.height,
              height: endIcon.props.width || endIcon.props.height,
            }
            : {},
        })
        : undefined
    }
    label={label}
    onDelete={onMoreClick}
    sx={style === 'classic'
      ? {
        ...props.sx,
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
        minHeight: size === 'medium' ? theme.spacing(4) : theme.spacing(3),
      }
      :(style === 'filled'
        ? {
          ...props.sx,
          backgroundColor: `${theme.palette.components.chips[color]}14`,
          color: theme.palette.components.chips[color],
          minHeight: size === 'medium' ? theme.spacing(4) : theme.spacing(3),
        }
        :(style === 'solid'
          ? {
            ...props.sx,
            backgroundColor: color,
            color: theme.palette.primary.contrastText,
            minHeight: size === 'medium' ? theme.spacing(4) : theme.spacing(3),
          }
          :{
            ...props.sx,
            border: `1px solid ${theme.palette.components.chips[color]} !important`,
            color: theme.palette.components.chips[color],
          })
      )
    }
  />
}

export default CustomChip
