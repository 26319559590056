/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getPerformerListService } from '@App/services/performer/getPerformerList'
import { getPerformerDetailService } from '@App/services/performer/getPerformerDetail'
import { getPerformerByTicketeroIdService } from '@App/services/performer/getPerformerByTicketeroId'

export default function usePerformer() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getPerformers = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getPerformerListService(apolloClient, { variables, options })
        resolve(response.performers)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getPerformer = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPerformerDetailService(apolloClient, { id, options })
        resolve(response.performer)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getPerformerByTicketeroId = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPerformerByTicketeroIdService(apolloClient, { id, options })
        resolve(response.performer)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    loadingSource,
    getPerformers,
    getPerformer,
    getPerformerByTicketeroId,
  }
}
