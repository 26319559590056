import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import { getFiscalDataService } from '@services/fiscalData/getFiscalData'
import { newFiscalDataService } from '@services/fiscalData/newFiscalData'
import { deleteFiscalDataService } from '@services/fiscalData/deleteFiscalData'

export default function useFiscalData() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getFiscalData = useCallback((options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getFiscalDataService(apolloClient, { options })
        resolve(response.getFiscalData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const newFiscalData = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await newFiscalDataService(apolloClient, { variables, options })
        resolve(response.addUserFiscalData)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const deleteFiscalData = useCallback( id => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await deleteFiscalDataService(apolloClient, id)
        resolve(response.removeUserFiscalData)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  },
  [apolloClient]
  )

  return {
    getFiscalData,
    newFiscalData,
    deleteFiscalData,
  }
}
