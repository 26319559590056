import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import useLanguage from '@hooks/useLanguage'
//import { useSubscription } from '@apollo/client'
//import { changedLocale } from '@graphql/session.graphql'
import Context from './index'

export default function LocaleContextProvider({ children }) {

  const router = useRouter()
  const { changeLanguage } = useLanguage()

  const localeContext = useContext(Context)

  const [locale, setLocale] = useState(router.locale ?? localeContext.currentLocale)
  const [localeGlossary, setLocaleGlossary] = useState({})

  const getGlossary = useCallback(
    async id => {
      return await import(`../../features/Locale/${id}.json`)
    },
    [locale]
  )

  useEffect(() => {
    getGlossary(locale)
      .then(resp => setLocaleGlossary(resp))
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [locale])

  useEffect(() => {
    changeLanguage(router.locale)
      .then( resp => {
        if(resp.setLocale) setLocale(router.locale)
      })
      .catch(error => {
        console.error('Error changing language:', error)
      })
  }, [router.locale])

  const changeLocale = newLocale => {
    setLocale(newLocale)
    if (newLocale !== router.locale) {
      if (newLocale === 'es')
        router.replace(`${router.asPath}`, `${router.asPath}`, {
          locale: newLocale,
        })
      else
        router.replace(
          `/${newLocale}${router.asPath}`,
          `/${newLocale}${router.asPath}`,
          {
            locale: newLocale,
          }
        )
    }
  }

  /*if (typeof window != 'undefined')
    useSubscription(changedLocale, {
      shouldResubscribe: true,
      fetchPolicy: 'no-cache',
      onData: async ({ data }) => {
        if (!data.data?.changedLocale) return
        changeLocale(data.data.changedLocale.id)
        setLocale(data.data.changedLocale.id)
        // setLocaleGlossary(getGlossary(data.data.changedLocale.id))

        if (changedLocale.id !== router.locale) {
          if (changedLocale.id === 'es')
            router.replace(`${router.asPath}`, `${router.asPath}`, {
              locale: data.data.changedLocale.id,
            })
          else
            router.replace(
              `/${data.data.changedLocale.id}${router.asPath}`,
              `/${data.data.changedLocale.id}${router.asPath}`,
              {
                locale: data.data.changedLocale.id,
              }
            )
        }
      },
    })*/

  // useSubscription(changedCurrencyFactor, {
  //   shouldResubscribe: true,
  //   fetchPolicy: 'no-cache',
  //   onData: async ({ data }) => {
  //     if (!data.data?.changedFactor) return
  //     setFactor(data.data.changedFactor)
  //   },
  // })

  const memorizedValue = useMemo(
    () => ({
      currentLocale: locale,
      glossary: localeGlossary,
      changeLocale,
    }),
    [localeGlossary, locale]
  )

  return <Context.Provider value={memorizedValue}>{children}</Context.Provider>
}
