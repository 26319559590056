/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getEventCategoriesListService } from '@services/eventCategories/getEventCategoryList'
import { getEventCategoryDetailService } from '@services/eventCategories/getEventCategoryDetail'
import { getTopEventCategoriesListService } from '@services/eventCategories/getTopEventCategoryList'
import { filterByUpcomingEventsService } from '@services/eventCategories/filterCategoriesWithUpcomingEvents'
import { getUnwindedEventCategoryService } from '@services/eventCategories/getUnwindedEventCategory'

export default function useEventCategory() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getEventCategories = useCallback( (filters={}, options={})  => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getEventCategoriesListService(apolloClient, { variables, options })
        resolve(response.eventCategories)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getEventCategory = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getEventCategoryDetailService(apolloClient, { id, options })
        resolve(response.eventCategory)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getTopEventCategories = useCallback(options => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getTopEventCategoriesListService(apolloClient, { options })
        resolve(response.topEventCategories)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const filterByUpcomingEvents = useCallback( (filters={}, options={})  => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await filterByUpcomingEventsService(apolloClient, { variables, options })
        resolve(response.filterCategoriesByUpcomingEvents)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getUnwindedEventCategory = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getUnwindedEventCategoryService(apolloClient, { id, options })
        resolve(response.eventCategoryUnwind)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    getEventCategories,
    getEventCategory,
    loadingSource,
    getTopEventCategories,
    filterByUpcomingEvents,
    getUnwindedEventCategory,
  }
}
