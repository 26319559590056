import { styled } from '@mui/material/styles'
import { AccordionSummary } from '@mui/material'

const StyledAccordionSummary = styled(props => <AccordionSummary {...props}/>)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    fontWeight: '500',
    font: theme.font.roboto.paragraph.small,
    height: theme.spacing(4),
    padding: '0', 
    '& .MuiAccordionSummary-content': {
      margin: '0',
      width: '100%',
      justifyContent: 'center',
    },
  })
)

const CollapsableSummary = props => {

  return <StyledAccordionSummary
    {...props}
  />
}

export default CollapsableSummary
