import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'
import  NumericFormat  from 'react-number-format'

const StyledTextField = styled(props => <TextField {...props}/>)(
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '100%',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        borderBottom: `1px solid ${theme.palette.gray[300]}`,
      },
    },
    '& input': {
      width: '30px',
      textAlign: 'center',
      font: theme.font.roboto.paragraph.medium,
      fontWeight: '600',
      padding: `${theme.spacing(0.5)} 0`,
    },
  })
)

// eslint-disable-next-line react/display-name
const NumericFormatCustom = forwardRef((
  props,
  ref
) => {

  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
    />
  )
})

const CustomNumericField = props  => {

  return <StyledTextField
    InputProps={{
      inputComponent: NumericFormatCustom,
    }}
    variant='standard'
    {...props}
  />
}

export default CustomNumericField
