import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const BankLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M12 1L2.5 6V8H21.5V6M16.5 10V17H19.5V10M2.5 22H21.5V19H2.5M10.5 10V17H13.5V10M4.5 10V17H7.5V10H4.5Z'
      fill={color}
    />
  </SvgIcon>
)

const BankDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M12 1L2.5 6V8H21.5V6M16.5 10V17H19.5V10M2.5 22H21.5V19H2.5M10.5 10V17H13.5V10M4.5 10V17H7.5V10H4.5Z'
      fill={color}
    />
  </SvgIcon>
)

const BankIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <BankDarkIcon {...props} /> : <BankLightIcon {...props} />
}

export default BankIcon
