import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const DownloadLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M4.00016 1C4.00016 0.447715 4.44787 0 5.00016 0H9.00016C9.55244 0 10.0002 0.447715 10.0002 1V8H12.4259C13.3168 8 13.763 9.07714 13.133 9.70711L7.70726 15.1329C7.31674 15.5234 6.68357 15.5234 6.29305 15.1329L0.867264 9.70711C0.237299 9.07714 0.683465 8 1.57437 8H4.00016V1Z' 
      fill={color}
    />
  </SvgIcon>
)

const DownloadDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M4.00016 1C4.00016 0.447715 4.44787 0 5.00016 0H9.00016C9.55244 0 10.0002 0.447715 10.0002 1V8H12.4259C13.3168 8 13.763 9.07714 13.133 9.70711L7.70726 15.1329C7.31674 15.5234 6.68357 15.5234 6.29305 15.1329L0.867264 9.70711C0.237299 9.07714 0.683465 8 1.57437 8H4.00016V1Z' 
      fill={color}
    />
  </SvgIcon>
)

const DownloadIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <DownloadDarkIcon {...props} /> : <DownloadLightIcon {...props} />
}

export default DownloadIcon
