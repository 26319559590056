/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const KitchenLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M18 10V7C18 5.34 16.66 4 15 4C13.34 4 12 5.34 12 7H14C14 6.45 14.45 6 15 6C15.55 6 16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18ZM13 18H11V12H13V18Z'
      fill={color}
    />
  </SvgIcon>
) 

const KitchenDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M18 10V7C18 5.34 16.66 4 15 4C13.34 4 12 5.34 12 7H14C14 6.45 14.45 6 15 6C15.55 6 16 6.45 16 7V10H8C9.1 10 10 9.1 10 8V4H4V8C4 9.1 4.9 10 6 10H2V12H4V20H20V12H22V10H18ZM13 18H11V12H13V18Z'
      fill={color}
    />
  </SvgIcon>
)

const KitchenIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <KitchenDarkIcon {...props} />
    : <KitchenLightIcon {...props} />
}

export default KitchenIcon
