import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const AddHomeLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon  width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M22.04 14.8799C23.68 14.5333 25.24 14.6399 26.6667 15.0666V13.3333C26.6667 12.4933 26.2667 11.7066 25.6 11.1999L17.6 5.19995C17.1376 4.85579 16.5765 4.66992 16 4.66992C15.4236 4.66992 14.8625 4.85579 14.4 5.19995L6.40004 11.1999C5.73337 11.7066 5.33337 12.4933 5.33337 13.3333V25.3333C5.33337 26.7999 6.53337 27.9999 8.00004 27.9999H15.5734C14.7062 26.1973 14.4488 24.1616 14.84 22.1999C15.5334 18.5733 18.4267 15.6533 22.04 14.8799Z' 
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M24 17.334C20.32 17.334 17.3334 20.3207 17.3334 24.0007C17.3334 27.6807 20.32 30.6673 24 30.6673C27.68 30.6673 30.6667 27.6807 30.6667 24.0007C30.6667 20.3207 27.68 17.334 24 17.334ZM28 24.6673H24.6667V28.0007H23.3334V24.6673H20V23.334H23.3334V20.0007H24.6667V23.334H28V24.6673Z'
      fill={color}
    />
  </SvgIcon>
)

const AddHomeDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon  width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M22.04 14.8799C23.68 14.5333 25.24 14.6399 26.6667 15.0666V13.3333C26.6667 12.4933 26.2667 11.7066 25.6 11.1999L17.6 5.19995C17.1376 4.85579 16.5765 4.66992 16 4.66992C15.4236 4.66992 14.8625 4.85579 14.4 5.19995L6.40004 11.1999C5.73337 11.7066 5.33337 12.4933 5.33337 13.3333V25.3333C5.33337 26.7999 6.53337 27.9999 8.00004 27.9999H15.5734C14.7062 26.1973 14.4488 24.1616 14.84 22.1999C15.5334 18.5733 18.4267 15.6533 22.04 14.8799Z' 
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M24 17.334C20.32 17.334 17.3334 20.3207 17.3334 24.0007C17.3334 27.6807 20.32 30.6673 24 30.6673C27.68 30.6673 30.6667 27.6807 30.6667 24.0007C30.6667 20.3207 27.68 17.334 24 17.334ZM28 24.6673H24.6667V28.0007H23.3334V24.6673H20V23.334H23.3334V20.0007H24.6667V23.334H28V24.6673Z'
      fill={color}
    />
  </SvgIcon>
)

const AddHomeIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <AddHomeDarkIcon {...props} /> : <AddHomeLightIcon {...props} />
}

export default AddHomeIcon
