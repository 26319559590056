/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const RestroomLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M19.4841 7.52938C21.5838 7.52938 23.2859 5.84387 23.2859 3.76469C23.2859 1.68551 21.5838 0 19.4841 0C17.3844 0 15.6823 1.68551 15.6823 3.76469C15.6823 5.84387 17.3844 7.52938 19.4841 7.52938Z'
      fill={color}
    />
    <path
      d='M19.2477 10.8232C14.4954 10.8232 14.4954 23.9997 14.4954 23.9997H24C24 23.9997 24 10.8232 19.2477 10.8232Z'
      fill={color}
    />
    <path
      d='M4.75368 7.52938C6.85337 7.52938 8.55551 5.84387 8.55551 3.76469C8.55551 1.68551 6.85337 0 4.75368 0C2.65398 0 0.951843 1.68551 0.951843 3.76469C0.951843 5.84387 2.65398 7.52938 4.75368 7.52938Z'
      fill={color}
    />
    <path
      d='M4.75229 24C0 24 0 10.8236 0 10.8236H9.50458C9.50458 10.8236 9.50458 24 4.75229 24Z'
      fill={color}
    />
  </SvgIcon>
)

const RestroomDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M19.4841 7.52938C21.5838 7.52938 23.2859 5.84387 23.2859 3.76469C23.2859 1.68551 21.5838 0 19.4841 0C17.3844 0 15.6823 1.68551 15.6823 3.76469C15.6823 5.84387 17.3844 7.52938 19.4841 7.52938Z'
      fill={color}
    />
    <path
      d='M19.2477 10.8232C14.4954 10.8232 14.4954 23.9997 14.4954 23.9997H24C24 23.9997 24 10.8232 19.2477 10.8232Z'
      fill={color}
    />
    <path
      d='M4.75368 7.52938C6.85337 7.52938 8.55551 5.84387 8.55551 3.76469C8.55551 1.68551 6.85337 0 4.75368 0C2.65398 0 0.951843 1.68551 0.951843 3.76469C0.951843 5.84387 2.65398 7.52938 4.75368 7.52938Z'
      fill={color}
    />
    <path
      d='M4.75229 24C0 24 0 10.8236 0 10.8236H9.50458C9.50458 10.8236 9.50458 24 4.75229 24Z'
      fill={color}
    />
  </SvgIcon>
)

const RestroomIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <RestroomDarkIcon {...props} />
    : <RestroomLightIcon {...props} />
}

export default RestroomIcon
