/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient, useSubscription } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { getEventsListService } from '@services/event/getEventList'
import { getEventDetailService } from '@services/event/getEventDetail'
import { getSuggestEventsService } from '@services/event/getSuggestEvents'
import { updatedEvent } from '@graphql/event.graphql'

export default function useEvent() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getEvents = useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getEventsListService(apolloClient, { variables, options })
        resolve(response.events)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getSuggestEvents = useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getSuggestEventsService(apolloClient, { variables, options })
        resolve(response.suggestEvents)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getEvent = useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getEventDetailService(apolloClient, { variables, options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updatedEventSubscription = useCallback(id => {
    const [eventData, setEventData] = useState(null)
    const subscription = useSubscription(updatedEvent, {
      variables: { id },
      shouldResubscribe: true,
    })
    useEffect(() => {
      if (!subscription) return
      if (subscription.loading) return
      if (!subscription.data) return
      if (!subscription.data.updatedEvent) return
      const updated = subscription.data.updatedEvent
      setEventData(updated)
    }, [subscription])

    return eventData
  }, [apolloClient])

  return {
    loading,
    getEvents,
    getEvent,
    getSuggestEvents,
    loadingSource,
    updatedEventSubscription,
  }
}
