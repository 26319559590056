/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ArrowGraphLigthIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
   <path fill-rule='evenodd'
    clip-rule='evenodd'
    d='M14.3993 8.4C14.0811 8.4 13.7758 8.27357 13.5508 8.04853C13.3257 7.82348 13.1993 7.51826 13.1993 7.2C13.1993 6.88174 13.3257 6.57652 13.5508 6.35147C13.7758 6.12643 14.0811 6 14.3993 6H20.3993C20.7176 6 21.0228 6.12643 21.2478 6.35147C21.4729 6.57652 21.5993 6.88174 21.5993 7.2V13.2C21.5993 13.5183 21.4729 13.8235 21.2478 14.0485C21.0228 14.2736 20.7176 14.4 20.3993 14.4C20.0811 14.4 19.7758 14.2736 19.5508 14.0485C19.3257 13.8235 19.1993 13.5183 19.1993 13.2V10.0968L14.0477 15.2484C13.8227 15.4734 13.5175 15.5997 13.1993 15.5997C12.8811 15.5997 12.576 15.4734 12.3509 15.2484L9.59932 12.4968L4.44772 17.6484C4.22139 17.867 3.91827 17.9879 3.60364 17.9852C3.289 17.9825 2.98802 17.8563 2.76553 17.6338C2.54304 17.4113 2.41684 17.1103 2.41411 16.7957C2.41137 16.481 2.53233 16.1779 2.75092 15.9516L8.75092 9.9516C8.97595 9.72664 9.28112 9.60026 9.59932 9.60026C9.91751 9.60026 10.2227 9.72664 10.4477 9.9516L13.1993 12.7032L17.5025 8.4H14.3993Z'
    fill={color}/>

  </SvgIcon>
)

const ArrowGraphDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path fill-rule='evenodd'
    clip-rule='evenodd'
    d='M14.3993 8.4C14.0811 8.4 13.7758 8.27357 13.5508 8.04853C13.3257 7.82348 13.1993 7.51826 13.1993 7.2C13.1993 6.88174 13.3257 6.57652 13.5508 6.35147C13.7758 6.12643 14.0811 6 14.3993 6H20.3993C20.7176 6 21.0228 6.12643 21.2478 6.35147C21.4729 6.57652 21.5993 6.88174 21.5993 7.2V13.2C21.5993 13.5183 21.4729 13.8235 21.2478 14.0485C21.0228 14.2736 20.7176 14.4 20.3993 14.4C20.0811 14.4 19.7758 14.2736 19.5508 14.0485C19.3257 13.8235 19.1993 13.5183 19.1993 13.2V10.0968L14.0477 15.2484C13.8227 15.4734 13.5175 15.5997 13.1993 15.5997C12.8811 15.5997 12.576 15.4734 12.3509 15.2484L9.59932 12.4968L4.44772 17.6484C4.22139 17.867 3.91827 17.9879 3.60364 17.9852C3.289 17.9825 2.98802 17.8563 2.76553 17.6338C2.54304 17.4113 2.41684 17.1103 2.41411 16.7957C2.41137 16.481 2.53233 16.1779 2.75092 15.9516L8.75092 9.9516C8.97595 9.72664 9.28112 9.60026 9.59932 9.60026C9.91751 9.60026 10.2227 9.72664 10.4477 9.9516L13.1993 12.7032L17.5025 8.4H14.3993Z'
    fill={color}/>
  </SvgIcon>
)

const ArrowGraphIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <ArrowGraphDarkIcon {...props} />
    : <ArrowGraphLigthIcon {...props} />
}

export default ArrowGraphIcon
