import { createTheme } from '@mui/material/styles'
import BaseTheme from './baseTheme'

const DarkTheme = createTheme({
  ...BaseTheme,
  palette: {
    ...BaseTheme.palette,
    type: 'dark',
  },
  components: {
    ...BaseTheme.components,
    MuiDivider: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          background: '#3E444B',
        },
      },
    },
  },
})

export default DarkTheme
