/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const TimesDangerIconLight = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM17.0306 15.9694C17.1003 16.0391 17.1556 16.1218 17.1933 16.2128C17.231 16.3039 17.2504 16.4015 17.2504 16.5C17.2504 16.5985 17.231 16.6961 17.1933 16.7872C17.1556 16.8782 17.1003 16.9609 17.0306 17.0306C16.9609 17.1003 16.8782 17.1556 16.7872 17.1933C16.6961 17.231 16.5985 17.2504 16.5 17.2504C16.4015 17.2504 16.3039 17.231 16.2128 17.1933C16.1218 17.1556 16.0391 17.1003 15.9694 17.0306L12 13.0603L8.03063 17.0306C7.88989 17.1714 7.69902 17.2504 7.5 17.2504C7.30098 17.2504 7.11011 17.1714 6.96937 17.0306C6.82864 16.8899 6.74958 16.699 6.74958 16.5C6.74958 16.301 6.82864 16.1101 6.96937 15.9694L10.9397 12L6.96937 8.03063C6.82864 7.88989 6.74958 7.69902 6.74958 7.5C6.74958 7.30098 6.82864 7.11011 6.96938 6.96938C7.11011 6.82864 7.30098 6.74958 7.5 6.74958C7.69902 6.74958 7.88989 6.82864 8.03063 6.96937L12 10.9397L15.9694 6.96937C16.1101 6.82864 16.301 6.74958 16.5 6.74958C16.699 6.74958 16.8899 6.82864 17.0306 6.96937C17.1714 7.11011 17.2504 7.30098 17.2504 7.5C17.2504 7.69902 17.1714 7.88989 17.0306 8.03063L13.0603 12L17.0306 15.9694Z' 
      fill={color}/>
  </SvgIcon>
)

const TimesDangerIconDark = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path d='M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM17.0306 15.9694C17.1003 16.0391 17.1556 16.1218 17.1933 16.2128C17.231 16.3039 17.2504 16.4015 17.2504 16.5C17.2504 16.5985 17.231 16.6961 17.1933 16.7872C17.1556 16.8782 17.1003 16.9609 17.0306 17.0306C16.9609 17.1003 16.8782 17.1556 16.7872 17.1933C16.6961 17.231 16.5985 17.2504 16.5 17.2504C16.4015 17.2504 16.3039 17.231 16.2128 17.1933C16.1218 17.1556 16.0391 17.1003 15.9694 17.0306L12 13.0603L8.03063 17.0306C7.88989 17.1714 7.69902 17.2504 7.5 17.2504C7.30098 17.2504 7.11011 17.1714 6.96937 17.0306C6.82864 16.8899 6.74958 16.699 6.74958 16.5C6.74958 16.301 6.82864 16.1101 6.96937 15.9694L10.9397 12L6.96937 8.03063C6.82864 7.88989 6.74958 7.69902 6.74958 7.5C6.74958 7.30098 6.82864 7.11011 6.96938 6.96938C7.11011 6.82864 7.30098 6.74958 7.5 6.74958C7.69902 6.74958 7.88989 6.82864 8.03063 6.96937L12 10.9397L15.9694 6.96937C16.1101 6.82864 16.301 6.74958 16.5 6.74958C16.699 6.74958 16.8899 6.82864 17.0306 6.96937C17.1714 7.11011 17.2504 7.30098 17.2504 7.5C17.2504 7.69902 17.1714 7.88989 17.0306 8.03063L13.0603 12L17.0306 15.9694Z' 
      fill={color}/>
  </SvgIcon>
)

const TimesDangerIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <TimesDangerIconDark {...props} /> : <TimesDangerIconLight {...props} />
}

export default TimesDangerIcon
