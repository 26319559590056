import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ShoppingBagOutlinedLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='12' height='14' viewBox='0 0 12 14' fill='none'>
   <path d="M8.16699 3.00016V3.50016H8.66699H10.0003C10.4575 3.50016 10.8337 3.87631 10.8337 4.3335V12.3335C10.8337 12.7907 10.4575 13.1668 10.0003 13.1668H2.00033C1.54313 13.1668 1.16699 12.7907 1.16699 12.3335V4.3335C1.16699 3.87631 1.54313 3.50016 2.00033 3.50016H3.33366H3.83366V3.00016C3.83366 1.80297 4.80313 0.833496 6.00033 0.833496C7.19752 0.833496 8.16699 1.80297 8.16699 3.00016ZM7.33366 3.50016H7.83366V3.00016C7.83366 1.99069 7.0098 1.16683 6.00033 1.16683C4.99085 1.16683 4.16699 1.99069 4.16699 3.00016V3.50016H4.66699H7.33366ZM10.0003 12.8335H10.5003V12.3335V4.3335V3.8335H10.0003H8.66699H8.16699V4.3335V5.66683C8.16699 5.75735 8.09085 5.8335 8.00033 5.8335C7.9098 5.8335 7.83366 5.75735 7.83366 5.66683V4.3335V3.8335H7.33366H4.66699H4.16699V4.3335V5.66683C4.16699 5.75735 4.09085 5.8335 4.00033 5.8335C3.9098 5.8335 3.83366 5.75735 3.83366 5.66683V4.3335V3.8335H3.33366H2.00033H1.50033V4.3335V12.3335V12.8335H2.00033H10.0003Z" 
    fill="black" 
    fill-opacity="0.54" 
    stroke="#82CD1C"/>
  </SvgIcon>
)

const ShoppingBagOutlinedDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='12' height='14' viewBox='0 0 12 14' fill='none'>
   <path d="M8.16699 3.00016V3.50016H8.66699H10.0003C10.4575 3.50016 10.8337 3.87631 10.8337 4.3335V12.3335C10.8337 12.7907 10.4575 13.1668 10.0003 13.1668H2.00033C1.54313 13.1668 1.16699 12.7907 1.16699 12.3335V4.3335C1.16699 3.87631 1.54313 3.50016 2.00033 3.50016H3.33366H3.83366V3.00016C3.83366 1.80297 4.80313 0.833496 6.00033 0.833496C7.19752 0.833496 8.16699 1.80297 8.16699 3.00016ZM7.33366 3.50016H7.83366V3.00016C7.83366 1.99069 7.0098 1.16683 6.00033 1.16683C4.99085 1.16683 4.16699 1.99069 4.16699 3.00016V3.50016H4.66699H7.33366ZM10.0003 12.8335H10.5003V12.3335V4.3335V3.8335H10.0003H8.66699H8.16699V4.3335V5.66683C8.16699 5.75735 8.09085 5.8335 8.00033 5.8335C7.9098 5.8335 7.83366 5.75735 7.83366 5.66683V4.3335V3.8335H7.33366H4.66699H4.16699V4.3335V5.66683C4.16699 5.75735 4.09085 5.8335 4.00033 5.8335C3.9098 5.8335 3.83366 5.75735 3.83366 5.66683V4.3335V3.8335H3.33366H2.00033H1.50033V4.3335V12.3335V12.8335H2.00033H10.0003Z" 
    fill="black" 
    fill-opacity="0.54" 
    stroke="#82CD1C"/>
  </SvgIcon>
)

const ShoppingBagOutlinedIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ShoppingBagOutlinedDarkIcon {...props} /> : <ShoppingBagOutlinedLightIcon {...props} />
}

export default ShoppingBagOutlinedIcon
