/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const GraphLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' {...props}>
    <path
      d='M1.25 15C0.973858 15 0.75 14.7761 0.75 14.5V6C0.75 5.72386 0.973858 5.5 1.25 5.5H2.25C2.52614 5.5 2.75 5.72386 2.75 6V14.5C2.75 14.7761 2.52614 15 2.25 15H1.25ZM6.5 15C6.22386 15 6 14.7761 6 14.5V1.5C6 1.22386 6.22386 1 6.5 1H7.5C7.77614 1 8 1.22386 8 1.5V14.5C8 14.7761 7.77614 15 7.5 15H6.5ZM11.75 15C11.4739 15 11.25 14.7761 11.25 14.5V10.5C11.25 10.2239 11.4739 10 11.75 10H12.75C13.0261 10 13.25 10.2239 13.25 10.5V14.5C13.25 14.7761 13.0261 15 12.75 15H11.75Z' 
      fill='white'
      stroke='white'
    />
  </SvgIcon>
)

const GraphDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' {...props}>
    <path
      d='M1.25 15C0.973858 15 0.75 14.7761 0.75 14.5V6C0.75 5.72386 0.973858 5.5 1.25 5.5H2.25C2.52614 5.5 2.75 5.72386 2.75 6V14.5C2.75 14.7761 2.52614 15 2.25 15H1.25ZM6.5 15C6.22386 15 6 14.7761 6 14.5V1.5C6 1.22386 6.22386 1 6.5 1H7.5C7.77614 1 8 1.22386 8 1.5V14.5C8 14.7761 7.77614 15 7.5 15H6.5ZM11.75 15C11.4739 15 11.25 14.7761 11.25 14.5V10.5C11.25 10.2239 11.4739 10 11.75 10H12.75C13.0261 10 13.25 10.2239 13.25 10.5V14.5C13.25 14.7761 13.0261 15 12.75 15H11.75Z' 
      fill='white'
      stroke='white'
    />
  </SvgIcon>
)

const GraphIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <GraphDarkIcon {...props} />
    : <GraphLightIcon {...props} />
}

export default GraphIcon
