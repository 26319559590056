/* eslint-disable max-len */
const generalSettings = {
  defaultTitle: 'Stadibox',
  defaultLocale: 'es',
  cdnRoot: 'https://cdn.stadibox.com',
  passwordMinLength: 8,
  recaptchaSiteKey: '6Ld1N8sZAAAAAB7WHDpgoSRvwoehXavaVSz5SLd8',
  mapsSiteKey: 'AIzaSyDv16jDUGvjKV86EGrH3XB5wMfvi-LVy2s',
  fbAppId: '275503862918498',
  tawkTo: '5990dc5f1b1bed47ceb04669',
  sepomex: '85761f8e-ab26-44dd-b644-a03c372bfdcb',
  ticketeroToken: '5|gPxyITCzeSeJy86gFGwZ5zQXIywYMaWxLZYqLRTf',
  encrypt_method: 'aes-256-cbc',
  defaultMetadataImageUrl: 'https://cdn.stadibox.com/0AL~.JTz0t/logocover1280x800.png',
}
export default process.env.NODE_ENV === 'development'
  ? {
      rootDomain: 'sandbox.stadibox.com',
      envDomain: 'sandbox.stadibox.com',
      httpsUri: 'https://gql.sandbox.stadibox.com',
      wssUri: 'wss://gql.sandbox.stadibox.com',
      blogUri: 'http://127.0.0.1:1337/graphql',
      previewRoot: 'https://gql.sandbox.stadibox.com/preview',
      dashboardUrl: 'https://dashboard.sandbox.stadibox.com',
      stripeKey:
        'pk_test_51L3NPDKFj7tNJZZn2CLKWFdKyqkTdMSK3juQ3DYgxU6w7D0pVXN5gadPmCe0EJcqxbdxq9Yn8CFmMDjAxeWjLeLx00hCR8cToM',
      paypalId: 'AXSVqg9ZAigJagbGo_zmOy3z4k_woWGtscdXyLTrWxU11olEP80Vpd4UQCQp3PNDR7S3JICnUhKmpoA1',
      ticketeroUrl: 'https://sandbox.ticketero.co/api/',
      passphrase: 'Z9.k9VdE*wntSqKJ',
      iv: 'dzMG9+4t2xYfTIOPqZ3bDQ==',
      sentryEnv: 'develop',
      ...generalSettings,
    }
  : process.env.NEXT_PUBLIC_ENV === 'staging'
  ? {
      rootDomain: 'stadibox.com',
      envDomain: 'alpha.stadibox.com',
      httpsUri: 'https://gql.test.stadibox.com',
      wssUri: 'wss://gql.test.stadibox.com',
      blogUri: 'https://test.blog.stadibox.com/graphql',
      previewRoot: 'https://gql.test.stadibox.com/preview',
      dashboardUrl: 'https://dashboard.test.stadibox.com',
      stripeKey:
        'pk_test_51L3NPDKFj7tNJZZn2CLKWFdKyqkTdMSK3juQ3DYgxU6w7D0pVXN5gadPmCe0EJcqxbdxq9Yn8CFmMDjAxeWjLeLx00hCR8cToM',
      paypalId: 'AXSVqg9ZAigJagbGo_zmOy3z4k_woWGtscdXyLTrWxU11olEP80Vpd4UQCQp3PNDR7S3JICnUhKmpoA1',
      ticketeroUrl: 'https://sandbox.ticketero.co/api/',
      passphrase: 'Z9.k9VdE*wntSqKJ',
      iv: 'dzMG9+4t2xYfTIOPqZ3bDQ==',
      sentryEnv: 'test',
      ...generalSettings,
    }
  : {
      rootDomain: 'stadibox.com',
      httpsUri: 'https://gql.stadibox.com',
      wssUri: 'wss://gql.stadibox.com',
      blogUri: 'https://blog.stadibox.com/graphql',
      previewRoot: 'https://gql.stadibox.com/preview',
      dashboardUrl: 'https://dashboard.stadibox.com',
      stripeKey:
        'pk_live_51L3NPDKFj7tNJZZnoLkdTSXfTyLAifhiWZT0vpS1vgqKFuYGsxm5t7m8fswOPvEaK8mRyw0TWSPOWEcpig4gWy0800pwMU2hZv',
      paypalId: 'AevFpNSGfvgyRA-2vpLvLb76dfunWlIIJtn7aGCUEzcgB0uU3fLDLzN1MKewTAIhXfK42up2j2JxSz2r',
      ticketeroUrl: 'https://production.ticketero.co/api/',
      passphrase: 'bx47v521VQSxAi51',
      iv: 'OcCFc1oyiiNx8ugktlEmCQ==',
      sentryEnv: 'production',
      ...generalSettings,
    }
