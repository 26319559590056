export const CITY = 1
export const REGION = 0
export const COUNTRY = 2

export const locationToString = (location, short=false, locale=null) => {
  let loc = location
  switch (locale) {
    case 'es':
      loc = {
        ...loc,
        name: loc.name_es ?? loc.name,
        country: loc.country ? {
          ...loc.country,
          name: loc.country.name_es ?? loc.country.name,
        } : null,
        region: loc.region ? {
          ...loc.region,
          name: loc.region.name_es ?? loc.region.name,
          country: loc.region.country ? {
            ...loc.region.country,
            name: loc.region.country.name_es ?? loc.region.country.name,
          } : null,
        } : null,
      }
      break
    case 'en':
      loc = {
        ...loc,
        name: loc.name_en ?? loc.name,
        country: loc.country ? {
          ...loc.country,
          name: loc.country.name_en ?? loc.country.name,
        } : null,
        region: loc.region ? {
          ...loc.region,
          name: loc.region.name_en ?? loc.region.name,
          country: loc.region.country ? {
            ...loc.region.country,
            name: loc.region.country.name_en ?? loc.region.country.name,
          } : null,
        } : null,
      }
      break
    case 'pt':
      loc = {
        ...loc,
        name: loc.name_pt ?? loc.name,
        country: loc.country ? {
          ...loc.country,
          name: loc.country.name_pt ?? loc.country.name,
        } : null,
        region: loc.region ? {
          ...loc.region,
          name: loc.region.name_pt ?? loc.region.name,
          country: loc.region.country ? {
            ...loc.region.country,
            name: loc.region.country.name_pt ?? loc.region.country.name,
          } : null,
        } : null,
      }
      break
  }

  switch(loc.__typename){
  case 'Region':
    return short ?
      `${loc.name}${loc.country?.id ? `, ${loc.country.id}` : ''}`
      : `${loc.name}${loc.country?.name ? `, ${loc.country.name}` : ''}`
  case 'City':
    return short ?
      `${loc.name}${loc.region?.country?.id ? `, ${loc.region.country.id}` : ''}`
      : `${loc.name}${loc.region?.name ? `, ${loc.region.name}` : ''}${loc.region?.country?.name ? `, ${loc.region.country.name}` : ''}`
  case 'Country':
    return `${loc.name}`
  default:
    return `${loc.name}`
  }
}

export const getRegionId = loc => {
  if(!loc) return null
  switch(loc.__typename){
  case 'Region':
    return loc.id
  case 'City':
    return loc.region?.id ? loc.region.id : null
  case 'Country':
    return null
  default:
    return null
  }
}

export const getCountryId = loc => {
  if(!loc) return null
  switch(loc.__typename){
  case 'Region':
    return loc.country?.id ? loc.country.id : null
  case 'City':
    return loc.region?.country?.id ? loc.region.country.id : null
  case 'Country':
    return loc.id
  default:
    return null
  }
}

export const getLocationQuery = loc => {
  if(!loc) return {}
  switch(loc.__typename){
  case 'Region':
    return { region: [loc.id]}
  case 'City':
    return { city: [loc.id]}
  case 'Country':
    return { country: [loc.id]}
  default:
    return {}
  }
}
