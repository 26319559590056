import { useContext, useState } from 'react'
import Context from '@context/App'

export default function useSession() {

  try {
    const { session, setSession, agent, refetch, setAgent, dispatch } = useContext(Context)
    const [user, setUser] = useState({ ...session?.user, profileImage: 'https://i.imgur.com/X2J5Z5J.png' })

    const onUpdateUser = _user => {
      const userData = { user: { ..._user } }
      setUser(userData)
      setSession(userData)
    }

    const clearSession = () => {
      dispatch({
        type: 'SET_SESSION',
        payload: null,
      })
    }

    return {
      session,
      user,
      onUpdateUser,
      clearSession,
      setSession,
      agent,
      setAgent,
      refetch,
      dispatch,
    }
  } catch (error) {
    throw new Error('useSession must be used within a AppContextProvider')
  }
}
