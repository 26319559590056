import { Box, MenuItem, FormControl, FormHelperText } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomSelect from './CustomSelect'

const FormControlStyled = styled(props => <FormControl {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  gap: theme.spacing(1),
  flexDirection: 'column',
}))

const InputContainer = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
}))

const Label = styled(props => <Box {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const CustomInputSelect = ({ label, value, onChange, options, error, errorMessage, ...props }) => {
  return <FormControlStyled error={error}>
    <Label>
      {label}
    </Label>
    <InputContainer>
      <CustomSelect
        sx = {{ backgroundColor: 'white' }}
        value={value}
        onChange={onChange}
      >
        { options.map(op => <MenuItem value={op} key={op}>{op}</MenuItem>) }
      </CustomSelect>
      { error && <FormHelperText>{errorMessage}</FormHelperText> }
    </InputContainer>
  </FormControlStyled>
}

export default CustomInputSelect
