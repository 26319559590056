import { Fragment } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { DeleteIcon, StartIcon } from '@icons/index'
import ImagePreview from './Upload/ImageUploader/ImagePreview'
import { useTheme, useGlossary } from '@App/hooks'

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows.elevation.medium,
}))

const Container = styled(Box)(({ theme }) => ({
  height: '80%',
}))

const DeleteContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '20%',
  font: theme.font.roboto.paragraph.minimum,
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
  alignItems:'center',
  gap: theme.spacing(1),
  justifyContent: 'center',
}))

const BoxPhoto = ({ picture, onDelete, onSetCover, isCover = false, ...props }) => {

  const { theme } = useTheme()
  const { glossary } = useGlossary()

  return <Root>
    <Container>
      <ImagePreview
        moreActions={
          <Fragment>
            <Box onClick={e => onSetCover(picture)}>
              <StartIcon
                stroke={1.5}
                sx={ isCover ? { '& path': { fill: theme.palette.primary.main } } : {} }
              />
            </Box>
          </Fragment>
        }
        fileId={picture?.file?.id}
        fileUrl={picture?.file?.url}
      />
      <DeleteContainer onClick={e => onDelete(picture)}>
        <DeleteIcon/> { glossary('Delete') }
      </DeleteContainer>
    </Container>
  </Root>
}

export default BoxPhoto
