/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient, useSubscription } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { getAsyncTicketsListService, getTicketsListService } from '@services/tickets/getTicketList'
import { getTicketService } from '@services/tickets/getTicket'
import { createTicketsOrderService } from '@services/tickets/createTicketsOrder'
import { getTicketForMapService } from '@services/tickets/getTicketForMap'
import { ticketsResolved } from '@graphql/tickets.graphql'

export default function useTicket() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getTickets = useCallback((event, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const response = await getTicketsListService(apolloClient, { event, options })
        resolve(response.tickets)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getAsyncTickets = useCallback((event, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const response = await getAsyncTicketsListService(apolloClient, { event, options })
        resolve(response.asyncTickets)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getTicket = useCallback(({ id, event }, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getTicketService(apolloClient, {
          variables: {
            ticketId: id,
            eventId: event,
          },
          options,
        })
        resolve(response.ticket)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const createTicketsOrder = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await createTicketsOrderService(apolloClient, { variables, options })
        resolve(response.createTicketsOrder)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getTicketForMap = useCallback(({ id, event }, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getTicketForMapService(apolloClient, {
          variables: {
            ticketId: id,
            eventId: event,
          },
          options,
        })
        resolve(response.ticketForMap)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    loadingSource,
    getTickets,
    getTicket,
    createTicketsOrder,
    getTicketForMap,
    getAsyncTickets
  }
}

export const useTicketsSubscription = (eventId) => {

  const [event, setEvent] = useState(eventId)

  const subscription = useSubscription(ticketsResolved, {
    variables: {event},
    shouldResubscribe: true,
    skip: !event,
  })

  useEffect(() => {
    setEvent(eventId)
  }, [eventId])

  return subscription || {data: undefined, loading: true}
}