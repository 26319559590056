import Context from '@context/Locale'
import I18nText, { interpolate } from './i18n'

export default {
  Context,
  I18nText,
  interpolate,
}

export { Context, I18nText, interpolate }
