import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const SearchLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M15.502 13.9999H14.712L14.432 13.7299C15.632 12.3299 16.252 10.4199 15.912 8.38989C15.442 5.60989 13.122 3.38989 10.322 3.04989C6.09197 2.52989 2.53197 6.08989 3.05197 10.3199C3.39197 13.1199 5.61197 15.4399 8.39197 15.9099C10.422 16.2499 12.332 15.6299 13.732 14.4299L14.002 14.7099V15.4999L18.252 19.7499C18.662 20.1599 19.332 20.1599 19.742 19.7499C20.152 19.3399 20.152 18.6699 19.742 18.2599L15.502 13.9999ZM9.50197 13.9999C7.01197 13.9999 5.00197 11.9899 5.00197 9.49989C5.00197 7.00989 7.01197 4.99989 9.50197 4.99989C11.992 4.99989 14.002 7.00989 14.002 9.49989C14.002 11.9899 11.992 13.9999 9.50197 13.9999Z'
      fill={color}
    />
  </SvgIcon>
)

const SearchDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M15.502 13.9999H14.712L14.432 13.7299C15.632 12.3299 16.252 10.4199 15.912 8.38989C15.442 5.60989 13.122 3.38989 10.322 3.04989C6.09197 2.52989 2.53197 6.08989 3.05197 10.3199C3.39197 13.1199 5.61197 15.4399 8.39197 15.9099C10.422 16.2499 12.332 15.6299 13.732 14.4299L14.002 14.7099V15.4999L18.252 19.7499C18.662 20.1599 19.332 20.1599 19.742 19.7499C20.152 19.3399 20.152 18.6699 19.742 18.2599L15.502 13.9999ZM9.50197 13.9999C7.01197 13.9999 5.00197 11.9899 5.00197 9.49989C5.00197 7.00989 7.01197 4.99989 9.50197 4.99989C11.992 4.99989 14.002 7.00989 14.002 9.49989C14.002 11.9899 11.992 13.9999 9.50197 13.9999Z'
      fill={color}
    />
  </SvgIcon>
)

const SearchIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <SearchDarkIcon {...props} /> : <SearchLightIcon {...props} />
}

export default SearchIcon
