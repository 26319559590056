/* eslint-disable max-len */
import { useContext, useLayoutEffect, useRef } from 'react'
import { SvgIcon } from '@mui/material'
import anime from 'animejs'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const InfoLightIcon = ({ color = '#8FA8BD', stroke = 1.5, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' id='info_icon' fill='none' {...props}>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='circle'
    />
    <path
      d='M12 16V12'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='line'
    />
    <path
      d='M12 8H12.01'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='point'
    />
  </SvgIcon>
)

const InfoDarkIcon = ({ color = '#8FA8BD', stroke = 1.5, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' id='info_icon' fill='none' {...props}>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='circle'
    />
    <path
      d='M12 16V12'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='line'
    />
    <path
      d='M12 8H12.01'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
      className='point'
    />
  </SvgIcon>
)

const InfoIcon = ({ playAnimation = false, playsOnAppears = false, ...props }) => {

  const { darkMode } = useContext(Context)
  const animationRef = useRef(null)

  anime.set('#info_icon path', {
    strokeDashoffset: 0,
  })

  useLayoutEffect(() => {
    if (!anime) return
    animationRef.current = anime.timeline({
      easing: 'easeInOutCirc',
      loop: false,
      autoplay: false,
    })

    animationRef.current.add({
      targets: '#info_icon .circle',
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: 700,
    })
      .add({
        targets: '#info_icon .line',
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 200,
      }, '-=50')
      .add({
        targets: '#info_icon .point',
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 100,
      })

  }, [anime])

  useLayoutEffect(() => {
    if (playAnimation){
      anime.set('#info_icon path', {
        strokeDashoffset: anime.setDashoffset,
      })
      animationRef.current.play()
    }
  }, [playAnimation])

  useLayoutEffect(() => {
    if (playsOnAppears) animationRef.current.play()
  }, [])

  return darkMode ?
    <InfoDarkIcon {...props} />
    : <InfoLightIcon {...props} />
}

export default InfoIcon
