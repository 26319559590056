import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomTextField from './Forms/CustomTextField'

const Container = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  gap: theme.spacing(1),
  flexDirection: 'column',
}))

const Label = styled(props => <Box {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const CustomInput = ({ label, value, onChange, error, errorMessage, ...props }) => {

  return <Container sx={props.sx}>
    <Label>
      { label }
    </Label>
    <CustomTextField
      value={value}
      onChange={onChange}
      error={error}
      helperText={error ? errorMessage : null}
      {...props}
    />
  </Container>
}

export default CustomInput
