/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const WeLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='20' height='14' viewBox='0 0 20 14' fill='none' {...props}>
    <path d='M14.5 7C14.8283 7 15.1534 6.93534 15.4567 6.8097C15.76 6.68406 16.0356 6.49991 16.2678 6.26777C16.4999 6.03562 16.6841 5.76002 16.8097 5.45671C16.9353 5.15339 17 4.8283 17 4.5C17 3.83696 16.7366 3.20107 16.2678 2.73223C15.7989 2.26339 15.163 2 14.5 2C13.837 2 13.2011 2.26339 12.7322 2.73223C12.2634 3.20107 12 3.83696 12 4.5C12 4.8283 12.0647 5.15339 12.1903 5.45671C12.3159 5.76002 12.5001 6.03562 12.7322 6.26777C13.2011 6.73661 13.837 7 14.5 7ZM7 6C7.79565 6 8.55871 5.68393 9.12132 5.12132C9.68393 4.55871 10 3.79565 10 3C10 2.20435 9.68393 1.44129 9.12132 0.87868C8.55871 0.316071 7.79565 0 7 0C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3C4 3.79565 4.31607 4.55871 4.87868 5.12132C5.44129 5.68393 6.20435 6 7 6ZM14.5 9C12.67 9 9 9.92 9 11.75V14H20V11.75C20 9.92 16.33 9 14.5 9ZM7 8C4.67 8 0 9.17 0 11.5V14H7V11.75C7 10.9 7.33 9.41 9.37 8.28C8.5 8.1 7.66 8 7 8Z' 
    fill={color}/>
  </SvgIcon>
)

const WeDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='20' height='14' viewBox='0 0 20 14' fill='none' {...props}>
    <path d='M14.5 7C14.8283 7 15.1534 6.93534 15.4567 6.8097C15.76 6.68406 16.0356 6.49991 16.2678 6.26777C16.4999 6.03562 16.6841 5.76002 16.8097 5.45671C16.9353 5.15339 17 4.8283 17 4.5C17 3.83696 16.7366 3.20107 16.2678 2.73223C15.7989 2.26339 15.163 2 14.5 2C13.837 2 13.2011 2.26339 12.7322 2.73223C12.2634 3.20107 12 3.83696 12 4.5C12 4.8283 12.0647 5.15339 12.1903 5.45671C12.3159 5.76002 12.5001 6.03562 12.7322 6.26777C13.2011 6.73661 13.837 7 14.5 7ZM7 6C7.79565 6 8.55871 5.68393 9.12132 5.12132C9.68393 4.55871 10 3.79565 10 3C10 2.20435 9.68393 1.44129 9.12132 0.87868C8.55871 0.316071 7.79565 0 7 0C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3C4 3.79565 4.31607 4.55871 4.87868 5.12132C5.44129 5.68393 6.20435 6 7 6ZM14.5 9C12.67 9 9 9.92 9 11.75V14H20V11.75C20 9.92 16.33 9 14.5 9ZM7 8C4.67 8 0 9.17 0 11.5V14H7V11.75C7 10.9 7.33 9.41 9.37 8.28C8.5 8.1 7.66 8 7 8Z' 
    fill={color}/>
  </SvgIcon>
)

const WeIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <WeDarkIcon {...props} />
    : <WeLightIcon {...props} />
}

export default WeIcon
