/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const HomeLigthIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='14' height='12' viewBox='0 0 14 12' fill='none' {...props}>
    <path d='M7.00001 0C7.00001 0 2.87601 3.56 0.571344 5.488C0.498008 5.5519 0.438924 5.6305 0.397922 5.7187C0.356921 5.80691 0.334919 5.90274 0.333344 6C0.333344 6.17681 0.403581 6.34638 0.528606 6.4714C0.65363 6.59643 0.823199 6.66667 1.00001 6.66667H2.33334V11.3333C2.33334 11.5101 2.40358 11.6797 2.52861 11.8047C2.65363 11.9298 2.8232 12 3.00001 12H5.00001C5.17682 12 5.34639 11.9298 5.47141 11.8047C5.59644 11.6797 5.66668 11.5101 5.66668 11.3333V8.66667H8.33334V11.3333C8.33334 11.5101 8.40358 11.6797 8.52861 11.8047C8.65363 11.9298 8.8232 12 9.00001 12H11C11.1768 12 11.3464 11.9298 11.4714 11.8047C11.5964 11.6797 11.6667 11.5101 11.6667 11.3333V6.66667H13C13.1768 6.66667 13.3464 6.59643 13.4714 6.4714C13.5964 6.34638 13.6667 6.17681 13.6667 6C13.6657 5.90088 13.6423 5.80327 13.598 5.71456C13.5538 5.62586 13.49 5.54838 13.4113 5.488C11.1227 3.56 7.00001 0 7.00001 0Z' 
      fill={color}/>
  </SvgIcon>
) 

const HomeDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='14' height='12' viewBox='0 0 14 12' fill='none' {...props}>
    <path d='M7.00001 0C7.00001 0 2.87601 3.56 0.571344 5.488C0.498008 5.5519 0.438924 5.6305 0.397922 5.7187C0.356921 5.80691 0.334919 5.90274 0.333344 6C0.333344 6.17681 0.403581 6.34638 0.528606 6.4714C0.65363 6.59643 0.823199 6.66667 1.00001 6.66667H2.33334V11.3333C2.33334 11.5101 2.40358 11.6797 2.52861 11.8047C2.65363 11.9298 2.8232 12 3.00001 12H5.00001C5.17682 12 5.34639 11.9298 5.47141 11.8047C5.59644 11.6797 5.66668 11.5101 5.66668 11.3333V8.66667H8.33334V11.3333C8.33334 11.5101 8.40358 11.6797 8.52861 11.8047C8.65363 11.9298 8.8232 12 9.00001 12H11C11.1768 12 11.3464 11.9298 11.4714 11.8047C11.5964 11.6797 11.6667 11.5101 11.6667 11.3333V6.66667H13C13.1768 6.66667 13.3464 6.59643 13.4714 6.4714C13.5964 6.34638 13.6667 6.17681 13.6667 6C13.6657 5.90088 13.6423 5.80327 13.598 5.71456C13.5538 5.62586 13.49 5.54838 13.4113 5.488C11.1227 3.56 7.00001 0 7.00001 0Z' 
      fill={color}/>
  </SvgIcon>

)

const HomeIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <HomeDarkIcon {...props} />
    : <HomeLigthIcon {...props} />
}

export default HomeIcon
