import { useCallback, useEffect, useState } from 'react'
import { Checkbox, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import useEventCategory from '@hooks/useEventCategory'
import useGlossary from '@hooks/useGlossary'
import ChevronDownIcon from '@icons/ChevronDownIcon'
import ChevronRightIcon from '@icons/ChevronRightIcon'
import CustomOption from '@components/CustomOption'
import CustomChip from '@components/CustomChip'
import CustomSelect from '@components/CustomSelect'
import { CONCERTS, SPORTS, THEATRE } from '@constants/MainEventCategories'

const CustomCustomSelect = styled(props => <CustomSelect {...props}/>)(({ theme }) => ({
  color: theme.palette.gray['500'],
}))

const OptionMenu = styled(CustomOption)(({ theme }) => ({
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCheckbox-root': {
    padding: '0 8px 0 0',
  },
  '&:hover': {
    backgroundColor: theme.palette.gray[400],
    cursor: 'pointer',
  },
}))

const DropdownLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.5),
}))

const SimpleLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  gap: theme.spacing(0.5),
  '& .MuiCheckbox-root': {
    padding: '0 8px 0 0',
    height: '24px',
    width: '24px !important',
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  height: '24px',
  width: '24px',
  padding: '3px',
  '& svg': {
    width: '18px',
    height: '18px',
  },
}))

const SelectedCategoryChip = styled(CustomChip)(({ theme }) => ({
  color: theme.palette.font.black,
  backgroundColor: theme.palette.gray[400],
}))

const EventCategoryFilter = ({
  initialSelectedCategories,
  onSelectCategory,
  expandableCategories= [CONCERTS, SPORTS, THEATRE],
  limitTags = 2,
  ...props
}) => {

  const { glossary } = useGlossary()
  const { getEventCategories } = useEventCategory()

  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState(initialSelectedCategories || [])

  const addParentIds = (arr, parentIds = []) => {
    return arr.map(item => {
      const newItem = {
        ...item,
        _id: item.id,
        parentIds: [...parentIds, item.id],
      }
      if (item.subcategories && item.subcategories.length) {
        newItem.subcategories = addParentIds(item.subcategories, [...parentIds, item.id])
      }
      return newItem
    })
  }

  const setOpenById = useCallback((arr, id) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id)
        arr[i].open = !arr[i].open
      if (arr[i].subcategories && arr[i].subcategories.length)
        setOpenById(arr[i].subcategories, id)
    }
  }, [categories])

  const handleOpen = useCallback(id => {
    const _categories = categories
    setOpenById(_categories, id)
    setCategories([..._categories])
  }, [categories])

  const fetchCategoriesData = useCallback(() => {
    getEventCategories().then(resp => {
      const a = addParentIds(resp)
      setCategories(a)
    })
  }, [categories])

  const handleAdd = useCallback(categories => {
    for (const x in categories) {
      const category = categories[x]
      if (selectedCategories.findIndex(c => c.id === category.id) === -1) {
        setSelectedCategories([...selectedCategories, category])
        if(onSelectCategory) onSelectCategory([...selectedCategories, category])
      }
    }
  }, [selectedCategories])

  const handleDelete = useCallback(category => {
    const _categories = selectedCategories.filter(cat => {
      if (cat.parentIds) {
        return [cat.id, ...cat.parentIds].indexOf(category.id) === -1
      } else {
        const subcats = category.subcategories ? category.subcategories.map(sub => sub.id) : []
        return [category.id, ...subcats].indexOf(cat.id) === -1
      }
    })
    setSelectedCategories(_categories)
    if(onSelectCategory) onSelectCategory(_categories)
  }, [selectedCategories, categories])

  useEffect(() => {
    fetchCategoriesData()
  }, [])

  useEffect(() => {
    setSelectedCategories(initialSelectedCategories)
  }, [initialSelectedCategories])

  return <CustomCustomSelect
    fullWidth
    size={'small'}
    multiple
    value={selectedCategories.length > 0 ? selectedCategories : ['Categories']}
    // onChange={handleChange}
    renderValue={selected => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        { selectedCategories.length > 0 ?
          selectedCategories.length <= limitTags ?
            selected.map(value => (
              <SelectedCategoryChip key={value.name} label={value.name} />
            )) :
            [...selected.slice(0, limitTags).map(value => (
              <SelectedCategoryChip key={value.name} label={value.name} />
            )), ` +${selectedCategories.length - limitTags}`]
          : glossary('Category')
        }
      </Box>
    )}
    sx={{ height:'100%' }}
  >
    {
      categories.map(category =>
        <OptionMenu key={category.id}>
          <DropdownLabel>
            <SimpleLabel>
              <Checkbox
                checked={selectedCategories.findIndex(c => c.id === category.id) !== -1}
                onChange={e => e.target.checked ? handleAdd([category]) : handleDelete(category)}
              />
              {category.name}
            </SimpleLabel>
            { expandableCategories.includes(category.id) &&
              <IconBox onClick={e => handleOpen(category.id)}>
                {
                  !category.open && category.subcategories?.length > 0 && <ChevronRightIcon/>
                }
                {
                  category.open && category.subcategories?.length > 0 && <ChevronDownIcon/>
                }
              </IconBox>
            }
          </DropdownLabel>
          {
            category.open && category.subcategories?.length > 0 && category.subcategories?.map(sub =>
              <OptionMenu key={sub.id}>
                <SimpleLabel>
                  <Checkbox
                    checked={selectedCategories.findIndex(c => c.id === sub.id) !== -1}
                    onChange={e => e.target.checked ? handleAdd([category, sub]) : handleDelete(sub)}
                  />
                  {sub.name}
                </SimpleLabel>
              </OptionMenu>
            )
          }
        </OptionMenu>
      )
    }
  </CustomCustomSelect>
}

export default EventCategoryFilter
