import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CardViewLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6.25 4C5.65326 4 5.08097 4.23705 4.65901 4.65901C4.23705 5.08097 4 5.65326 4 6.25V8.75C4 9.34674 4.23705 9.91903 4.65901 10.341C5.08097 10.7629 5.65326 11 6.25 11H8.75C9.34674 11 9.91903 10.7629 10.341 10.341C10.7629 9.91903 11 9.34674 11 8.75V6.25C11 5.65326 10.7629 5.08097 10.341 4.65901C9.91903 4.23705 9.34674 4 8.75 4H6.25ZM6.25 13C5.65326 13 5.08097 13.2371 4.65901 13.659C4.23705 14.081 4 14.6533 4 15.25V17.75C4 18.3467 4.23705 18.919 4.65901 19.341C5.08097 19.7629 5.65326 20 6.25 20H8.75C9.34674 20 9.91903 19.7629 10.341 19.341C10.7629 18.919 11 18.3467 11 17.75V15.25C11 14.6533 10.7629 14.081 10.341 13.659C9.91903 13.2371 9.34674 13 8.75 13H6.25ZM15.25 4C14.6533 4 14.081 4.23705 13.659 4.65901C13.2371 5.08097 13 5.65326 13 6.25V8.75C13 9.34674 13.2371 9.91903 13.659 10.341C14.081 10.7629 14.6533 11 15.25 11H17.75C18.3467 11 18.919 10.7629 19.341 10.341C19.7629 9.91903 20 9.34674 20 8.75V6.25C20 5.65326 19.7629 5.08097 19.341 4.65901C18.919 4.23705 18.3467 4 17.75 4H15.25ZM15.25 13C14.6533 13 14.081 13.2371 13.659 13.659C13.2371 14.081 13 14.6533 13 15.25V17.75C13 18.3467 13.2371 18.919 13.659 19.341C14.081 19.7629 14.6533 20 15.25 20H17.75C18.3467 20 18.919 19.7629 19.341 19.341C19.7629 18.919 20 18.3467 20 17.75V15.25C20 14.6533 19.7629 14.081 19.341 13.659C18.919 13.2371 18.3467 13 17.75 13H15.25Z'
      fill={color}
    />
  </SvgIcon>
)

const CardViewDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6.25 4C5.65326 4 5.08097 4.23705 4.65901 4.65901C4.23705 5.08097 4 5.65326 4 6.25V8.75C4 9.34674 4.23705 9.91903 4.65901 10.341C5.08097 10.7629 5.65326 11 6.25 11H8.75C9.34674 11 9.91903 10.7629 10.341 10.341C10.7629 9.91903 11 9.34674 11 8.75V6.25C11 5.65326 10.7629 5.08097 10.341 4.65901C9.91903 4.23705 9.34674 4 8.75 4H6.25ZM6.25 13C5.65326 13 5.08097 13.2371 4.65901 13.659C4.23705 14.081 4 14.6533 4 15.25V17.75C4 18.3467 4.23705 18.919 4.65901 19.341C5.08097 19.7629 5.65326 20 6.25 20H8.75C9.34674 20 9.91903 19.7629 10.341 19.341C10.7629 18.919 11 18.3467 11 17.75V15.25C11 14.6533 10.7629 14.081 10.341 13.659C9.91903 13.2371 9.34674 13 8.75 13H6.25ZM15.25 4C14.6533 4 14.081 4.23705 13.659 4.65901C13.2371 5.08097 13 5.65326 13 6.25V8.75C13 9.34674 13.2371 9.91903 13.659 10.341C14.081 10.7629 14.6533 11 15.25 11H17.75C18.3467 11 18.919 10.7629 19.341 10.341C19.7629 9.91903 20 9.34674 20 8.75V6.25C20 5.65326 19.7629 5.08097 19.341 4.65901C18.919 4.23705 18.3467 4 17.75 4H15.25ZM15.25 13C14.6533 13 14.081 13.2371 13.659 13.659C13.2371 14.081 13 14.6533 13 15.25V17.75C13 18.3467 13.2371 18.919 13.659 19.341C14.081 19.7629 14.6533 20 15.25 20H17.75C18.3467 20 18.919 19.7629 19.341 19.341C19.7629 18.919 20 18.3467 20 17.75V15.25C20 14.6533 19.7629 14.081 19.341 13.659C18.919 13.2371 18.3467 13 17.75 13H15.25Z'
      fill={color}
    />
  </SvgIcon>
)

const CardViewIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CardViewDarkIcon {...props} /> : <CardViewLightIcon {...props} />
}

export default CardViewIcon
