import { useState } from 'react'
import { Typography, Box } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import baseConfig from '@baseConfig'
import useError from '@hooks/useError'
import useRegion from '@hooks/useRegion'
import useCountry from '@hooks/useCountry'
import useGlossary from '@hooks/useGlossary'
import { locationToString } from '@helpers/location'
import Map from '../Map/Map'

const CurrentLocationButton = ({ selectedLocation, onSelectLocation }) => {

  const { glossary } = useGlossary()
  const { logError, showAlert } = useError()
  const { getRegions } = useRegion()
  const { getCountries } = useCountry()

  const [coordinates, setCoordinates] = useState({ lat:0, lng:0 })
  const [currentLocation, setCurrentLocation] = useState()

  const fetchLocation = async (regionName, countryId) => {  
    try {
      let region = await getRegions({ filter: { query: regionName }, num: 1 })
      region = region.edges.length > 0 ? region.edges[0].node : undefined
      if(region) {
        setCurrentLocation(region)
        onSelectLocation(region)
        return
      }
      let country = await getCountries({ filter: { id: countryId }, num: 1 })
      country = country.edges.length > 0 ? country.edges[0].node : undefined
      if(country) {
        setCurrentLocation(country)
        onSelectLocation(country)
      } else {
        showAlert(glossary('RegionNotFound'), 'warning')
      }
    } catch(e) {
      logError('ERROR:', e) 
    }
  }

  const getCurrentLocation = () => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        setCoordinates({ lat, lng })
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: { lat, lng } })
          .then(response => {
            if (response.results[0]) {
              let matchedRegionName
              let matchedCountryId
              response.results[0].address_components.forEach(value => {
                if (value.types.includes('administrative_area_level_1')){ // Find a match with the current REGION
                  matchedRegionName = value.long_name.toLowerCase()
                }
                if (value.types.includes('country')){ // Find a match with the current COUNTRY
                  matchedCountryId = value.short_name.toLowerCase()
                }
              })
              fetchLocation(matchedRegionName, matchedCountryId)
            } else {
              showAlert(glossary('CantGetLocation'), 'warning')
              logError('No results found')
            }
          })
          .catch(e => logError('Geocoder failed due to: ' + e))
      }
    )
  }

  return <Box>
    {<Map googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${baseConfig.mapsSiteKey}&libraries=places`}
      loadingElement={<div style={{ height: '100%' }}/>}
      containerElement={<div style={{ height: '100%' }}/>}
      mapElement={<div style={{ height: '100%' }}/>}
      coordinates={coordinates}
    />}
    <Box sx={{ display:'flex', justifyContent:'flex-start', alignItems:'center', gap:'5px' }}
      onClick={e => {
        e.stopPropagation()
        getCurrentLocation()
      }}>
      <LocationOnOutlinedIcon/>
      <Typography>
        { currentLocation && selectedLocation ?
          (selectedLocation.id === currentLocation.id ?
            locationToString(selectedLocation) :
            glossary('UseCurrentLocation'))
          : glossary('UseCurrentLocation')
        }
      </Typography>
    </Box>
  </Box>
}

export default CurrentLocationButton
