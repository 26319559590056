import { getWaitListsStatistics } from '@graphql/waitList.graphql'

export const getEventWaitListService = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: getWaitListsStatistics,
      variables,
      ...options,
    })

    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
