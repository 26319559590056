import { useSnackbar } from 'notistack'
import useGlossary from '@hooks/useGlossary'

export default function useError() {

  const { enqueueSnackbar } = useSnackbar()
  const { glossary } = useGlossary()

  const logError = (error, message) => {
    if (error.message?.includes('[custom]')) {
      const messageText = error.message.split(':').pop().trim()
      enqueueSnackbar(messageText, { variant: 'error' })
    } else if (error.message?.includes('Error')) {
      const customMessage = message || error.message.split(':').pop().trim()
      enqueueSnackbar(customMessage, { variant: 'warning' })
    } else if (error.message?.includes('GraphQL')) {
      const customMessage = message || error.message.split(':').pop().trim()
      enqueueSnackbar(customMessage, { variant: 'warning' })
    }  else {
      // eslint-disable-next-line no-console
      console.error(error)
      const customMessage = message || glossary('LogErrorDefault')
      enqueueSnackbar(customMessage, { variant: 'error' })
    }
  }

  const showAlert = (message, variant = 'warning', duration = 5000) => {
    enqueueSnackbar(message, { variant, autoHideDuration: duration })
  }

  const showSuccess = message => {
    enqueueSnackbar(message, { variant: 'success' })
  }

  return {
    logError,
    showAlert,
    showSuccess,
  }
}
