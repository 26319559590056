/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getBankFields } from '../services/country/getCountryBankFields'
import { getBankFieldCountry } from '../services/country/getBankFieldByCountry'

export default function useBankFields() {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getCountriesBankFields = useCallback(
    variables => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const response = await getBankFields(apolloClient, variables)
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const getBankFieldByCountry = useCallback(
    variables => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const response = await getBankFieldCountry( apolloClient, variables)
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }     
      })
    }, [apolloClient]
  )

  return {
    loading,
    getBankFieldByCountry,
    getCountriesBankFields,
  }
}
