import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.tertiary[500],
  padding: `${theme.spacing(0.7)} ${theme.spacing(2)}`,
  '&:hover': {
    backgroundColor: theme.palette.gray[700],
  },
}))

const DarkButton = ({ children, ...props }) => {

  return <StyledButton {...props}>
    { children }
  </StyledButton>
}

export default DarkButton
