import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CloseXLightIcon = ({ color = '#8FA8BD', stroke = 2 }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' stroke={color} strokeWidth={stroke}>
    <line xmlns='http://www.w3.org/2000/svg' x1='18' y1='6' x2='6' y2='18'/>
    <line xmlns='http://www.w3.org/2000/svg' x1='6' y1='6' x2='18' y2='18'/>
  </SvgIcon>
)

const CloseXDarkIcon = ({ color = '#8FA8BD', stroke = 2 }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' stroke={color} strokeWidth={stroke}>
    <line xmlns='http://www.w3.org/2000/svg' x1='18' y1='6' x2='6' y2='18'/>
    <line xmlns='http://www.w3.org/2000/svg' x1='6' y1='6' x2='18' y2='18'/>
  </SvgIcon>
)

const CloseXIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CloseXDarkIcon {...props} /> : <CloseXLightIcon {...props} />
}

export default CloseXIcon
