import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.tertiary[500],
  border: '1px solid',
  borderColor: theme.palette.tertiary[500],
  padding: `${theme.spacing(0.7)} ${theme.spacing(2)}`,
  '&:hover': {
    border: '1px solid',
    borderColor: theme.palette.tertiary[500],
    backgroundColor: theme.palette.tertiary[10],
  },
}))

const TertiaryOutlinedButton = ({ children, ...props }) => {

  return <StyledButton {...props} variant={'outlined'}>
    { children }
  </StyledButton>
}

export default TertiaryOutlinedButton
