import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ShoppingLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M17.5 18C16.39 18 15.5 18.89 15.5 20C15.5 20.5304 15.7107 21.0391 16.0858 21.4142C16.4609 21.7893 16.9696 22 17.5 22C18.0304 22 18.5391 21.7893 18.9142 21.4142C19.2893 21.0391 19.5 20.5304 19.5 20C19.5 19.4696 19.2893 18.9609 18.9142 18.5858C18.5391 18.2107 18.0304 18 17.5 18ZM1.5 2V4H3.5L7.1 11.59L5.74 14.04C5.59 14.32 5.5 14.65 5.5 15C5.5 15.5304 5.71071 16.0391 6.08579 16.4142C6.46086 16.7893 6.96957 17 7.5 17H19.5V15H7.92C7.8537 15 7.79011 14.9737 7.74322 14.9268C7.69634 14.8799 7.67 14.8163 7.67 14.75C7.67 14.7 7.68 14.66 7.7 14.63L8.6 13H16.05C16.8 13 17.46 12.58 17.8 11.97L21.38 5.5C21.45 5.34 21.5 5.17 21.5 5C21.5 4.73478 21.3946 4.48043 21.2071 4.29289C21.0196 4.10536 20.7652 4 20.5 4H5.71L4.77 2M7.5 18C6.39 18 5.5 18.89 5.5 20C5.5 20.5304 5.71071 21.0391 6.08579 21.4142C6.46086 21.7893 6.96957 22 7.5 22C8.03043 22 8.53914 21.7893 8.91421 21.4142C9.28929 21.0391 9.5 20.5304 9.5 20C9.5 19.4696 9.28929 18.9609 8.91421 18.5858C8.53914 18.2107 8.03043 18 7.5 18Z'
      fill={color}
    />
  </SvgIcon>
)

const ShoppingDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M17.5 18C16.39 18 15.5 18.89 15.5 20C15.5 20.5304 15.7107 21.0391 16.0858 21.4142C16.4609 21.7893 16.9696 22 17.5 22C18.0304 22 18.5391 21.7893 18.9142 21.4142C19.2893 21.0391 19.5 20.5304 19.5 20C19.5 19.4696 19.2893 18.9609 18.9142 18.5858C18.5391 18.2107 18.0304 18 17.5 18ZM1.5 2V4H3.5L7.1 11.59L5.74 14.04C5.59 14.32 5.5 14.65 5.5 15C5.5 15.5304 5.71071 16.0391 6.08579 16.4142C6.46086 16.7893 6.96957 17 7.5 17H19.5V15H7.92C7.8537 15 7.79011 14.9737 7.74322 14.9268C7.69634 14.8799 7.67 14.8163 7.67 14.75C7.67 14.7 7.68 14.66 7.7 14.63L8.6 13H16.05C16.8 13 17.46 12.58 17.8 11.97L21.38 5.5C21.45 5.34 21.5 5.17 21.5 5C21.5 4.73478 21.3946 4.48043 21.2071 4.29289C21.0196 4.10536 20.7652 4 20.5 4H5.71L4.77 2M7.5 18C6.39 18 5.5 18.89 5.5 20C5.5 20.5304 5.71071 21.0391 6.08579 21.4142C6.46086 21.7893 6.96957 22 7.5 22C8.03043 22 8.53914 21.7893 8.91421 21.4142C9.28929 21.0391 9.5 20.5304 9.5 20C9.5 19.4696 9.28929 18.9609 8.91421 18.5858C8.53914 18.2107 8.03043 18 7.5 18Z'
      fill={color}
    />
  </SvgIcon>
)

const ShoppingIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ShoppingDarkIcon {...props} /> : <ShoppingLightIcon {...props} />
}

export default ShoppingIcon
