import { getPurchaseActnetPaymentMethod } from '@graphql/purchase.graphql'

export const getPurchaseActnetPaymentMethodService = async (apolloClient, { purchaseId, options }) => {
  try {
    const resp = await apolloClient.query({
      query: getPurchaseActnetPaymentMethod,
      variables: { purchaseId },
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
