import { getCurrency } from '@graphql/currency.graphql'

export const getCurrencyService = async (apolloClient, { id, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: getCurrency,
      variables: {
        id,
      },
      ...options,
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
