import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const StyledButton = styled(props => <Button {...props}/>)(({ theme }) => ({
  textTransform: 'Capitalize',
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50cqh',
  height: '100%',
  font: `${theme.font.roboto.paragraph.small} !important`,
  color: theme.palette.gray[500],
  fontWeight: '600 !important',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.light}99`,
  },
  '& .MuiButton-startIcon svg': {
    fontSize: '1rem',
    '& path': {
      fill: 'transparent',
      stroke: theme.palette.gray[500],
    },
  },
}))
const FilledButton =  ({ children, ...props })  => {

  return <StyledButton
    {...props}
  >
    { children }
  </StyledButton>

}

export default FilledButton
