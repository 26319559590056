import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import useGlossary from '@hooks/useGlossary'
import ClipboardIcon from '@icons/ClipboardIcon'

const CopyClickIcon = props => {

  const { glossary } = useGlossary()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
    setTimeout(() => setOpen(false), 1000)
  }

  return <Tooltip
    title={glossary('Copied')}
    onClose={handleTooltipClose}
    open={open}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  >
    <IconButton onClick={handleTooltipOpen}>
      <ClipboardIcon width={'16px'} height={'16px'} sx={{ width: '16px', height: '16px' }}/>
    </IconButton>
  </Tooltip>
}

export default CopyClickIcon
