/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getCitiesListService } from '@services/city/getCitiesList'
import { getCityService } from '@services/city/getCity'

export default function useCity() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getCities = useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getCitiesListService(apolloClient, { variables, options })
        resolve(response.cities)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getCity = useCallback((id, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getCityService(apolloClient, { id, options })
        resolve(response.city)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    getCities,
    getCity,
  }
}
