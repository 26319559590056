/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ChevronUpLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M14 11.25L9.5 6.75L5 11.25' 
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round' 
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const ChevronUpDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M14 11.25L9.5 6.75L5 11.25' 
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round' 
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const ChevronUpIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <ChevronUpDarkIcon {...props} />
    : <ChevronUpLightIcon {...props} />
}

export default ChevronUpIcon
