import { useContext, Component } from 'react';
import cryptoRandomString from 'crypto-random-string';

import reducer from '../reducers';
import initialState from './initialState';
import BaseFileContext from './context';
import { signUpload, verifyUpload } from '../../queries.graphql';
import { ADD_UPLOAD, UPDATE_UPLOAD } from '../actions/types';

export const useStore = () => useContext(BaseFileContext);

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.dispatch = this.dispatch.bind(this);
    this.nextUpload = this.nextUpload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  dispatch(action, callback = null) {
    this.setState(
      prevState => reducer(prevState, action),
      callback
    );
  }

  nextUpload = () => {
    this.setState(
      prevState => {
        const currentlyUploading = prevState.uploads.filter(file => file.uploading).length;
        if (currentlyUploading >= 2) return null;

        const nextFile = prevState.uploads.find(file => !file.uploading && !file.done);
        if (!nextFile) return null;

        const req = new XMLHttpRequest();
        const fields = JSON.parse(nextFile.policy.fields);
        const formData = new FormData();
        Object.keys(fields).forEach(key => formData.append(key, fields[key]));
        formData.append('acl', 'public-read');
        formData.append('file', nextFile.file);

        req.open('POST', nextFile.policy.url);

        req.upload.onprogress = event => {
          const uploadProgress = event.lengthComputable ? (event.loaded / event.total) * 100 : -1;
          this.dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...nextFile, uploadProgress } },
          });
        };

        req.onerror = () => {
          this.dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...nextFile, uploading: false, done: true, error: 'Error al cargar' } },
          });
        };

        req.onload = async () => {
          this.dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...nextFile, uploading: false, done: true, uploadProgress: 100 } },
          });

          try {
            await this.props.client.mutate({
              mutation: verifyUpload,
              variables: { id: nextFile.id },
            });
          } catch (error) {
            console.error('Error verifying upload:', error);
          } finally {
            this.nextUpload(); // Continúa con el siguiente archivo
          }
        };

        req.send(formData);

        return {
          ...prevState,
          uploads: prevState.uploads.map(upload =>
            upload.id === nextFile.id
              ? { ...nextFile, uploading: true, req }
              : upload
          ),
        };
      },
    );
  };

  async uploadFiles(files, path) {
    const uploads = await Promise.all(
      Array.from(files).map(async file => {
        try {
          const response = await this.props.client.mutate({
            mutation: signUpload,
            variables: { name: file.name, path },
          });
          const data = response.data.signUpload;
          const upload = {
            ...data.file,
            policy: data.policy,
            file,
            uploadProgress: 0,
            uploading: false,
            done: false,
          };
          this.dispatch({ type: ADD_UPLOAD, payload: { upload } });
          return upload;
        } catch (error) {
          console.error('Error signing upload:', error);
          const upload = {
            id: cryptoRandomString({ type: 'url-safe', length: 10 }),
            name: file.name,
            error: 'Error signing upload',
          };
          this.dispatch({ type: ADD_UPLOAD, payload: { upload } });
          return upload;
        }
      })
    );

    this.nextUpload(); // Inicia la subida de archivos
    return uploads;
  }

  render() {
    return (
      <BaseFileContext.Provider
        value={[
          {
            ...this.state,
            uploadFiles: this.uploadFiles,
          },
          this.dispatch,
        ]}
      >
        {this.props.children}
      </BaseFileContext.Provider>
    );
  }
}

export default Provider;
