import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CardLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0 14.625C0 15.3212 0.276562 15.9889 0.768845 16.4812C1.26113 16.9734 1.92881 17.25 2.625 17.25H18.375C19.0712 17.25 19.7389 16.9734 20.2312 16.4812C20.7234 15.9889 21 15.3212 21 14.625V7.40625H0V14.625ZM3.09375 11.0625C3.09375 10.6895 3.24191 10.3319 3.50563 10.0681C3.76935 9.80441 4.12704 9.65625 4.5 9.65625H6.75C7.12296 9.65625 7.48065 9.80441 7.74437 10.0681C8.00809 10.3319 8.15625 10.6895 8.15625 11.0625V12C8.15625 12.373 8.00809 12.7306 7.74437 12.9944C7.48065 13.2581 7.12296 13.4062 6.75 13.4062H4.5C4.12704 13.4062 3.76935 13.2581 3.50563 12.9944C3.24191 12.7306 3.09375 12.373 3.09375 12V11.0625ZM18.375 0.75H2.625C1.92881 0.75 1.26113 1.02656 0.768845 1.51884C0.276562 2.01113 0 2.67881 0 3.375V4.59375H21V3.375C21 2.67881 20.7234 2.01113 20.2312 1.51884C19.7389 1.02656 19.0712 0.75 18.375 0.75Z'
      fill={color}
    />
  </SvgIcon>
)

const CardDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0 14.625C0 15.3212 0.276562 15.9889 0.768845 16.4812C1.26113 16.9734 1.92881 17.25 2.625 17.25H18.375C19.0712 17.25 19.7389 16.9734 20.2312 16.4812C20.7234 15.9889 21 15.3212 21 14.625V7.40625H0V14.625ZM3.09375 11.0625C3.09375 10.6895 3.24191 10.3319 3.50563 10.0681C3.76935 9.80441 4.12704 9.65625 4.5 9.65625H6.75C7.12296 9.65625 7.48065 9.80441 7.74437 10.0681C8.00809 10.3319 8.15625 10.6895 8.15625 11.0625V12C8.15625 12.373 8.00809 12.7306 7.74437 12.9944C7.48065 13.2581 7.12296 13.4062 6.75 13.4062H4.5C4.12704 13.4062 3.76935 13.2581 3.50563 12.9944C3.24191 12.7306 3.09375 12.373 3.09375 12V11.0625ZM18.375 0.75H2.625C1.92881 0.75 1.26113 1.02656 0.768845 1.51884C0.276562 2.01113 0 2.67881 0 3.375V4.59375H21V3.375C21 2.67881 20.7234 2.01113 20.2312 1.51884C19.7389 1.02656 19.0712 0.75 18.375 0.75Z'
      fill={color}
    />
  </SvgIcon>
)

const CardIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CardDarkIcon {...props} /> : <CardLightIcon {...props} />
}

export default CardIcon
