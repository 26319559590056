export const SNACKBAR_STYLE = {
  '& .SnackbarItem-message': {
    gap: '4px',
    maxWidth: '500px',
    '& svg path': {
      stroke: '#FFFFFF',
      fill: 'none',
    },
  },
  '& .SnackbarContent-root': {
    color: '#FFFFFF',
    backdropFilter: 'blur(4px)',
    backgroundColor: '#21283699',
    '&.SnackbarItem-variantSuccess': {
      backgroundColor: '#84E26899',
    },
    '&.SnackbarItem-variantError': {
      backgroundColor: '#FF5C4799',
    },
    '&.SnackbarItem-variantWarning': {
      backgroundColor: '#FCDF0299',
    },
    '&.SnackbarItem-variantInfo': {
      backgroundColor: '#59A1FF99',
    },
  },

}
