/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getRegionsListService } from '@services/region/getRegionsList'

export default function useRegion() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getRegions= useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getRegionsListService(apolloClient, { variables, options })
        resolve(response.regions)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    getRegions,
  }
}
