/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const PrivacyLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
   <path d='M10 17C11.1 17 12 16.1 12 15C12 13.9 11.1 13 10 13C8.9 13 8 13.9 8 15C8 16.1 8.9 17 10 17ZM16 8C17.1 8 18 8.9 18 10V20C18 21.1 17.1 22 16 22H4C2.9 22 2 21.1 2 20V10C2 8.9 2.9 8 4 8H5V6C5 3.2 7.2 1 10 1C12.8 1 15 3.2 15 6V8H16ZM10 3C8.3 3 7 4.3 7 6V8H13V6C13 4.3 11.7 3 10 3ZM22 13H20V7H22V13ZM22 17H20V15H22V17Z' 
   fill={color}/>
  </SvgIcon>
)

const PrivacyDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
   <path d='M10 17C11.1 17 12 16.1 12 15C12 13.9 11.1 13 10 13C8.9 13 8 13.9 8 15C8 16.1 8.9 17 10 17ZM16 8C17.1 8 18 8.9 18 10V20C18 21.1 17.1 22 16 22H4C2.9 22 2 21.1 2 20V10C2 8.9 2.9 8 4 8H5V6C5 3.2 7.2 1 10 1C12.8 1 15 3.2 15 6V8H16ZM10 3C8.3 3 7 4.3 7 6V8H13V6C13 4.3 11.7 3 10 3ZM22 13H20V7H22V13ZM22 17H20V15H22V17Z' 
   fill={color}/>
  </SvgIcon>
)

const PrivacyIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <PrivacyDarkIcon {...props} />
    : <PrivacyLightIcon {...props} />
}

export default PrivacyIcon
