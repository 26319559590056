/* eslint-disable max-len */
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ArrowRightLightIcon = ({ color = '#212121', ...props }) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M13.334 22.6663L20.0007 15.9997L13.334 9.33301L13.334 22.6663Z' fill='#212121' />
  </svg>
)

const ArrowRightDarkIcon = ({ color = '#212121', ...props }) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M13.334 22.6663L20.0007 15.9997L13.334 9.33301L13.334 22.6663Z' fill='#212121' />
  </svg>
)

const ArrowRightIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ? <ArrowRightDarkIcon {...props} /> : <ArrowRightLightIcon {...props} />
}

export default ArrowRightIcon
