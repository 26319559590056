/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const InformationLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M8.66671 5.33301C8.66671 5.7012 8.36823 5.99967 8.00004 5.99967C7.63185 5.99967 7.33337 5.7012 7.33337 5.33301C7.33337 4.96482 7.63185 4.66634 8.00004 4.66634C8.36823 4.66634 8.66671 4.96482 8.66671 5.33301ZM8.66671 10.6663C8.66671 11.0345 8.36823 11.333 8.00004 11.333C7.63185 11.333 7.33337 11.0345 7.33337 10.6663V7.99967C7.33337 7.63148 7.63185 7.33301 8.00004 7.33301C8.36823 7.33301 8.66671 7.63148 8.66671 7.99967V10.6663ZM8.00004 1.33301C7.12456 1.33301 6.25766 1.50545 5.44882 1.84048C4.63998 2.17551 3.90505 2.66657 3.286 3.28563C2.03575 4.53587 1.33337 6.23156 1.33337 7.99967C1.33337 9.76779 2.03575 11.4635 3.286 12.7137C3.90505 13.3328 4.63998 13.8238 5.44882 14.1589C6.25766 14.4939 7.12456 14.6663 8.00004 14.6663C9.76815 14.6663 11.4638 13.964 12.7141 12.7137C13.9643 11.4635 14.6667 9.76779 14.6667 7.99967C14.6667 7.1242 14.4943 6.25729 14.1592 5.44845C13.8242 4.63961 13.3331 3.90469 12.7141 3.28563C12.095 2.66657 11.3601 2.17551 10.5513 1.84048C9.74243 1.50545 8.87552 1.33301 8.00004 1.33301Z'
      fill={color}/>
  </SvgIcon>
) 

const InformationDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path d='M8.66671 5.33301C8.66671 5.7012 8.36823 5.99967 8.00004 5.99967C7.63185 5.99967 7.33337 5.7012 7.33337 5.33301C7.33337 4.96482 7.63185 4.66634 8.00004 4.66634C8.36823 4.66634 8.66671 4.96482 8.66671 5.33301ZM8.66671 10.6663C8.66671 11.0345 8.36823 11.333 8.00004 11.333C7.63185 11.333 7.33337 11.0345 7.33337 10.6663V7.99967C7.33337 7.63148 7.63185 7.33301 8.00004 7.33301C8.36823 7.33301 8.66671 7.63148 8.66671 7.99967V10.6663ZM8.00004 1.33301C7.12456 1.33301 6.25766 1.50545 5.44882 1.84048C4.63998 2.17551 3.90505 2.66657 3.286 3.28563C2.03575 4.53587 1.33337 6.23156 1.33337 7.99967C1.33337 9.76779 2.03575 11.4635 3.286 12.7137C3.90505 13.3328 4.63998 13.8238 5.44882 14.1589C6.25766 14.4939 7.12456 14.6663 8.00004 14.6663C9.76815 14.6663 11.4638 13.964 12.7141 12.7137C13.9643 11.4635 14.6667 9.76779 14.6667 7.99967C14.6667 7.1242 14.4943 6.25729 14.1592 5.44845C13.8242 4.63961 13.3331 3.90469 12.7141 3.28563C12.095 2.66657 11.3601 2.17551 10.5513 1.84048C9.74243 1.50545 8.87552 1.33301 8.00004 1.33301Z'
      fill={color}/>
  </SvgIcon>
)

const InformationIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <InformationDarkIcon {...props} />
    : <InformationLightIcon {...props} />
}

export default InformationIcon
