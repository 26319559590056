import { Box, IconButton, styled, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Delete } from '@mui/icons-material'
import { EditIcon, MaximizeIcon } from '@icons/index'

export const DocumentPreviewContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  borderRadius: theme.spacing(1),
}))

export const CheckIcon = () => (
  <IconButton sx={{ top: 0, right: 0 }}>
    <CheckCircleIcon sx={{ fill: '#76F3C2' }} />
  </IconButton>
)

export const DeleteIcon = ({ onClick, ...props }) => {
  const theme = useTheme()
  return (
    <Box onClick={onClick}>
      <Delete
        {...props}
        color={theme.palette.primary.main}
      />
    </Box>
  )
}

export const FullScreenIcon = ({ onClick, ...props }) => {
  const theme = useTheme()

  return (
    <Box onClick={onClick}>
      <MaximizeIcon
        {...props}
        color={theme.palette.primary.main}
      />
    </Box>
  )
}

export const EditDocIcon = ({ ...props }) => {
  const theme = useTheme()
  return (
    <EditIcon
      {...props}
      color={theme.palette.primary.main}
    />
  )
}


export const ActionBox = ({ children, ...props }) => {
  const theme = useTheme()
  return (
    <Box sx={{
      position: 'absolute',
      display: 'flex',
      padding: theme.spacing(1),
      gap: theme.spacing(1),
      '& svg': {
        width: '16px',
        height: '16px',
        cursor: 'pointer',
      },
    }} {...props}>
      {children}
    </Box>
  )
}

export const GradientBack = props => {

  return (
    <Box sx={{
      // eslint-disable-next-line max-len
      background: 'linear-gradient(180deg, rgba(33, 40, 54, 0.8) 0%, rgba(33, 40, 54, 0.5) 50%, rgba(33, 40, 54, 0) 100%)',
      position: 'absolute',
      borderRadius: '8px',
      width: '100%',
      height: '100%',
    }} {...props}/>
  )
}
