import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const TicketLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M17.1586 6.84375C16.7686 6.45366 16.5403 5.93075 16.5193 5.37958C16.4982 4.8284 16.6861 4.28961 17.0452 3.87094C17.0749 3.83612 17.0903 3.79136 17.0884 3.74563C17.0865 3.69991 17.0673 3.65659 17.0348 3.62438L14.9663 1.55344C14.9321 1.51926 14.8857 1.50006 14.8373 1.50006C14.789 1.50006 14.7426 1.51926 14.7084 1.55344L11.4113 4.85063C11.2896 4.97225 11.198 5.12054 11.1436 5.28375C11.0894 5.44733 10.9979 5.59605 10.8763 5.7181C10.7546 5.84015 10.6062 5.93217 10.4428 5.98688C10.2795 6.0413 10.1311 6.13292 10.0092 6.25453L1.55344 14.7084C1.51926 14.7426 1.50006 14.789 1.50006 14.8373C1.50006 14.8857 1.51926 14.9321 1.55344 14.9663L3.62203 17.0348C3.65425 17.0673 3.69756 17.0865 3.74329 17.0884C3.78902 17.0903 3.83377 17.0749 3.86859 17.0452C4.28717 16.6857 4.82609 16.4976 5.37745 16.5185C5.92881 16.5394 6.45193 16.7678 6.84209 17.1579C7.23224 17.5481 7.46063 18.0712 7.48154 18.6226C7.50245 19.1739 7.31433 19.7128 6.95484 20.1314C6.92514 20.1662 6.9097 20.211 6.91162 20.2567C6.91353 20.3024 6.93265 20.3458 6.96516 20.378L9.03375 22.4466C9.06794 22.4807 9.11431 22.4999 9.16266 22.4999C9.211 22.4999 9.25737 22.4807 9.29156 22.4466L17.7478 13.9908C17.8694 13.8689 17.961 13.7205 18.0155 13.5572C18.0696 13.3936 18.1612 13.2449 18.2828 13.1228C18.4044 13.0008 18.5528 12.9088 18.7163 12.8541C18.8795 12.7997 19.0278 12.7081 19.1494 12.5864L22.4466 9.28922C22.4807 9.25503 22.4999 9.20866 22.4999 9.16031C22.4999 9.11197 22.4807 9.0656 22.4466 9.03141L20.378 6.96281C20.3458 6.93031 20.3024 6.91118 20.2567 6.90927C20.211 6.90736 20.1662 6.9228 20.1314 6.9525C19.7133 7.31221 19.1749 7.50084 18.6237 7.48067C18.0725 7.46051 17.5493 7.23304 17.1586 6.84375Z'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={'none'}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M11.7422 6.58313L10.9683 5.80922M13.8056 8.64656L13.2895 8.13094M15.8691 10.7105L15.3534 10.1944M18.1908 13.0317L17.4169 12.2578'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={'none'}
    />
  </SvgIcon>
)

const TicketDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M17.1586 6.84375C16.7686 6.45366 16.5403 5.93075 16.5193 5.37958C16.4982 4.8284 16.6861 4.28961 17.0452 3.87094C17.0749 3.83612 17.0903 3.79136 17.0884 3.74563C17.0865 3.69991 17.0673 3.65659 17.0348 3.62438L14.9663 1.55344C14.9321 1.51926 14.8857 1.50006 14.8373 1.50006C14.789 1.50006 14.7426 1.51926 14.7084 1.55344L11.4113 4.85063C11.2896 4.97225 11.198 5.12054 11.1436 5.28375C11.0894 5.44733 10.9979 5.59605 10.8763 5.7181C10.7546 5.84015 10.6062 5.93217 10.4428 5.98688C10.2795 6.0413 10.1311 6.13292 10.0092 6.25453L1.55344 14.7084C1.51926 14.7426 1.50006 14.789 1.50006 14.8373C1.50006 14.8857 1.51926 14.9321 1.55344 14.9663L3.62203 17.0348C3.65425 17.0673 3.69756 17.0865 3.74329 17.0884C3.78902 17.0903 3.83377 17.0749 3.86859 17.0452C4.28717 16.6857 4.82609 16.4976 5.37745 16.5185C5.92881 16.5394 6.45193 16.7678 6.84209 17.1579C7.23224 17.5481 7.46063 18.0712 7.48154 18.6226C7.50245 19.1739 7.31433 19.7128 6.95484 20.1314C6.92514 20.1662 6.9097 20.211 6.91162 20.2567C6.91353 20.3024 6.93265 20.3458 6.96516 20.378L9.03375 22.4466C9.06794 22.4807 9.11431 22.4999 9.16266 22.4999C9.211 22.4999 9.25737 22.4807 9.29156 22.4466L17.7478 13.9908C17.8694 13.8689 17.961 13.7205 18.0155 13.5572C18.0696 13.3936 18.1612 13.2449 18.2828 13.1228C18.4044 13.0008 18.5528 12.9088 18.7163 12.8541C18.8795 12.7997 19.0278 12.7081 19.1494 12.5864L22.4466 9.28922C22.4807 9.25503 22.4999 9.20866 22.4999 9.16031C22.4999 9.11197 22.4807 9.0656 22.4466 9.03141L20.378 6.96281C20.3458 6.93031 20.3024 6.91118 20.2567 6.90927C20.211 6.90736 20.1662 6.9228 20.1314 6.9525C19.7133 7.31221 19.1749 7.50084 18.6237 7.48067C18.0725 7.46051 17.5493 7.23304 17.1586 6.84375Z'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={'none'}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M11.7422 6.58313L10.9683 5.80922M13.8056 8.64656L13.2895 8.13094M15.8691 10.7105L15.3534 10.1944M18.1908 13.0317L17.4169 12.2578'
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill={'none'}
    />
  </SvgIcon>
)

const TicketIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <TicketDarkIcon {...props} /> : <TicketLightIcon {...props} />
}

export default TicketIcon
