import { styled } from '@mui/material/styles'

const StyledOption = styled('div')(
  ({ theme }) => ({
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    '&:hover': {
      backgroundColor: `${theme.palette.background.dark}CC`,
      backdropFilter: 'blur(3px)',
      cursor: 'pointer',
    },
  })
)

const CustomOption = ({ children, ...props }) => {

  return <StyledOption {...props}>
    {children}
  </StyledOption>
}

export default CustomOption
