/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getProductsInCatalogueListService } from '@App/services/productInCatalogue/getProductInCatalogueList'
import { updateProductInCatalogueService } from '@App/services/productInCatalogue/updateProductInCatalogue'
import { addProductToCatalogueService } from '@App/services/productInCatalogue/addProductToCatalogue'
import { deleteProductFromCatalogueService } from '@App/services/productInCatalogue/deleteProductFromCatalogue'
import { deleteProductFromCataloguesService } from '@App/services/productInCatalogue/deleteProductFromCatalogues'

export default function useProductsInCatalogue() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getProductsInCatalogue = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getProductsInCatalogueListService(apolloClient, { variables, options })
        resolve(response.productsInCatalogue)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updateProductInCatalogue = useCallback(({ input }) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = { input: { ...input } }
        const response = await updateProductInCatalogueService(apolloClient, { variables })
        resolve(response.updateProductInCatalogue)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])


  const addProductToCatalogue = useCallback(({ input }) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = { input }
        const response = await addProductToCatalogueService(apolloClient, { variables })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const deleteProductFromCatalogue = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await deleteProductFromCatalogueService(apolloClient, { id, options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const deleteProductFromCatalogues = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await deleteProductFromCataloguesService(apolloClient, { id, options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    loadingSource,
    getProductsInCatalogue,
    updateProductInCatalogue,
    addProductToCatalogue,
    deleteProductFromCatalogue,
    deleteProductFromCatalogues,
  }
}
