import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import FilterChipButton from './FilterChipButton'

const FilterChipDropdown = ({ label, value, open, readOnly, onClick, highlighted }) => {

  return <FilterChipButton
    label={ value ?? label}
    onClick={onClick}
    highlighted={highlighted}
    icon={ open ?
      !readOnly && <KeyboardArrowUpOutlinedIcon fontSize={'small'}/> :
      !readOnly && <KeyboardArrowDownOutlinedIcon fontSize={'small'}/> }/>
}

export default FilterChipDropdown
