/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ClipboardLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const ClipboardDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const ClipboardIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <ClipboardDarkIcon {...props} />
    : <ClipboardLightIcon {...props} />
}

export default ClipboardIcon
