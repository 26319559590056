import { Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

const Container = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  gap: theme.spacing(1),
  flexDirection: 'column',
}))

const Label = styled(props => <Box {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const StyledTextField = styled(props => <TextField {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  fontWeight: 500,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.gray[300]}`,
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& input': {
    border: 'none',
    font: theme.font.roboto.paragraph.medium,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  '& fieldset': {
    display: 'none',
  },
}))

const CustomOnlyDigitsInput = ({ label, initialValue, onChange, error, errorMessage, ...props }) => {

  const [inputValue, setInputValue] = useState(initialValue)

  const isInputValid = input => {
    const regex = /^\d*$/
    return regex.test(input)
  }

  const handleChange = e => {
    if(!isInputValid(e.target.value)) return
    setInputValue(e.target.value)
    onChange(e.target.value)
  }

  return <Container sx={props.sx}>
    <Label>
      { label }
    </Label>
    <StyledTextField
      onChange={handleChange}
      value={inputValue}
      error={error}
      helperText={error ? errorMessage : null}
      {...props}
    />
  </Container>
}

export default CustomOnlyDigitsInput
