import { setLocale } from '@graphql/language.graphql'

export const updateLanguageService = async (apolloClient, variables) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: setLocale,
      variables: {
        id: variables,
      },
    })
    return resp.data
  } catch (error) {
    throw new Error(error)
  }
}
