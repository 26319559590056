import { filterCategoriesWithUpcomingEvents } from '@graphql/eventCategory.graphql'

export const filterByUpcomingEventsService = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: filterCategoriesWithUpcomingEvents,
      variables: { ...variables },
      ...options,
    })

    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
