import { useCallback, useContext } from 'react'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'

import useError from '@hooks/useError'
import useGlossary from '@hooks/useGlossary'
import ArrowLeftIcon from './Icons/ArrowLeftIcon'
import CustomButton from './CustomButton'
import CustomDivider from './CustomDivider'

const Root = styled(props => <Box {...props}/>)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  fontWeight: '600',
  font: theme.font.roboto.paragraph.medium,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '10px',
  padding: 0,
}))

const BackButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  padding: 0,
  color: theme.palette.secondary.main,
  '& svg path': {
    fill: theme.palette.secondary.main,
  },
}))

const CustomLink = styled(props => <Link {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
}))

/**
 * @param breadcrumbsContent List of objects {title, pathname}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GoBackBreadcrumbs = ({ breadcrumbsContent = [], ...props }) => {

  const router = useRouter()
  const { showAlert } = useError()
  const { glossary } = useGlossary()

  const handleBack = () => {
    router.back()
  }

  const handleClick = useCallback((e, pathname) => {
    router.push({
      pathname,
    }).then(resp => {})
      .catch(err => showAlert(err.toString()))
  }, [router])

  return <Root>
    <BackButton variant='text' startIcon={<ArrowLeftIcon/>} onClick={handleBack}>
      { glossary('GoBack') }
    </BackButton>
    { breadcrumbsContent.length > 0 && <CustomDivider orientation='vertical' flexItem /> }
    { breadcrumbsContent.length > 0 &&
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} >
        {
          breadcrumbsContent.slice(0, breadcrumbsContent.length-1).map((page, index) =>
            <CustomLink underline='hover' key={index} component='button' onClick={e => handleClick(e, page.pathname)}>
              {page.title}
            </CustomLink>)
        }
        <Typography>{breadcrumbsContent[breadcrumbsContent.length-1].title}</Typography>
      </Breadcrumbs>
    }
  </Root>
}

export default GoBackBreadcrumbs
