import { getPurchaseForClient } from '@graphql/purchase.graphql'

export const getPurchaseForClientService = async (apolloClient, { id, options }) => {
  try {
    const resp = await apolloClient.query({
      query: getPurchaseForClient,
      variables: { id },
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
