import {useCallback, useState} from 'react'
import { useApolloClient } from '@apollo/client'
import { updateLanguageService } from '@services/language/updateLanguageService'


export default function useLanguage() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const changeLanguage = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await updateLanguageService(apolloClient, variables)
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
   loading,
   changeLanguage,
  }
}
