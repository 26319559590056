import {useState, useEffect, useContext} from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  useDebounce,
  useCity,
  useRegion,
  useCountry,
  useGlossary
} from '@hooks/index'
import { locationToString } from '@helpers/location'
import { Context as LocaleContext } from '@features/Locale'

const GroupTypography = styled(props => <Typography {...props}/>)(({ theme }) => ({
  fontSize: 'small',
  padding: '6px',
  paddingLeft: '10px',
  color: theme.palette.gray['500'],
}))

const LocationAutocomplete = ({ selectedLocation, onSelectLocation }) => {

  const { glossary } = useGlossary()
  const { getCities } = useCity()
  const { getRegions } = useRegion()
  const { getCountries } = useCountry()
  const { currentLocale } = useContext(LocaleContext)

  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [queryLocations, setQueryLocations] = useState([])
  const debouncedQuery = useDebounce(query, 500)

  const fetchOptions = async query => {
    if(query.length === 0) return []
    const ops = {
      num: 7,
      pag: 0,
    }
    let locations = []
    await getCities({ filter: { name: query }, ...ops }).then(resp => {
      locations = locations.concat( resp.edges.map(l => l.node) )
    })
    await getRegions({ filter: { query }, ...ops }).then(resp => {
      locations = locations.concat( resp.edges.map(l => l.node) )
    })
    await getCountries({ filter: { query }, ...ops }).then(resp => {
      locations = locations.concat( resp.edges.map(l => l.node) )
    })
    // filter out all the locations without name or id
    locations = locations.filter(l => {
      return Boolean(l) && Boolean(l.id) && Boolean(l.name)
    })
    return locations
  }

  useEffect(() => {
    setIsLoading(true)
    fetchOptions(query).then(locations => {
      setQueryLocations(locations)
      setIsLoading(false)
    })
  }, [debouncedQuery])

  return <Autocomplete
    onChange={(event, val) => {
      event.stopPropagation()
      onSelectLocation(val)
    }}
    value={selectedLocation}
    options={queryLocations}
    getOptionLabel={op => locationToString(op, false, currentLocale)}
    renderInput={params => (
      <TextField {...params}
        variant='outlined'
        label={glossary('SelectLocation')}
        value={query}
        onChange={e => setQuery(e.target.value)}
      />
    )}
    groupBy={option => option.__typename}
    renderGroup={params => {
      return <div key={`group-${params.group}`}>
        <GroupTypography>
          {params.group=='Region' ?
            glossary('Regions') :
            ( params.group=='City' ? glossary('Cities') : glossary('Countries') )
          }
        </GroupTypography>
        <div>{params.children}</div>
      </div>
    }
    }
    loading={debouncedQuery.length === 0 || isLoading}
    loadingText={isLoading ? glossary.Searching : glossary('TypeToSearch')}
    noOptionsText={glossary('NoOptions')}
    sx={{ minWidth:'200px' }}
  />
}

export default LocationAutocomplete
