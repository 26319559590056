import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const TrendingTopicLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M14.5 5.5C14.1878 4.56335 14.4645 3.56578 14.7595 2.93526C14.8359 2.77201 14.6888 2.57314 14.5156 2.62307C10.5738 3.75954 11.5 7.03758 11.5 8C11.5 9 12 10 11 10.5C9.55724 11.2214 8.71365 9.50053 8.87945 7.71138C8.89816 7.5094 8.63385 7.367 8.4921 7.5121C6.99688 9.04265 5 11.3719 5 14.5C5 18 8 21.5 12.5 21.5C17 21.5 19 16.5 19 14.5C19 12.5 18 11 17 9.5C16.5 8.75 15 7 14.5 5.5Z'
      fill={color}
    />
  </SvgIcon>
)

const TrendingTopicDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M14.5 5.5C14.1878 4.56335 14.4645 3.56578 14.7595 2.93526C14.8359 2.77201 14.6888 2.57314 14.5156 2.62307C10.5738 3.75954 11.5 7.03758 11.5 8C11.5 9 12 10 11 10.5C9.55724 11.2214 8.71365 9.50053 8.87945 7.71138C8.89816 7.5094 8.63385 7.367 8.4921 7.5121C6.99688 9.04265 5 11.3719 5 14.5C5 18 8 21.5 12.5 21.5C17 21.5 19 16.5 19 14.5C19 12.5 18 11 17 9.5C16.5 8.75 15 7 14.5 5.5Z'
      fill={color}
    />
  </SvgIcon>
)

const TrendingTopicIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <TrendingTopicDarkIcon {...props} /> : <TrendingTopicLightIcon {...props} />
}

export default TrendingTopicIcon
