/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const DocLinesLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
   <path d='M11 7H16.5L11 1.5V7ZM2 0H12L18 6V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 0.89 0.89 0 2 0ZM2 9V11H16V9H2ZM2 13V15H11V13H2Z' 
   fill={color}/>
  </SvgIcon>
)

const DocLinesDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
   <path d='M11 7H16.5L11 1.5V7ZM2 0H12L18 6V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 0.89 0.89 0 2 0ZM2 9V11H16V9H2ZM2 13V15H11V13H2Z' 
   fill={color}/>
  </SvgIcon>
)

const DocLinesIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <DocLinesDarkIcon {...props} />
    : <DocLinesLightIcon {...props} />
}

export default DocLinesIcon
