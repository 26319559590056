/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ClosetLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M11.0005 8.551V7.873C11.8587 7.65053 12.6188 7.14959 13.1617 6.44869C13.7047 5.7478 13.9997 4.88657 14.0005 4C14.0005 1.794 12.2065 0 10.0005 0C7.79451 0 6.00051 1.794 6.00051 4H8.00051C8.00051 2.897 8.89751 2 10.0005 2C11.1035 2 12.0005 2.897 12.0005 4C12.0005 5.103 11.1035 6 10.0005 6C9.73529 6 9.48094 6.10536 9.2934 6.29289C9.10587 6.48043 9.00051 6.73478 9.00051 7V8.551L0.33551 16.253C0.18459 16.388 0.0781738 16.5657 0.0303124 16.7624C-0.0175489 16.9592 -0.00460412 17.1659 0.0674378 17.3551C0.13948 17.5444 0.26723 17.7073 0.43382 17.8225C0.60041 17.9376 0.798004 17.9995 1.00051 18H19.0005C19.2032 17.9995 19.4009 17.9376 19.5676 17.8223C19.7342 17.707 19.862 17.5439 19.9339 17.3545C20.0059 17.165 20.0187 16.9582 19.9706 16.7614C19.9225 16.5645 19.8157 16.3869 19.6645 16.252L11.0005 8.551ZM3.63051 16L10.0005 10.338L16.3705 16H3.63051Z'
      fill={color}
    />
  </SvgIcon>
)

const ClosetDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M11.0005 8.551V7.873C11.8587 7.65053 12.6188 7.14959 13.1617 6.44869C13.7047 5.7478 13.9997 4.88657 14.0005 4C14.0005 1.794 12.2065 0 10.0005 0C7.79451 0 6.00051 1.794 6.00051 4H8.00051C8.00051 2.897 8.89751 2 10.0005 2C11.1035 2 12.0005 2.897 12.0005 4C12.0005 5.103 11.1035 6 10.0005 6C9.73529 6 9.48094 6.10536 9.2934 6.29289C9.10587 6.48043 9.00051 6.73478 9.00051 7V8.551L0.33551 16.253C0.18459 16.388 0.0781738 16.5657 0.0303124 16.7624C-0.0175489 16.9592 -0.00460412 17.1659 0.0674378 17.3551C0.13948 17.5444 0.26723 17.7073 0.43382 17.8225C0.60041 17.9376 0.798004 17.9995 1.00051 18H19.0005C19.2032 17.9995 19.4009 17.9376 19.5676 17.8223C19.7342 17.707 19.862 17.5439 19.9339 17.3545C20.0059 17.165 20.0187 16.9582 19.9706 16.7614C19.9225 16.5645 19.8157 16.3869 19.6645 16.252L11.0005 8.551ZM3.63051 16L10.0005 10.338L16.3705 16H3.63051Z'
      fill={color}
    />
  </SvgIcon>
)

const ClosetIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <ClosetDarkIcon {...props} />
    : <ClosetLightIcon {...props} />
}

export default ClosetIcon
