/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getPurchaseByEmailService } from '@services/purchase/getPurchaseByEmail'
import { createPurchaseService } from '@services/purchase/createPurchase'
import { updatePurchaseService } from '@services/purchase/updatePurchase'
import { payWithTransferService } from '../services/purchase/payWithTransfer'
import { getPurchasesForOwnerListService } from '@services/purchase/getPurchasesForOwnerList'
import { getPurchasesForClientListService } from '@services/purchase/getPurchasesForClientList'
import { getPurchaseForClientService } from '@services/purchase/getPurchaseForClient'
import { getPurchasePaymentMethodService } from '@services/purchase/getPurchasePaymentMethod'
import { getPurchaseActnetPaymentMethodService } from '@services/purchase/getPurchaseActnetPaymentMethod'

export default function usePurchase() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const recoverByEmail = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPurchaseByEmailService(apolloClient, { variables, options })
        resolve(response.recoverByEmail)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const createPurchase = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await createPurchaseService(apolloClient, { variables, options })
        resolve(response.createPurchase)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const updatePurchase = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await updatePurchaseService(apolloClient, { variables, options })
        resolve(response.updatePurchase)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const payWithTransfer = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await payWithTransferService(apolloClient, { variables: { 
          purchaseId: id, 
        }, options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getPurchasesForOwner = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getPurchasesForOwnerListService(apolloClient, { variables, options })
        resolve(response.boxEventPurchases)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getPurchaseForClient = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPurchaseForClientService(apolloClient, { id, options })
        resolve(response.boxEventPurchase)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getPurchasePaymentMethod = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPurchasePaymentMethodService(apolloClient, { purchaseId: id, options })
        resolve(response.getPurchasePaymentMethod)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getPurchaseActnetPaymentMethod = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getPurchaseActnetPaymentMethodService(apolloClient, { purchaseId: id, options })
        resolve(response.getPurchaseActnetPaymentMethod)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getPurchasesForClient = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getPurchasesForClientListService(apolloClient, { variables, options })
        resolve(response.boxEventPurchases)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    recoverByEmail,
    createPurchase,
    updatePurchase,
    payWithTransfer,
    getPurchasesForOwner,
    getPurchasesForClient,
    getPurchaseForClient,
    getPurchasePaymentMethod,
    getPurchaseActnetPaymentMethod,
  }
}
