/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { updateUserService } from '../services/user/updateUser'
import { getUserDetailService } from '../services/user/getUserDetail'
import { deleteUserAddressService } from '../services/user/deleteUserAddresService'

export default function useUser() {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getUser = useCallback(options => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getUserDetailService(apolloClient, { options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updateUser = useCallback(({ input }) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = { input }
        const response = await updateUserService(apolloClient, { variables })
        resolve(response.updateUser)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const deleteUserAddress = useCallback(input => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables =  input
        const response = await deleteUserAddressService(apolloClient, { variables })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    loadingSource,
    getUser,
    updateUser,
    deleteUserAddress,
  }
}
