import { styled } from '@mui/material/styles'
import { Accordion } from '@mui/material'

const StyledAccordion = styled(props => <Accordion {...props}/>)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    background: 'transparent',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '0px',
      marginTop: '10px',
    },
  })
)

const Collapsable = props => {

  return <StyledAccordion
    {...props}
  />
}

export default Collapsable
