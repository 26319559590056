import { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { updatedBoxEvent } from '@graphql/publication.graphql'

export default function useBoxEventUpdateSubscription(boxEventId) {

  const [updatedPublication, setUpdatedPublication] = useState(null)

  const updatedBoxEventSubscription = useSubscription(updatedBoxEvent, {
    shouldResubscribe: true,
    variables: {
      id: boxEventId,
    },
  })

  useEffect(() => {
    if (!updatedBoxEventSubscription) return
    if (updatedBoxEventSubscription.loading) return
    if (!updatedBoxEventSubscription.data) return
    if (!updatedBoxEventSubscription.data.updatedBoxEvent) return
    setUpdatedPublication(updatedBoxEventSubscription.data.updatedBoxEvent)
  }, [updatedBoxEventSubscription])

  return { updatedPublication }
}
