import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'

/**
 * Returns a component that, as soon as it enters the viewport, will make function onIntersection execute.
 * @param {function} onIntersection - Function that will be executed when the component enters the viewport.
 * @returns {JSX.Element} - MUI Box (of dimension 1px x 1px)
 * @constructor
 */
const IntersectionObserverBox = ({ onIntersection }) => {

  const boxRef = useRef(null)

  /*
   * Execute onIntersection as soon as the bottom of the
   * element with the reference boxRef enters the viewport.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries
      if (entry.isIntersecting) {
        // The bottom of the box has entered the viewport
        onIntersection()
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })
    if (boxRef.current) {
      observer.observe(boxRef.current)
    }
    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current)
      }
    }
  }, [onIntersection])

  return <Box ref={boxRef} sx={{ width:'1px', height:'1px' }}/>
}

export default IntersectionObserverBox
