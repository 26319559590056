import { setAgentCurrency } from '@graphql/currency.graphql'

export const setAgentCurrencyService = async (apolloClient, { id, options = {} }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: setAgentCurrency,
      variables: {
        id,
      },
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
