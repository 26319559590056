import {useCallback, useContext} from 'react'
import Context from '@context/App'
import { useApolloClient } from '@apollo/client'
import { getCurrenciesListService } from '@services/currency/getCurrencyList'
import { getCurrencyService } from '@services/currency/getCurrency'
import { setAgentCurrencyService } from '@services/currency/setAgentCurrency'

const AVAILABLE_CURRENCIES = ['MXN', 'USD']

export default function useCurrency() {

  const apolloClient = useApolloClient()
  const { currency, dispatch } = useContext(Context)

  const setCurrency = async _currency => {
    dispatch({
      type: 'SET_CURRENCY',
      payload: _currency,
    })
    await setAgentCurrencyService(apolloClient, {
      id: _currency.id,
    })
  }

  const getAvailableCurrencies = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const variables = {
          num: AVAILABLE_CURRENCIES.length,
          filter: {
            id: AVAILABLE_CURRENCIES,
          }
        }
        const response = await getCurrenciesListService(apolloClient, {
          variables,
        })
        resolve(response.currencies?.edges?.map(e => e.node))
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getCurrency =  useCallback(id => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getCurrencyService(apolloClient, {
          id,
        })
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    currency,
    setCurrency,
    getAvailableCurrencies,
    getCurrency,
  }
}
