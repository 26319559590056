import { useEffect, useState, useCallback } from 'react'
import { Chip, TextField } from '@mui/material'

import useDebounce from '@hooks/useDebounce'
import usePerformer from '@hooks/usePerformer'
import useGlossary from '@hooks/useGlossary'
import CustomAutocomplete from '@components/CustomAutocomplete'

const PerformerFilter = ({ initialSelectedPerformers=[], onSelectPerformers, maxSelected = 5 }) => {

  const { glossary } = useGlossary()
  const { getPerformers } = usePerformer()

  const [selectedPerformers, setSelectedPerformers] = useState(initialSelectedPerformers)
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [queryPerformers, setQueryPerformers] = useState([])
  const debouncedQuery = useDebounce(query, 500)

  const fetchOptions = useCallback( query => {
    if(query.length === 0) return
    setIsLoading(true)
    const filter = {
      num: 12,
      pag: 0,
      filter: {
        query,
      },
    }
    getPerformers(filter).then(resp => {
      setQueryPerformers(resp.edges.map(edge => edge.node))
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const handleSelectionChange = (event, newSelection) => {
    event.stopPropagation()
    const _val = []
    newSelection.map( f => _val.push(f))
    setSelectedPerformers(_val)
    setIsDisabled(_val.length >= maxSelected)
    if(onSelectPerformers) onSelectPerformers(_val)
  }

  const handleTagDelete = useCallback((event, option) => {
    const newSelection = selectedPerformers.filter(perf => perf.id !== option.id)
    handleSelectionChange(event, newSelection)
  }, [selectedPerformers])

  const handleInputChange = (event, newInput) => {
    setQuery(newInput)
  }

  useEffect(() => {
    fetchOptions(debouncedQuery)
  }, [debouncedQuery])

  useEffect(() => {
    setSelectedPerformers(initialSelectedPerformers)
  }, [initialSelectedPerformers])

  return <CustomAutocomplete
    multiple
    id={'forum-filter-autocomplete'}
    filterSelectedOptions={true}
    options={queryPerformers}
    freeSolo={true}
    value={selectedPerformers}
    getOptionLabel={option => option.name}
    onChange={handleSelectionChange}
    onInputChange = {handleInputChange}
    renderInput={params => (
      <TextField {...params}
        variant='outlined'
        label={glossary('SelectPerformer')}
      />
    )}
    disabled={isDisabled}
    limitTags={maxSelected}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          key={index}
          label={option.name}
          sx={{ maxWidth: '140px' }}
          onDelete={e => handleTagDelete(e, option)}
        />
      ))
    }
    loading={debouncedQuery.length === 0 || isLoading}
    loadingText={isLoading ? glossary('Searching') : glossary('TypeToSearch')}
    noOptionsText={glossary('NoOptions')}
    sx={{ width:'100%', height:'100%' }}
  />
}

export default PerformerFilter
