import { useContext, useEffect, useMemo, useReducer } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import useCurrency from '@hooks/useCurrency'
import { agent } from '@graphql/session.graphql'
import { AppReducer } from '@context/App/reducer'
import AppContext from '.'

export default function AppContextProvider({ children }) {

  const INITIAL_STATE = useContext({
    ...AppContext,
  })

  const [store, dispatch] = useReducer(AppReducer, INITIAL_STATE)

  const { getCurrency, setCurrency } = useCurrency()
  const { enqueueSnackbar } = useSnackbar()

  const { refetch } = useQuery(agent, {
    fetchPolicy: 'no-cache',
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error' })
    },
    onCompleted: data => {
      if (!data?.agent) return
      dispatch({
        type: 'SET_AGENT',
        payload: data.agent,
      })
    },
  })

  const memorizedValues = useMemo(
    () => ({
      ...store,
      refetch,
      dispatch,
    }),
    [store]
  )

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (!store.agent) return

    // Select the agent's currency
    if (typeof window !== 'undefined' ) {
      if(store.agent?.currency.id === 'MXN') {
        dispatch({
          type: 'SET_CURRENCY',
          payload: store.agent?.currency,
        })
      } else {
        getCurrency('USD').then(resp => {
          dispatch({
            type: 'SET_CURRENCY',
            payload: resp.currency,
          })
        })
      }
    }
  }, [store.agent])

  return (
    <AppContext.Provider value={memorizedValues}>
      {children}
    </AppContext.Provider>
  )
}

