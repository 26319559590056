import { styled } from '@mui/material/styles'
import { Box, Modal } from '@mui/material'

const StyledModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff', // theme.palette.background[90],
  boxShadow: 24,
}))

const ResponsiveModal = ({ children, ...props }) => {

  return <Modal {...props} sx={{ display:'flex', justifyContent:'center', alignItems: 'center' }}>
    <StyledModalContent>
      {children}
    </StyledModalContent>
  </Modal>
}

export default ResponsiveModal
