import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

const StyledButton = styled(props => <Button {...props}/>)(({ theme }) => ({
  borderRadius: '50cqi',
  font: theme.font.roboto.paragraph.medium,
  height: '100%',
  minWidth: 'fit-content',
  textTransform: 'none',
}))

const CustomButton = ({ children, ...props }) => {

  return <StyledButton {...props}>
    { children }
  </StyledButton>
}

export default CustomButton
