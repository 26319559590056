import { payWithTransferProducts } from '@graphql/productPurchase.graphql'

export const payWithTransferProductsService = async (apolloClient, { variables, options }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: payWithTransferProducts,
      variables,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
