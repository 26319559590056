import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, IconButton, Chip, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import useTheme from '@hooks/useTheme'
import useBox from '@hooks/useBox'
import useSession from '@hooks/useSession'
import useGlossary from '@hooks/useGlossary'
import CustomAutocomplete from '@components/CustomAutocomplete'
import CustomButton from '@components/CustomButton'

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const Label = styled(Box)(({ theme }) => ({
  fontWeight: 'Bold',
}))

const AddAllButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  color: theme.palette.gray[300],
  border: `1px solid ${theme.palette.gray[300]}`,
  '&:hover': {
    border: `1px solid ${theme.palette.gray[300]}`,
    backgroundColor: theme.palette.gray[300],
  },
}))

/**
 * If initialBox is defined, we take it as the initially selected box. Otherwise, we use
 * initiallySelectedBoxId (can be one id or an array of ids) to retrieve the selected box(es) objects.
 * @param initiallySelectedBoxId Id string or array of Id strings.
 * @param initialBox Box object. Can be null.
 * @param onSelectBox
 * @param multiple
 * @param onlyActive
 * @returns {JSX.Element}
 * @constructor
 */
const UserBoxesAutocomplete = ({
  initiallySelectedBoxId,
  initialBox, onSelectBox,
  multiple=false, onlyActive=false,
}) => {

  const { getBoxes } = useBox()
  const { session } = useSession()
  const { glossary } = useGlossary()
  const { theme } = useTheme()

  const [boxes, setBoxes] = useState([])
  const [selectedBox, setSelectedBox] = useState(initialBox ?? null)
  const [selectedBoxMultiple, setSelectedBoxMultiple] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSelectBox = (event, newBox) => {
    setSelectedBox(newBox)
    onSelectBox(newBox)
  }

  const handleSelectBoxMultiple = (event, newBoxes) => {
    setSelectedBoxMultiple(newBoxes)
    onSelectBox(newBoxes)
  }

  const handleSelectForum = forumName => {
    const forumBoxes = boxes.filter(box => box.forum.name === forumName)
    const _boxes = [...selectedBoxMultiple]
    forumBoxes.map(box => {
      if(!selectedBoxMultiple.find(b => b.id === box.id)) _boxes.push(box)
    })
    setSelectedBoxMultiple(_boxes)
    onSelectBox(_boxes)
  }

  const handleTagDelete = (event, option) => {
    const newSelection = selectedBoxMultiple.filter(b => b.id !== option.id)
    handleSelectBoxMultiple(event, newSelection)
  }

  useEffect(() => {
    setLoading(true)
    if(session?.user?.id)
      getBoxes({
        filter: {
          owner: [session.user.id],
          status: onlyActive ? ['3'] : null,
        },
        num: 500,
      }).then(resp => {
        const _boxes = resp.edges.map(edge => edge.node)
        setBoxes(_boxes.sort((b1, b2) => {
          if (b1.forum?.name < b2.forum?.name)
            return -1
          else if (b1.forum?.name > b2.forum?.name)
            return 1
          else return 0
        }))
      }).finally(() => setLoading(false))
  }, [session])

  useEffect(() => {
    if(selectedBox || selectedBoxMultiple) return
    if(!multiple) setSelectedBox(boxes.find(b => b.id === initiallySelectedBoxId))
    else setSelectedBoxMultiple(boxes.filter(b => Boolean(initiallySelectedBoxId.find(id => id===b.id))))
  }, [boxes, initiallySelectedBoxId])
  
  return <Root>
    { !multiple ?
      <CustomAutocomplete
        value={ selectedBox }
        options={boxes}
        filterSelectedOptions={true}
        onChange={handleSelectBox}
        getOptionLabel={option => option.rowName}
        groupBy={option => option.forum?.name}
        renderOption={(props, option) => (
          <Box component='li' {...props}>
            { option.rowName }
          </Box>
        )}
        renderInput={params => (
          <TextField {...params}
            variant='outlined'
            label={glossary('SearchByPropertyName')}
          />
        )}
        renderGroup={params => (
          <Box key={params.key} sx={{ paddingLeft: '16px' }}>
            <Typography
              sx={{ 
                fontWeight: 'bold', 
                color: 'primary.main', 
                position: 'sticky', 
                top: '-8px', 
                backgroundColor: 'white', 
                zIndex: 1,
                paddingTop: '8px',
              }}
            >
              {params.group}
            </Typography>
            {params.children}
          </Box>
        )}
        loading={loading}
        loadingText={ glossary('Loading') }
        noOptionsText={ glossary('NoOptions') }
      />
      : <CustomAutocomplete
        multiple
        value={ selectedBoxMultiple }
        options={boxes}
        filterSelectedOptions={true}
        onChange={handleSelectBoxMultiple}
        getOptionLabel={option => option.rowName}
        groupBy={option => option.forum?.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={option.rowName}
              sx={{ maxWidth: '140px' }}
              onDelete={e => handleTagDelete(e, option)}
            />
          ))
        }
        renderInput={params => (
          <TextField {...params}
                     variant='outlined'
                     label={glossary('SearchByPropertyName')}
          />
        )}
        renderGroup={params => (
          <Box key={params.key} sx={{ paddingLeft: '16px' }}>
            <Box
              sx={{
                width: '100%',
                fontWeight: 'bold',
                color: 'primary.main',
                position: 'sticky',
                top: '-8px',
                backgroundColor: 'white',
                zIndex: 1,
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingRight: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={() => handleSelectForum(params.group)}
            >
              {params.group}
              <IconButton size={'small'}>
                <AddIcon fontSize={'small'}/>
              </IconButton>
            </Box>
            {params.children}
          </Box>
        )}
        loading={loading}
        loadingText={ glossary('Loading') }
        noOptionsText={ glossary('NoOptions') }
      />
    }
  </Root>
}

export default UserBoxesAutocomplete
