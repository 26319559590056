/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const PinLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M4.5 7.4V17M4.5 7.4C5.42826 7.4 6.3185 7.06286 6.97487 6.46274C7.63125 5.86263 8 5.04869 8 4.2C8 3.35131 7.63125 2.53737 6.97487 1.93726C6.3185 1.33714 5.42826 1 4.5 1C3.57174 1 2.6815 1.33714 2.02513 1.93726C1.36875 2.53737 1 3.35131 1 4.2C1 5.04869 1.36875 5.86263 2.02513 6.46274C2.6815 7.06286 3.57174 7.4 4.5 7.4V7.4Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const PinDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M4.5 7.4V17M4.5 7.4C5.42826 7.4 6.3185 7.06286 6.97487 6.46274C7.63125 5.86263 8 5.04869 8 4.2C8 3.35131 7.63125 2.53737 6.97487 1.93726C6.3185 1.33714 5.42826 1 4.5 1C3.57174 1 2.6815 1.33714 2.02513 1.93726C1.36875 2.53737 1 3.35131 1 4.2C1 5.04869 1.36875 5.86263 2.02513 6.46274C2.6815 7.06286 3.57174 7.4 4.5 7.4V7.4Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const PinIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <PinDarkIcon {...props} />
    : <PinLightIcon {...props} />
}

export default PinIcon
