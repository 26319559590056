import { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { updatedBoxEventPurchase } from '@graphql/purchase.graphql'

export default function usePurchaseUpdateSubscription(purchaseId) {

  const [updatedPurchase, setUpdatedPurchase] = useState(null)

  const updatedPurchaseSubscription = useSubscription(updatedBoxEventPurchase, {
    shouldResubscribe: true,
    variables: {
      id: purchaseId,
    },
  })

  useEffect(() => {
    if (!updatedPurchaseSubscription) return
    if (updatedPurchaseSubscription.loading) return
    if (!updatedPurchaseSubscription.data) return
    if (!updatedPurchaseSubscription.data.updatedBoxEventPurchase) return
    setUpdatedPurchase(updatedPurchaseSubscription.data.updatedBoxEventPurchase)
  }, [updatedPurchaseSubscription])

  return { updatedPurchase }
}
