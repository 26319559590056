import { sendWaitlist } from '@graphql/waitList.graphql'

// eslint-disable-next-line max-len
export const waitlistSignUp = async (apolloClient, variables ) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: sendWaitlist,
      variables
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
