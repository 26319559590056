import { createPurchaseCfdi } from '@graphql/cfdi.graphql'

export const createPurchaseCfdiService = async (apolloClient, { variables, options }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: createPurchaseCfdi,
      variables,
      ...options,
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
