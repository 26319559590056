import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ListViewLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M3 13H5V11H3V13ZM3 17H5V15H3V17ZM3 9H5V7H3V9ZM7 13H21V11H7V13ZM7 17H21V15H7V17ZM7 7V9H21V7H7Z'
      fill={color}
    />
  </SvgIcon>
)

const ListViewDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M3 13H5V11H3V13ZM3 17H5V15H3V17ZM3 9H5V7H3V9ZM7 13H21V11H7V13ZM7 17H21V15H7V17ZM7 7V9H21V7H7Z'
      fill={color}
    />
  </SvgIcon>
)

const ListViewIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ListViewDarkIcon {...props} /> : <ListViewLightIcon {...props} />
}

export default ListViewIcon
