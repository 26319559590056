import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledGreenButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:disabled': {
    backgroundColor: theme.palette.primary.light,
  },
}))

const GreenButton = ({ children, ...props }) => {

  return <StyledGreenButton {...props}>
    { children }
  </StyledGreenButton>
}

export default GreenButton
