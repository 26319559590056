import { createContext, useState, useContext, useEffect } from 'react'
import Context from '@context/App'

const LocationContext = createContext({})

const LocationProvider = ({ children }) => {

  const { agent } = useContext(Context)
  const [location, setLocation] = useState(undefined)

  useEffect(() => {
    if (!agent) return

    if (typeof window !== 'undefined' ) {
      const storedLocation = localStorage.getItem('userLocation')
      if (storedLocation) {
        if(storedLocation === '{}') {
          setLocation(null)
          return
        }
        const loc = JSON.parse(storedLocation)
        if(loc && loc.id && loc.name)
          setLocation(loc)
      } else if(agent?.remoteSession?.remoteAddress?.region?.country) {
        const loc = agent.remoteSession.remoteAddress.region.country
        localStorage.setItem('userLocation', JSON.stringify(loc))
        setLocation(loc)
      } else if(agent?.remoteSession?.remoteAddress?.region) {
        const loc = agent.remoteSession.remoteAddress.region
        localStorage.setItem('userLocation', JSON.stringify(loc))
        setLocation(loc)
      }
    }
  }, [agent])

  useEffect(() => {
    // location null significa que el usuario seleccionó la ubicación vacía,
    // entonces almacenamos la ubicación como {}, para diferenciar entre cuando
    // aún no se toma la ubicación, y cuando el usuario decidió quitarle la ubicación
    if(location === null)
      localStorage.setItem('userLocation', JSON.stringify({}))
    else if(location !== undefined)
      localStorage.setItem('userLocation', JSON.stringify(location))
  }, [location])

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  )
}

const useContextLocation = () => {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useContextLocation must be used within a LocationProvider')
  }
  return context
}

export { LocationProvider, useContextLocation }
