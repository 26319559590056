import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const PaymentLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M20.5 4H4.5C3.96957 4 3.46086 4.21071 3.08579 4.58579C2.71071 4.96086 2.5 5.46957 2.5 6V18C2.5 18.5304 2.71071 19.0391 3.08579 19.4142C3.46086 19.7893 3.96957 20 4.5 20H13.59C13.53 19.67 13.5 19.34 13.5 19C13.5 15.69 16.19 13 19.5 13C20.56 13 21.59 13.28 22.5 13.81V6C22.5 4.89 21.61 4 20.5 4ZM20.5 11H4.5V8H20.5M18.25 22L15.5 19L16.66 17.84L18.25 19.43L21.84 15.84L23 17.25L18.25 22Z'
      fill={color}
    />
  </SvgIcon>
)

const PaymentDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M20.5 4H4.5C3.96957 4 3.46086 4.21071 3.08579 4.58579C2.71071 4.96086 2.5 5.46957 2.5 6V18C2.5 18.5304 2.71071 19.0391 3.08579 19.4142C3.46086 19.7893 3.96957 20 4.5 20H13.59C13.53 19.67 13.5 19.34 13.5 19C13.5 15.69 16.19 13 19.5 13C20.56 13 21.59 13.28 22.5 13.81V6C22.5 4.89 21.61 4 20.5 4ZM20.5 11H4.5V8H20.5M18.25 22L15.5 19L16.66 17.84L18.25 19.43L21.84 15.84L23 17.25L18.25 22Z'
      fill={color}
    />
  </SvgIcon>
)

const PaymentIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <PaymentDarkIcon {...props} /> : <PaymentLightIcon {...props} />
}

export default PaymentIcon
