import { listTopEventCategories } from '@graphql/eventCategory.graphql'

export const getTopEventCategoriesListService = async (apolloClient, { options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: listTopEventCategories,
      ...options,
    })

    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
