import { styled } from '@mui/material/styles'
import { Divider } from '@mui/material'

const StyledDivider = styled(props => <Divider {...props}/>)(({ theme }) => ({
  background: theme.palette.gray[10],
}))

const CustomDivider = ({ children, ...props }) => {

  return <StyledDivider {...props}>
    { children }
  </StyledDivider>
}

export default CustomDivider
