import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledBlueButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}))

const BlueButton = ({ children, ...props }) => {

  return <StyledBlueButton {...props}>
    { children }
  </StyledBlueButton>
}

export default BlueButton
