import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const StadiboxProtectionLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='36' height='46' viewBox='0 0 36 46' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
       d='M18 0.0830078L0.8125 8.41634V20.9163C0.8125 32.4788 8.14583 43.2914 18 45.9164C27.8542 43.2914 35.1875 32.4788 35.1875 20.9163V8.41634L18 0.0830078ZM26.8222 20.6833C26.1998 17.4952 21.447 15.8377 16.2058 16.982C16.1984 16.9835 16.1912 16.9852 16.1841 16.9869L16.1667 16.9908L16.6013 19.1567C20.6651 18.5656 24.1026 19.6163 24.5775 22.0493C24.9961 24.1928 23.1232 26.5302 20.0645 28.0894C24.4417 26.6461 27.3792 23.5352 26.8222 20.6833ZM19.796 27.7272C14.5548 28.8715 9.802 27.214 9.17961 24.0259C8.62325 21.1733 11.5608 18.0631 15.9374 16.6198C12.8787 18.179 11.0057 20.5164 11.4243 22.6599C11.8993 25.0929 15.3368 26.1436 19.4005 25.5525L19.8351 27.7184C19.8223 27.7211 19.8095 27.7245 19.796 27.7272Z'
      fill-rule='evenodd'
      clip-rule='evenodd'
      fill={color}
    />
  </SvgIcon>
)

const StadiboxProtectionDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='36' height='46' viewBox='0 0 36 46' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
       d='M18 0.0830078L0.8125 8.41634V20.9163C0.8125 32.4788 8.14583 43.2914 18 45.9164C27.8542 43.2914 35.1875 32.4788 35.1875 20.9163V8.41634L18 0.0830078ZM26.8222 20.6833C26.1998 17.4952 21.447 15.8377 16.2058 16.982C16.1984 16.9835 16.1912 16.9852 16.1841 16.9869L16.1667 16.9908L16.6013 19.1567C20.6651 18.5656 24.1026 19.6163 24.5775 22.0493C24.9961 24.1928 23.1232 26.5302 20.0645 28.0894C24.4417 26.6461 27.3792 23.5352 26.8222 20.6833ZM19.796 27.7272C14.5548 28.8715 9.802 27.214 9.17961 24.0259C8.62325 21.1733 11.5608 18.0631 15.9374 16.6198C12.8787 18.179 11.0057 20.5164 11.4243 22.6599C11.8993 25.0929 15.3368 26.1436 19.4005 25.5525L19.8351 27.7184C19.8223 27.7211 19.8095 27.7245 19.796 27.7272Z'
      fill-rule='evenodd'
      clip-rule='evenodd'
      fill={color}
    />
  </SvgIcon>
)

const StadiboxProtectionIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <StadiboxProtectionDarkIcon {...props} /> : <StadiboxProtectionLightIcon {...props} />
}

export default StadiboxProtectionIcon
