import  { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { IconButton } from '@mui/material'

import { InformationIcon } from '@features/App/Components/Icons'
import { useTheme } from '@features/App/hooks'

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: theme.spacing(2),
  height: theme.spacing(2),
  padding: theme.spacing(0),
  '.MuiSvgIcon-root': {
    width: theme.spacing(2),
  },
}))

const TooltipClickable = props => {

  const { theme } = useTheme()
  const [open, setOpen] = useState(false)

  const handleTooltipToggle = () => {
    setOpen(!open)
  }
  
  useEffect(() => {
    const handleClickOutside = event => {
      if (open && !event.target.closest('.MuiTooltip-tooltip')) {
        setOpen(false)
      }
    }

    const handleScroll = () => {
      if (open) {
        setOpen(false)
      }
    }

    document.body.addEventListener('click', handleClickOutside)
    window.addEventListener('scroll', handleScroll)

    return () => {
      document.body.removeEventListener('click', handleClickOutside)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [open])

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'rgb(255, 255, 255)',
            borderRadius: '12px',
            boxShadow: '0 6px 30px rgb(175, 175, 175, .89)',
            padding: '10px',
            font: theme.font.rubik.paragraph.small,
            color: theme.palette.gray[700],
            marginTop: theme.spacing(0),
            '& p': {
              margin: theme.spacing(0),
            },
            '& .MuiTooltip-popper': {
              margin: theme.spacing(0),
            },
          },
        },
      }}
      onClose={() => setOpen(false)}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={<p>{props.bodyText}</p>}
    >
      <CustomIconButton onClick={handleTooltipToggle}>
        <InformationIcon color='#626771' />
      </CustomIconButton>
    </Tooltip>
  )
}

export default TooltipClickable
