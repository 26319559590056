import { user } from '@graphql/user.graphql'

export const getUserDetailService = async (apolloClient, { options }) => {
  try {
    const resp = await apolloClient.query({
      query: user,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
