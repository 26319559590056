
export const AppReducer = (state, action) => {
  switch (action.type) {
  case 'SET_AGENT':
    return {
      ...state,
      agent: action.payload,
      session: action.payload.session,
    }
  case 'SET_SESSION':
    return {
      ...state,
      session: action.payload,
    }
  case 'SET_CURRENCY':
    return {
      ...state,
      currency: action.payload,
    }
  default:
    return state
  }
}
