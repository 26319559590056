import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledRedButton = styled(props => <CustomButton {...props} />)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.semantic.danger,
  '&:hover': {
    backgroundColor: '#fc5b3a8f',
  },
}))

const RedButton = ({ children, ...props }) => {
  return <StyledRedButton {...props}>{children}</StyledRedButton>
}

export default RedButton
