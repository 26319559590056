import React, { useCallback, useEffect, useState, useRef } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import { DayPicker } from 'react-day-picker'
import WheelPicker from 'react-simple-wheel-picker'
import 'react-day-picker/dist/style.css'
import dayjs from 'dayjs'

import ChevronLeftIcon from '@icons/ChevronLeftIcon'
import ChevronRightIcon from '@icons/ChevronRightIcon'
import FilledButton from './FilledButton'

// eslint-disable-next-line max-len, react/display-name
const StyledDayPicker = styled(React.forwardRef((props, ref) => <DayPicker {...props} ref={ref} />))(({ theme, dayPickerWidth }) => ({
  '--rdp-accent-color': theme.palette.primary[40],
  '--rdp-accent-color-dark': theme.palette.primary[40],
  '--rdp-background-color': theme.palette.primary[15],
  '--rdp-background-color-dark': theme.palette.primary[15],
  '--rdp-cell-size': `${dayPickerWidth / 7}px`,
  margin: '0',
  '& .rdp-caption': {
    display: 'none',
  },
  '& .rdp-head_row, .rdp-row': {
    height: 'var(--rdp-cell-size)',
    maxHeight: 'var(--rdp-cell-size)',
    '&>td, >th': {
      height: 'var(--rdp-cell-size)',
      maxHeight: 'var(--rdp-cell-size)',
      '&:nth-of-type(6), :nth-of-type(7)': {
        color: theme.palette.primary.light,
      },
    },
  },
  '& .rdp-head_row': {
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.gray[400]}`,
  },
  '& .rdp-head': {
    color: theme.palette.gray.main,
  },
  '& .rdp-day_today': {
    // backgroundColor: theme.palette.primary.main,
  },
  '& .rdp-button': {
    color: theme.palette.gray.main,
  },
  '& .rdp-months': {
    width: '100%',
  },
}))

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  width: '100%',
}))

const MonthsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1.5),
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const ChevronsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const ChevronContainer = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
}))

const MonthMainLabel = styled(props => <FilledButton {...props}/>)(({ theme }) => ({
  textTransform: 'capitalize',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  '&.active': {
    color: theme.palette.primary.light,
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
}))

const YearsContainer = styled(Box)(({ theme }) => ({
  width: '280px',
  maxWidth: '280px',
  '& li': {
    '& span': {
      display: 'none',
    },
    '& p': {
      padding: '0',
      textAlign: 'center',
      width: '100%',
      transition: 'font-size 0.1s linear',
    },
    '&[aria-selected="true"] p': {
      color: `${theme.palette.primary.light} !important`,
    },
    '&[aria-selected="false"] p': {
      fontSize: '12px !important',
    },
  },
  '& *::-webkit-scrollbar': {
    width: '0px',
  },
  '& *::-webkit-scrollbar-track': {
    width: '0px',
  },
  '& *::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(180,180,180,0)',
  },
}))

const LineDecoration = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.primary.light}`,
  position: 'relative',
}))

const CustomCalendarPicker = ({
  selected, setSelected, disablePreviousDates=true,
  disableUpcomingDates=false, ...props
}) => {

  const theme = useTheme()

  const [monthView, setMontView] = useState(dayjs())
  const [view, setView] = useState('calendar')
  const [year, setYear] = useState(dayjs().$y)
  const [dayPickerWidth, setDayPickerWidth] = useState()

  const dayPickerRef = useRef(null)

  const data = Array.from({ length: 101 }, (_, i) => ({ id: (i + 1950).toString(), value: i + 1950 }))

  const handleOnChange = useCallback( target => {
    setYear(target.id)
    setMontView(monthView.year(target.id))
  }, [year, monthView])

  const handleMonth = useCallback(i => {
    setMontView(monthView.month(i))
    setView('calendar')
  }, [view, monthView])

  const handleView = useCallback(e => {
    if (view === 'calendar')
      setView('months')
    else if (view === 'months')
      setView('years')
    else
      setView('calendar')
  }, [view])

  useEffect(() => {
    const measureCellSize = () => {
      if (dayPickerRef.current) {
        const width = dayPickerRef.current.clientWidth
        setDayPickerWidth(width)
      }
    }
    measureCellSize()
    window.addEventListener('resize', measureCellSize)
    return () => {
      window.removeEventListener('resize', measureCellSize)
    }
  }, [dayPickerRef.current])

  return <Root>
    <Header>
      <MonthMainLabel onClick={handleView}>
        { monthView.format(`${view !== 'months' ? 'MMMM ' : ''}YYYY`) }
      </MonthMainLabel>
      <ChevronsContainer>
        <ChevronContainer onClick={e => setMontView(monthView.subtract(1, 'M'))}>
          <ChevronLeftIcon/>
        </ChevronContainer>
        <ChevronContainer onClick={e => setMontView(monthView.add(1, 'M'))}>
          <ChevronRightIcon/>
        </ChevronContainer>
      </ChevronsContainer>
    </Header>
    {
      view === 'months' && <MonthsContainer>
        {
          Array.from({ length: 12 }, (_, i) => i).map(i =>
            <MonthMainLabel
              key={`month-${i}`}
              onClick={e => handleMonth(i)}
              className={monthView.month() === i ? 'active' : ''}
            >
              { dayjs().month(i).format('MMMM') }
            </MonthMainLabel>)
        }
      </MonthsContainer>
    }
    {
      view === 'years' && <YearsContainer>
        <WheelPicker
          data={data}
          onChange={handleOnChange}
          height={150}
          width={280}
          itemHeight={30}
          selectedID={year.toString()}
          color={ theme.palette.gray[500] }
          activeColor={ theme.palette.gray[600] }
          backgroundColor={ theme.palette.gray[100] }
          shadowColor={ theme.palette.gray[100] }
        />
        <LineDecoration style={{ top: '-98px' }}/>
        <LineDecoration style={{ top: '-70px' }}/>
      </YearsContainer>
    }
    {
      view === 'calendar' && <Box ref={dayPickerRef}>
        <StyledDayPicker dayPickerWidth={dayPickerWidth}
        // locale={'es'}
          month={monthView.$d}
          selected={selected}
          onSelect={setSelected}
          showOutsideDays={true}
          mode='range'
          disabled={{
            before: disablePreviousDates ? new Date() : null,
            after: disableUpcomingDates ? new Date() : null,
          }}
        />
      </Box>
    }
  </Root>
}

export default CustomCalendarPicker
