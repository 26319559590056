import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ShoppingBagLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6.125 21.0003C5.345 21.0003 4.665 20.5503 4.335 19.9003L1.725 10.4403L1.625 10.0003C1.625 9.73506 1.73036 9.4807 1.91789 9.29317C2.10543 9.10563 2.35978 9.00027 2.625 9.00027H7.205L11.805 2.43027C11.8972 2.29615 12.0208 2.18663 12.1651 2.11129C12.3094 2.03595 12.4699 1.99708 12.6326 1.99807C12.7954 1.99905 12.9554 2.03987 13.0988 2.11695C13.2421 2.19403 13.3644 2.30504 13.455 2.44027L18.045 9.00027H22.625C22.8902 9.00027 23.1446 9.10563 23.3321 9.29317C23.5196 9.4807 23.625 9.73506 23.625 10.0003L23.585 10.2903L20.915 19.9003C20.585 20.5503 19.905 21.0003 19.125 21.0003H6.125ZM12.625 4.74027L9.625 9.00027H15.625L12.625 4.74027ZM12.625 13.0003C12.0946 13.0003 11.5859 13.211 11.2108 13.5861C10.8357 13.9611 10.625 14.4698 10.625 15.0003C10.625 15.5307 10.8357 16.0394 11.2108 16.4145C11.5859 16.7896 12.0946 17.0003 12.625 17.0003C13.1554 17.0003 13.6641 16.7896 14.0392 16.4145C14.4143 16.0394 14.625 15.5307 14.625 15.0003C14.625 14.4698 14.4143 13.9611 14.0392 13.5861C13.6641 13.211 13.1554 13.0003 12.625 13.0003Z'
      fill={color}
    />
  </SvgIcon>
)

const ShoppingBagDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6.125 21.0003C5.345 21.0003 4.665 20.5503 4.335 19.9003L1.725 10.4403L1.625 10.0003C1.625 9.73506 1.73036 9.4807 1.91789 9.29317C2.10543 9.10563 2.35978 9.00027 2.625 9.00027H7.205L11.805 2.43027C11.8972 2.29615 12.0208 2.18663 12.1651 2.11129C12.3094 2.03595 12.4699 1.99708 12.6326 1.99807C12.7954 1.99905 12.9554 2.03987 13.0988 2.11695C13.2421 2.19403 13.3644 2.30504 13.455 2.44027L18.045 9.00027H22.625C22.8902 9.00027 23.1446 9.10563 23.3321 9.29317C23.5196 9.4807 23.625 9.73506 23.625 10.0003L23.585 10.2903L20.915 19.9003C20.585 20.5503 19.905 21.0003 19.125 21.0003H6.125ZM12.625 4.74027L9.625 9.00027H15.625L12.625 4.74027ZM12.625 13.0003C12.0946 13.0003 11.5859 13.211 11.2108 13.5861C10.8357 13.9611 10.625 14.4698 10.625 15.0003C10.625 15.5307 10.8357 16.0394 11.2108 16.4145C11.5859 16.7896 12.0946 17.0003 12.625 17.0003C13.1554 17.0003 13.6641 16.7896 14.0392 16.4145C14.4143 16.0394 14.625 15.5307 14.625 15.0003C14.625 14.4698 14.4143 13.9611 14.0392 13.5861C13.6641 13.211 13.1554 13.0003 12.625 13.0003Z'
      fill={color}
    />
  </SvgIcon>
)

const ShoppingBagIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ShoppingBagDarkIcon {...props} /> : <ShoppingBagLightIcon {...props} />
}

export default ShoppingBagIcon
