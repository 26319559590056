import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
// import { changeTawkToStyle } from '@utils/customizeStyle'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'
import { useContext, useRef } from 'react'
import { Badge, Box, SvgIcon } from '@mui/material'
import { useState } from 'react'
import styled from '@emotion/styled'

const TalktoCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(4),
  aspectRatio: '1',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  '& svg path': {
    stroke: theme.palette.gray[700],
  },
}))

const MessageLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='25' height='25' viewBox='0 0 24 20' fill='none' {...props}>
    <path
      d='M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const MessageDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='25' height='25' viewBox='0 0 24 20' fill='none' {...props}>
    <path
      d='M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const TawkToMessenger = props => {
  const { darkMode } = useContext(Context)
  const tawkMessengerRef = useRef()
  const [unreadMessages, setUnreadMessages] = useState(0)

  const onLoad = () => {
    tawkMessengerRef.current?.hideWidget()
    setUnreadMessages(1)
  }
  //s1.src='https://embed.tawk.to//';

  return (
    <>
      <div onClick={() => tawkMessengerRef.current.toggle()}>
        <Badge color='error' badgeContent={unreadMessages} max={9}>
          <TalktoCircle>
            {/* Other content */}
            {darkMode ? <MessageDarkIcon {...props} /> : <MessageLightIcon {...props} />}
          </TalktoCircle>
        </Badge>
      </div>
      <TawkMessengerReact
        propertyId='5990dc5f1b1bed47ceb04669'
        widgetId='default'
        onLoad={onLoad}
        onUnreadCountChanged={count => setUnreadMessages(Number(count))}
        ref={tawkMessengerRef}
      />
    </>
  )
}

export default TawkToMessenger
