import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const PropertyLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0.375 21V10L7.875 5L15.375 10V21H10.375V14H5.375V21H0.375ZM24.375 2V21H17.375V8.93L16.375 8.27V6H14.375V6.93L10.375 4.27V2H24.375ZM21.375 14H19.375V16H21.375V14ZM21.375 10H19.375V12H21.375V10ZM21.375 6H19.375V8H21.375V6Z'
      fill={color}
    />
  </SvgIcon>
)

const PropertyDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0.375 21V10L7.875 5L15.375 10V21H10.375V14H5.375V21H0.375ZM24.375 2V21H17.375V8.93L16.375 8.27V6H14.375V6.93L10.375 4.27V2H24.375ZM21.375 14H19.375V16H21.375V14ZM21.375 10H19.375V12H21.375V10ZM21.375 6H19.375V8H21.375V6Z'
      fill={color}
    />
  </SvgIcon>
)

const PropertyIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <PropertyDarkIcon {...props} /> : <PropertyLightIcon {...props} />
}

export default PropertyIcon
