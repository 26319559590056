import { useContext, useState, useEffect } from 'react'
import Context from '@context/Locale'

const interpolate = (text, variables) => {
  const keys = Object.keys(variables)
  if (keys.length === 0) return text
  return keys.reduce((prev, textId) => prev.replace(new RegExp(`{{${textId}}}`, 'g'), variables[textId]), text)
}

const I18nText = ({ textId, variables = {} }) => {
  const localeContext = useContext(Context)
  const [text, setText] = useState(
    localeContext.glossary[textId] ? interpolate(localeContext.glossary[textId], variables) : ''
  )

  useEffect(() => {
    setText(localeContext.glossary[textId] ? interpolate(localeContext.glossary[textId], variables) : '')
  }, [localeContext.glossary, textId, variables, setText])

  return text
}

export { I18nText, interpolate }

export default I18nText
