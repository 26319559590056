import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const SupportLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M18.72 14.7607C19.07 13.9107 19.26 13.0007 19.26 12.0007C19.26 11.2807 19.15 10.5907 18.96 9.95068C18.31 10.1007 17.63 10.1807 16.92 10.1807C15.466 10.1822 14.0329 9.8342 12.7415 9.1659C11.4502 8.4976 10.3384 7.52863 9.5 6.34068C8.6031 8.51085 6.91112 10.2565 4.77 11.2207C4.73 11.4707 4.73 11.7407 4.73 12.0007C4.73 12.9554 4.91804 13.9008 5.2834 14.7828C5.64875 15.6648 6.18425 16.4663 6.85933 17.1413C8.22272 18.5047 10.0719 19.2707 12 19.2707C13.05 19.2707 14.06 19.0407 14.97 18.6307C15.54 19.7207 15.8 20.2607 15.78 20.2607C14.14 20.8107 12.87 21.0807 12 21.0807C9.58 21.0807 7.27 20.1307 5.57 18.4207C4.536 17.3899 3.76737 16.1237 3.33 14.7307H2V10.1807H3.09C3.42024 8.57319 4.17949 7.08509 5.28719 5.87427C6.39489 4.66345 7.80971 3.77509 9.38153 3.30344C10.9534 2.83179 12.6235 2.79445 14.2149 3.19539C15.8062 3.59632 17.2593 4.42057 18.42 5.58068C19.6802 6.83593 20.5398 8.43678 20.89 10.1807H22V14.7307H21.94L18.38 18.0007L13.08 17.4007V15.7307H17.91L18.72 14.7607ZM9.27 11.7707C9.57 11.7707 9.86 11.8907 10.07 12.1107C10.281 12.3234 10.3995 12.611 10.3995 12.9107C10.3995 13.2104 10.281 13.4979 10.07 13.7107C9.86 13.9207 9.57 14.0407 9.27 14.0407C8.64 14.0407 8.13 13.5407 8.13 12.9107C8.13 12.2807 8.64 11.7707 9.27 11.7707ZM14.72 11.7707C15.35 11.7707 15.85 12.2807 15.85 12.9107C15.85 13.5407 15.35 14.0407 14.72 14.0407C14.09 14.0407 13.58 13.5407 13.58 12.9107C13.58 12.6083 13.7001 12.3184 13.9139 12.1046C14.1277 11.8908 14.4177 11.7707 14.72 11.7707Z'
      fill={color}
    />
  </SvgIcon>
)

const SupportDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M18.72 14.7607C19.07 13.9107 19.26 13.0007 19.26 12.0007C19.26 11.2807 19.15 10.5907 18.96 9.95068C18.31 10.1007 17.63 10.1807 16.92 10.1807C15.466 10.1822 14.0329 9.8342 12.7415 9.1659C11.4502 8.4976 10.3384 7.52863 9.5 6.34068C8.6031 8.51085 6.91112 10.2565 4.77 11.2207C4.73 11.4707 4.73 11.7407 4.73 12.0007C4.73 12.9554 4.91804 13.9008 5.2834 14.7828C5.64875 15.6648 6.18425 16.4663 6.85933 17.1413C8.22272 18.5047 10.0719 19.2707 12 19.2707C13.05 19.2707 14.06 19.0407 14.97 18.6307C15.54 19.7207 15.8 20.2607 15.78 20.2607C14.14 20.8107 12.87 21.0807 12 21.0807C9.58 21.0807 7.27 20.1307 5.57 18.4207C4.536 17.3899 3.76737 16.1237 3.33 14.7307H2V10.1807H3.09C3.42024 8.57319 4.17949 7.08509 5.28719 5.87427C6.39489 4.66345 7.80971 3.77509 9.38153 3.30344C10.9534 2.83179 12.6235 2.79445 14.2149 3.19539C15.8062 3.59632 17.2593 4.42057 18.42 5.58068C19.6802 6.83593 20.5398 8.43678 20.89 10.1807H22V14.7307H21.94L18.38 18.0007L13.08 17.4007V15.7307H17.91L18.72 14.7607ZM9.27 11.7707C9.57 11.7707 9.86 11.8907 10.07 12.1107C10.281 12.3234 10.3995 12.611 10.3995 12.9107C10.3995 13.2104 10.281 13.4979 10.07 13.7107C9.86 13.9207 9.57 14.0407 9.27 14.0407C8.64 14.0407 8.13 13.5407 8.13 12.9107C8.13 12.2807 8.64 11.7707 9.27 11.7707ZM14.72 11.7707C15.35 11.7707 15.85 12.2807 15.85 12.9107C15.85 13.5407 15.35 14.0407 14.72 14.0407C14.09 14.0407 13.58 13.5407 13.58 12.9107C13.58 12.6083 13.7001 12.3184 13.9139 12.1046C14.1277 11.8908 14.4177 11.7707 14.72 11.7707Z'
      fill={color}
    />
  </SvgIcon>
)

const SupportIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <SupportDarkIcon {...props} /> : <SupportLightIcon {...props} />
}

export default SupportIcon
