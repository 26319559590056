/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getBoxesListService } from '@services/box/getBoxesList'
import { createBoxService } from '@services/box/createBox'
import { addBoxOwnerService } from '@services/box/addBoxOwner'
import { addBoxAmenityService } from '@services/box/addBoxAmenity'
import { addBoxFileService } from '@services/box/addBoxFile'
import { addBoxDocumentService } from '@services/box/addBoxDocument'
import { updateBoxFileService } from '@services/box/updateBoxFile'
import { updateBoxService } from '@services/box/updateBox'
import { removeBoxOwnerService } from '@services/box/removeBoxOwner'
import { removeBoxAmenityService } from '@services/box/removeBoxAmenity'
import { removeBoxFileService } from '@services/box/removeBoxFile'
import { getBoxService } from '@services/box/getBox'

export default function useBox() {

  const apolloClient = useApolloClient()

  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getBoxes = useCallback(
    (filters, options = {}) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          setLoadingSource('list')
          const variables = filters || {}
          const response = await getBoxesListService(apolloClient, { variables, options })
          resolve(response.boxes)
          setLoading(false)
          setLoadingSource(null)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const getBox = useCallback(
    id => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const response = await getBoxService(apolloClient, {
            id,
            options: { fetchPolicy: 'network-only' },
          })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const createBox = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await createBoxService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const addBoxOwner = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await addBoxOwnerService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const addBoxAmenity = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await addBoxAmenityService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const addBoxFile = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await addBoxFileService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const addBoxDocument = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await addBoxDocumentService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const updateBoxFile  = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await updateBoxFileService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const updateBox = useCallback(
    ({ input }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const variables = { ...input }
          const response = await updateBoxService(apolloClient, { variables })
          resolve(response)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const updateBoxOwners = useCallback(
    ({ boxId, oldOwnerIds, newOwners }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          // First we delete all current co-owners
          await Promise.all(
            oldOwnerIds.map( id =>
              removeBoxOwnerService(apolloClient, {
                variables: { id, boxId },
              })
            )
          )
          // Then we add all the new owners
          await Promise.all(
            newOwners.map( owner =>
              addBoxOwnerService(apolloClient, {
                variables: {
                  fullName: owner.name,
                  email: owner.email,
                  box: boxId,
                },
              })
            )
          )
          resolve(true)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  const updateBoxAmenities = useCallback(
    ({ boxId, oldAmenitiesIds, currentAmenitiesIds }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const removedAmenities = oldAmenitiesIds.filter(
              amenity => !currentAmenitiesIds.includes(amenity)
            ),
            addedAmenities = currentAmenitiesIds.filter(
              amenity => !oldAmenitiesIds.includes(amenity)
            )
          if (addedAmenities.length > 0) await Promise.all(
            addedAmenities.map(amenity =>
              addBoxAmenityService(apolloClient, {
                variables: { box: boxId, amenity: amenity },
              })
            )
          )
          if (removedAmenities.length > 0) await Promise.all(
            removedAmenities.map(amenity =>
              removeBoxAmenityService(apolloClient, {
                variables: { box: boxId, amenity: amenity },
              })
            )
          )
          resolve(true)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  /**
   * oldPictures and currentPictures are BoxFile objects. That is, they must at least
   * have the following fields: file, cover, id
   */
  const updateBoxPictures = useCallback(
    ({ boxId, oldPictures, currentPictures, currentCoverFileId }) => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const oldPicturesFiles = oldPictures.map(p => p.file)
          const currentPicturesFiles = currentPictures.map(p => p.file)
          const removedPictures = oldPictures.filter(p =>
              !currentPictures.map(p => p.id).includes(p.id)
            ),
            addedPictures = currentPictures.filter(p =>
              !oldPictures.map(p => p.id).includes(p.id)
            )
          // Add the new files. If any of them is selected as cover it will be saved as such.
          if (addedPictures.length > 0) await Promise.all(
            addedPictures.map(pic =>
              addBoxFileService(apolloClient, {
                variables: {
                  box: boxId,
                  file: pic.file.id,
                  cover: pic.file.id === currentCoverFileId,
                },
              })
            )
          )
          // Delete the removed pictures.
          if (removedPictures.length > 0) await Promise.all(
            removedPictures.map(pic =>
              removeBoxFileService(apolloClient, {
                variables: { box: boxId, file: pic.file.id, cover: false },
              })
            )
          )
          // Check if there are any old cover pictures, and remove them if they don't have
          // currentCoverFileId as file id
          const persistingPictures = currentPictures.filter(f =>
            oldPictures.map(f => f.id).includes(f.id)
          )
          const outdatedCoverPictures = persistingPictures.filter(f =>
            f.cover && f.file.id !== currentCoverFileId
          )
          if (outdatedCoverPictures.length > 0) await Promise.all(
            outdatedCoverPictures.map(pic =>
              updateBoxFileService(apolloClient, {
                variables: { box: boxId, file: pic.file.id, cover: false },
              })
            )
          )
          // If there is any persisting picture with file id currentCoverFileId,
          // find it and set it as cover
          const coverPicture = persistingPictures.find(f => f.file.id === currentCoverFileId)
          if (coverPicture && coverPicture!==-1) {
            await updateBoxFileService(apolloClient, {
              variables: { box: boxId, file: coverPicture.file.id, cover: true },
            })
          }
          resolve(true)
          setLoading(false)
        } catch (error) {
          reject(error)
        }
      })
    },
    [apolloClient]
  )

  return {
    loading,
    loadingSource,
    getBoxes,
    getBox,
    createBox,
    addBoxOwner,
    addBoxAmenity,
    addBoxFile,
    addBoxDocument,
    updateBoxFile,
    updateBox,
    updateBoxOwners,
    updateBoxAmenities,
    updateBoxPictures,
  }
}
