import { getCity } from '@graphql/city.graphql'

export const getCityService = async (apolloClient, { id, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: getCity,
      variables: { id },
      ...options,
    })

    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
