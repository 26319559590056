import {useContext, useEffect, useState} from 'react'
import { Box, Popover, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import useGlossary from '@hooks/useGlossary'
import useTheme from '@hooks/useTheme'
import { locationToString } from '@helpers/location'
import GreenButton from '@components/GreenButton'
import FilterChipDropdown from '@components/FilterChipDropdown'
import WhiteOutlinedButton from '@components/WhiteOutlinedButton'
import { Context as LocaleContext } from '@features/Locale'
import LocationAutocomplete from './LocationAutocomplete'
import CurrentLocationButton from './CurrentLocationButton'
import { useContextLocation } from '@context/Location'

const StyledPopover = styled(props => <Popover {...props}/>)(({ theme }) => ({
  '& .MuiPopover-paper':{
    minWidth: '300px',
    padding: '15px 10px',
    display: 'flex',
    flexDirection:'column',
    gap:'15px',
    overflow:'hidden',
  },
}))

const StyledGreenButton = styled(props => <GreenButton {...props}/>)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  gap: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]:{
    height: '28px',
    ...theme.textEllipsis[1],
    width: '100%',
    minWidth: '30px',
    maxWidth: '120px',
  },
}))

const StyledWhiteOutlinedButton = styled(props => <WhiteOutlinedButton {...props}/>)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  gap: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  //maxWidth: '120px',
  //maxHeight: '28px',
  ...theme.textEllipsis[1],
  /*[theme.breakpoints.down('md')]:{
    height: '28px',
    ...theme.textEllipsis[1],
    width: '100%',
    minWidth: '30px',
    maxWidth: '120px',
  },*/
}))

const Name = styled(props => <Box {...props}/>)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    font: theme.font.roboto.paragraph.medium,
    fontWeight: '500',
    ...theme.textEllipsis[1],
  },


}))

/**
 * This component only works inside a location context, though it doesn't necessarily change the context's location.
 * @param {Boolean} readOnly - When false, and in the filter variant, the component will not show the down arrow that
 *  signifies that the component may display options.
 * @param {String} variant - Should be either 'filter' or 'standout'.
 *  Standout will make the button green, filter will make it look like a filter button
 * @param {Boolean} useLocationContext - When true, each new location picked will change the context's location.
 *  And, when the context location changes, the selected location will too.
 * @param {Object} initialLocation - Must be a location object (object with a __typename region, city or country)
 * @param {function} customSelectionHandler - (Optional) If provided, will be executed after a new location has
 *  been picked. The function receives the newly selected location as an argument.
 * @returns {Element} - React component.
 * @constructor
 */
const LocationPicker = ({ readOnly= false, variant= 'standout',
  useLocationContext = false, initialLocation, customSelectionHandler }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery((theme.breakpoints.up('md')))
  const { location, setLocation } = useContextLocation()
  const { currentLocale } = useContext(LocaleContext)

  const [selectedLocation, setSelectedLocation] = useState(useLocationContext ? location : initialLocation)
  const [anchor, setAnchor] = useState(null)

  const open = Boolean(anchor)
  const id = open ? 'location-popover' : undefined

  const handleOpen = event => {
    if(!readOnly) setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const onSelectLocation = newLocation => {
    setSelectedLocation(newLocation)
    if(useLocationContext) setLocation(newLocation)
    if(customSelectionHandler) customSelectionHandler(newLocation)
    handleClose()
  }

  useEffect(() => {
    if(useLocationContext) setSelectedLocation(location)
  }, [location])

  useEffect(() => {
    if(!useLocationContext) setSelectedLocation(initialLocation)
  }, [initialLocation])

  const DesktopPopover = <StyledPopover
    id={id} open={open} anchorEl={anchor} onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    elevation={1}
  >
    <Box sx={{ display:'flex', justifyContent:'space-between' }}>
      <Typography sx={{ fontSize:'larger' }}>{glossary('WhatsYourLocation')}</Typography>
      <HighlightOffIcon onClick={handleClose}/>
    </Box>
    <CurrentLocationButton selectedLocation={selectedLocation}
      onSelectLocation={onSelectLocation}/>
    <LocationAutocomplete selectedLocation={selectedLocation}
      onSelectLocation={onSelectLocation} />
  </StyledPopover>

  return (
    <Box sx={{ height:'100%' }}>
      {
        variant === 'standout' &&
        <StyledGreenButton onClick={handleOpen}>
          <LocationOnIcon fontSize={'small'}/>
          <Name>
            {selectedLocation ?
              `${locationToString(selectedLocation, true, currentLocale)}`
              : (isMd ? glossary('SelectLocation') : glossary('Location')) }
          </Name>
        </StyledGreenButton>
      }
      {
        variant === 'filter' &&
        <FilterChipDropdown onClick={handleOpen}
          open={open}
          label = { selectedLocation ?
            locationToString(selectedLocation, true, currentLocale)
            : glossary('Location')
          }
          highlighted={ !!selectedLocation }
        />
      }
      {
        variant === 'outline' &&
        <StyledWhiteOutlinedButton variant='container' onClick={handleOpen}>
          <LocationOnIcon fontSize={'small'}/>
          <Name sx={{ marginLeft: theme.spacing(1)}}>
            { selectedLocation ? locationToString(selectedLocation, true, currentLocale) : glossary('Location') }
          </Name>
        </StyledWhiteOutlinedButton>
      }
      { DesktopPopover }
    </Box>
  ) 
}

export default LocationPicker
