import { createContext } from 'react'

const initialState = {
  session: null,
  agent: null,
  hasSocket: false,
  timeZone: null,
  currency: null,
}

const AppContext = createContext(initialState)

export default AppContext
