import { getCountryBankFields } from '@graphql/bankFields.graphql'

export const getBankFields = async (apolloClient, variables ) => {
  try {
    const resp = await apolloClient.query({
      query: getCountryBankFields,
      variables: variables,
    })
    return resp.data.countryBankFieldConfigs?.edges
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
