import { useTheme as getTheme } from '@mui/material/styles'

export default function useTheme () {

  const theme = getTheme()

  return {
    theme,
  }
}
