import { removeUserAddress } from '@graphql/user.graphql'

export const deleteUserAddressService = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: removeUserAddress,
      variables:{
        id: variables,
      },
      ...options,
    })
    return resp.data
  } catch (error) {
    throw new Error(error)
  }
}
