import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const RoundedSeatLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7.33329 28C8.43996 28 9.33329 27.1067 9.33329 26V24H22.6666V26C22.6666 27.1067 23.56 28 24.6666 28C25.7733 28 26.6666 27.1067 26.6666 26V22.6667C26.6666 21.2 25.4666 20 24 20H7.99996C6.53329 20 5.33329 21.2 5.33329 22.6667V26C5.33329 27.1067 6.22663 28 7.33329 28ZM26.6666 13.3333H28C28.7333 13.3333 29.3333 13.9333 29.3333 14.6667V16C29.3333 16.7333 28.7333 17.3333 28 17.3333H26.6666C25.9333 17.3333 25.3333 16.7333 25.3333 16V14.6667C25.3333 13.9333 25.9333 13.3333 26.6666 13.3333ZM3.99996 13.3333H5.33329C6.06663 13.3333 6.66663 13.9333 6.66663 14.6667V16C6.66663 16.7333 6.06663 17.3333 5.33329 17.3333H3.99996C3.26663 17.3333 2.66663 16.7333 2.66663 16V14.6667C2.66663 13.9333 3.26663 13.3333 3.99996 13.3333ZM22.6666 17.3333H9.33329V6.66667C9.33329 5.2 10.5333 4 12 4H20C21.4666 4 22.6666 5.2 22.6666 6.66667V17.3333Z' 
      fill={color}
    />
  </SvgIcon>
)

const RoundedSeatDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7.33329 28C8.43996 28 9.33329 27.1067 9.33329 26V24H22.6666V26C22.6666 27.1067 23.56 28 24.6666 28C25.7733 28 26.6666 27.1067 26.6666 26V22.6667C26.6666 21.2 25.4666 20 24 20H7.99996C6.53329 20 5.33329 21.2 5.33329 22.6667V26C5.33329 27.1067 6.22663 28 7.33329 28ZM26.6666 13.3333H28C28.7333 13.3333 29.3333 13.9333 29.3333 14.6667V16C29.3333 16.7333 28.7333 17.3333 28 17.3333H26.6666C25.9333 17.3333 25.3333 16.7333 25.3333 16V14.6667C25.3333 13.9333 25.9333 13.3333 26.6666 13.3333ZM3.99996 13.3333H5.33329C6.06663 13.3333 6.66663 13.9333 6.66663 14.6667V16C6.66663 16.7333 6.06663 17.3333 5.33329 17.3333H3.99996C3.26663 17.3333 2.66663 16.7333 2.66663 16V14.6667C2.66663 13.9333 3.26663 13.3333 3.99996 13.3333ZM22.6666 17.3333H9.33329V6.66667C9.33329 5.2 10.5333 4 12 4H20C21.4666 4 22.6666 5.2 22.6666 6.66667V17.3333Z' 
      fill={color}
    />
  </SvgIcon>
)

const RoundedSeatIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <RoundedSeatDarkIcon {...props} /> : <RoundedSeatLightIcon {...props} />
}

export default RoundedSeatIcon
