import { styled } from '@mui/material/styles'
import { Select, Popper } from '@mui/material'
import useTheme from '@hooks/useTheme'

const StyledSelect = styled(Select, { shouldForwardProp: prop => prop !== 'classes' })(
  ({ theme }) => ({
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiFormControl-root': {
      height: '100%',
      overflow: 'scroll',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& svg': {
      fill: theme.palette.gray[500],
      zIndex: '1',
    },
    '& fieldset': {
      border: '1px solid',
      borderColor: theme.palette.gray[300],
    },
    '& input': {
      color: theme.palette.font.black,
    },
    '& .MuiInputLabel-formControl': {
      color: theme.palette.gray[500],
      '&.MuiInputLabel-shrink': {
        color: theme.palette.gray[500],
      },
    },
    '& .MuiChip-root': {
      color: theme.palette.font.black,
      backgroundColor: theme.palette.components.white,
    },
    '& .MuiAutocomplete-tag': {
      color: theme.palette.font.black,
    },
    '& .MuiPaper-root ':{
      overflow: 'scroll !important', 
    },

  })
)

const StyledPopper = styled(props => <Popper {...props} />)(
  ({ theme }) => ({
    color: `${theme.palette.font.black} !important`,
    '& *':{
      color: `${theme.palette.font.black} !important`,
    },
  })
)

const CustomSelect = props => {
  const { theme } = useTheme()
  return <StyledSelect
    size={'small'}
    fullWidth
    MenuProps = {{
      PaperProps: {
        sx: {
          backgroundColor: theme.palette.components.chips.white,
          backdropFilter: 'blur(3px)',
          color: theme.palette.font.black,
          overflow: 'scroll !important',
          maxHeight: '200px',
          '& *::-webkit-scrollbar': {
            width: theme.spacing(1),
            backgroundColor: 'transparent',
          },
          '& *::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(180,180,180,0.2)',
          },
        },
      },
      PopperComponent: { StyledPopper } }}
    {...props}
  />
}

export default CustomSelect
