import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CalendarLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7 2.625C7 2.45924 6.93415 2.30027 6.81694 2.18306C6.69973 2.06585 6.54076 2 6.375 2C6.20924 2 6.05027 2.06585 5.93306 2.18306C5.81585 2.30027 5.75 2.45924 5.75 2.625V3.25H4.5C3.83696 3.25 3.20107 3.51339 2.73223 3.98223C2.26339 4.45107 2 5.08696 2 5.75L2 7H22V5.75C22 5.08696 21.7366 4.45107 21.2678 3.98223C20.7989 3.51339 20.163 3.25 19.5 3.25H18.25V2.625C18.25 2.45924 18.1842 2.30027 18.0669 2.18306C17.9497 2.06585 17.7908 2 17.625 2C17.4592 2 17.3003 2.06585 17.1831 2.18306C17.0658 2.30027 17 2.45924 17  2.625V3.25H7V2.625Z'
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M22 19.5V8.25H2V19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22H19.5C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5Z'
      fill={color}
    />
  </SvgIcon>
)

const CalendarDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M7 2.625C7 2.45924 6.93415 2.30027 6.81694 2.18306C6.69973 2.06585 6.54076 2 6.375 2C6.20924 2 6.05027 2.06585 5.93306 2.18306C5.81585 2.30027 5.75 2.45924 5.75 2.625V3.25H4.5C3.83696 3.25 3.20107 3.51339 2.73223 3.98223C2.26339 4.45107 2 5.08696 2 5.75L2 7H22V5.75C22 5.08696 21.7366 4.45107 21.2678 3.98223C20.7989 3.51339 20.163 3.25 19.5 3.25H18.25V2.625C18.25 2.45924 18.1842 2.30027 18.0669 2.18306C17.9497 2.06585 17.7908 2 17.625 2C17.4592 2 17.3003 2.06585 17.1831 2.18306C17.0658 2.30027 17 2.45924 17  2.625V3.25H7V2.625Z'
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M22 19.5V8.25H2V19.5C2 20.163 2.26339 20.7989 2.73223 21.2678C3.20107 21.7366 3.83696 22 4.5 22H19.5C20.163 22 20.7989 21.7366 21.2678 21.2678C21.7366 20.7989 22 20.163 22 19.5Z'
      fill={color}
    />
  </SvgIcon>
)

const CalendarIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CalendarDarkIcon {...props} /> : <CalendarLightIcon {...props} />
}

export default CalendarIcon
