import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef, useMemo } from 'react'
import cryptoRandomString from 'crypto-random-string'
import { useSnackbar } from 'notistack'

import UploadContext from '.'
import reducer from './reducers'
import { ADD_UPLOAD, UPDATE_UPLOAD } from './actions/types'
import { signUpload, verifyUpload } from '../../../graphql/upload.graphql'

export default function UploadContextProvider({ children }) {

  const [state, setState] = useState({
    state: true,
    filter: '',
    uploads: [],
    callback: null,
  })

  const [signUploadMutation] = useMutation(signUpload)
  const [verifyUploadMutation] = useMutation(verifyUpload)
  const { enqueueSnackbar } = useSnackbar()
  const stateRef = useRef()
  stateRef.current = state

  /**
   *
   * @param {{type: String, payload: Object}} action
   * @param {Function} callback
   */
  const dispatch = (action, callback = null) => {
    const newState = reducer(stateRef.current, action)
    setState(actual => ({
      ...actual,
      ...newState,
      callback,
    }))
  }

  const nextUpload = () => {
    // Search for upload
    const currentlyUploading = stateRef.current.uploads.reduce(
      (count, file) => (file.uploading ? count + 1 : count),
      0
    )
    if (currentlyUploading >= 2) return
    const nextFile = stateRef.current.uploads.find(
      file => !file.uploading && !file.done
    )
    if (!nextFile) return

    // Build HTTP Request
    const req = new XMLHttpRequest()
    const fields = JSON.parse(nextFile.policy.fields)
    const formData = new FormData()
    Object.keys(fields).forEach(key => formData.append(key, fields[key]))
    formData.append('file', nextFile.file)

    // Send Request
    req.open('POST', nextFile.policy.url)
    req.upload.onprogress = event => {
      const uploadProgress = event.lengthComputable
        ? (event.loaded / event.total) * 100
        : -1
      const upload = stateRef.current.uploads.find(
        item => item.id === nextFile.id
      )
      dispatch({
        type: UPDATE_UPLOAD,
        payload: { upload: { ...upload, uploadProgress } },
      })
    }
    req.onerror = () => {
      enqueueSnackbar('Error al cargar', { variant: 'error' })
      const upload = stateRef.current.uploads.find(
        item => item.id === nextFile.id
      )
      dispatch(
        {
          type: UPDATE_UPLOAD,
          payload: {
            upload: {
              ...upload,
              uploading: false,
              done: true,
              error: new Error('Error al cargar'),
            },
          },
        },
        () => {
          setTimeout(() => {
            nextUpload()
          }, 5000)
        }
      )
    }
    req.onload = () => {
      const upload = stateRef.current.uploads.find(
        item => item.id === nextFile.id
      )
      dispatch(
        {
          type: UPDATE_UPLOAD,
          payload: {
            upload: {
              ...upload,
              uploading: false,
              done: true,
              req: null,
              uploadProgress: 100,
            },
          },
        },
        async () => {
          try {
            await verifyUploadMutation({ variables: { id: nextFile.id } })
          } catch (error) {
            const toUpload = stateRef.current.uploads.find(
              item => item.id === nextFile.id
            )
            dispatch({ type: UPDATE_UPLOAD, payload: { ...toUpload, error } })
          }
          nextUpload()
        }
      )
    }
    req.send(formData)
    dispatch(
      {
        type: UPDATE_UPLOAD,
        payload: { upload: { ...nextFile, uploading: true, req } },
      },
      nextUpload
    )
  }

  const uploadFiles = async (files, path) => {
    const result = []
    await Promise.all(
      Array.from(files).map(async file => {
        try {
          const response = await signUploadMutation({
            variables: { name: file.name, path },
          })
          const data = response.data.signUpload
          const upload = {
            ...data.file,
            policy: data.policy,
            file,
            req: null,
            uploadProgress: 0,
            uploading: false,
            done: false,
          }
          result.push(upload)
          dispatch({ type: ADD_UPLOAD, payload: { upload } })
        } catch (error) {
          const upload = {
            id: cryptoRandomString({ type: 'url-safe', length: 10 }),
            name: file.name,
            error,
          }
          result.push(upload)
          dispatch({ type: ADD_UPLOAD, payload: { upload } })
        }
      })
    )
    setState(actual => ({
      ...actual,
      callback: nextUpload,
    }))
    return result
  }

  const memoValue = useMemo(
    () => ({
      ...state,
      uploadFiles,
    }),
    [state, uploadFiles]
  )

  useEffect(() => {
    if (state.callback) {
      state.callback()
      setState(actual => ({ ...actual, callback: null }))
    }
  }, [state])

  return (
    <UploadContext.Provider value={memoValue}>
      {children}
    </UploadContext.Provider>
  )
}

UploadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
