import { getBankFieldByCountry } from '@graphql/bankFields.graphql'

export const getBankFieldCountry = async (apolloClient, id ) => {
  try {
    const resp = await apolloClient.query({
      query: getBankFieldByCountry,
      variables: id,
    })
    
    
    return resp
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
