import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import dayjs from 'dayjs'

import useTheme from '@hooks/useTheme'
import useDate from '@hooks/useDate'
import useGlossary from '@hooks/useGlossary'
import CloseXIcon from '@icons/CloseXIcon'
import CustomCalendarPicker from '@components/CustomCalendarPicker'
import CustomPopover from '@components/CustomPopover'
import ResponsiveModal from '@components/ResponsiveModal'
import FilterChipButton from '@components/FilterChipButton'

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.components.filterBackground,
  alignItems: 'center',
}))

const CalendarPickerContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}))

/**
 * @param onRangeChange Function which receives an object with properties startDate and endDate
 * @param initialRange Objects with properties startDate and endDate
 * @param onlyPreviousDates If true, the user will only be able to select dates between the start
 *  of time and the present day.
*  @param nullable If true, the selected range can be set to null. Otherwise, it will always have a value
 * @param props
 * @returns {Element}
 * @constructor
 */
const DateRangePicker = ({ onRangeChange, initialRange, onlyPreviousDates=false, nullable=true, ...props }) => {

  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const upToSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { format } = useDate()

  const [dateRange, setDateRange] = useState({
    from: initialRange.from ? initialRange.from.$d : null,
    to: initialRange.to ? initialRange.to.$d : null,
  })
  const [anchor, setAnchor] = useState(null)

  const open = Boolean(anchor)

  const handleClose = () => {
    setAnchor(null)
  }
  const handleMoreClick = e => {
    e.stopPropagation()
    setAnchor(e.currentTarget)
  }

  const handleClear = e => {
    e.stopPropagation()
    setDateRange({
      from: null,
      to: null,
    })
    onRangeChange({ from: null, to: null })
  }

  const dateRangeChangeHandler = useCallback( newRange => {
    if(newRange) {
      const _newRange = {
        from: dayjs(newRange.from),
        to: newRange.to ? dayjs(newRange.to).endOf('day') : dayjs(newRange.from).endOf('day'),
      }
      setDateRange({ from: _newRange.from.$d, to: _newRange.to.$d })
      onRangeChange(_newRange)
    }
  }, [setDateRange])

  useEffect(() => {
    setDateRange({
      from: initialRange.from ? initialRange.from.$d : null,
      to: initialRange.to ? initialRange.to.$d : null,
    })
  }, [initialRange])

  return <Root>
    <FilterChipButton
      label={ (dateRange?.from && dateRange?.to ?
        `${format(dateRange.from, 'DD/MMM')} - ${format(dateRange.to, 'DD/MMM')}`
        : glossary('Date')) }
      icon={
        dateRange?.from && dateRange?.to && nullable ?
          <CloseXIcon fontSize={'10px'}/> :
          ( open ?
            <KeyboardArrowUpOutlinedIcon fontSize={'small'}/> :
            <KeyboardArrowDownOutlinedIcon fontSize={'small'}/>
          )
      }
      onClick={handleMoreClick}
      onIconClick={e => {
        if(dateRange?.from && dateRange?.to && nullable) handleClear(e)
      }}
      highlighted={dateRange?.from && dateRange?.to}/>
    {
      !upToSm && <CustomPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '300px' }}>
          <CustomCalendarPicker
            selected={dateRange}
            setSelected={dateRangeChangeHandler}
            disablePreviousDates={!onlyPreviousDates}
            disableUpcomingDates={onlyPreviousDates}
          />
        </Box>
      </CustomPopover>
    }
    {
      upToSm && <ResponsiveModal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <CalendarPickerContainer>
          <CustomCalendarPicker
            selected={dateRange}
            setSelected={dateRangeChangeHandler}
            disablePreviousDates={!onlyPreviousDates}
            disableUpcomingDates={onlyPreviousDates}
          />
        </CalendarPickerContainer>
      </ResponsiveModal>
    }
  </Root>
}

export default DateRangePicker
