/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const LivingRoomLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M15 0H5C4.20435 0 3.44129 0.316071 2.87868 0.87868C2.31607 1.44129 2 2.20435 2 3V4.028C3.18604 3.89543 4.37617 4.23942 5.30859 4.98431C6.24102 5.7292 6.83936 6.81397 6.972 8H13.028C13.1606 6.81397 13.759 5.7292 14.6914 4.98431C15.6238 4.23942 16.814 3.89543 18 4.028V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM17.5 6C18.163 6 18.7989 6.26339 19.2678 6.73223C19.7366 7.20107 20 7.83696 20 8.5V13C20.0003 13.6207 19.8081 14.2261 19.4499 14.733C19.0917 15.2398 18.5852 15.6231 18 15.83V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C16.7348 18 16.4804 17.8946 16.2929 17.7071C16.1054 17.5196 16 17.2652 16 17V16H4V17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V15.83C1.41484 15.6231 0.908257 15.2398 0.550056 14.733C0.191856 14.2261 -0.00032496 13.6207 4.12473e-07 13V8.5C4.12473e-07 7.83696 0.263393 7.20107 0.732233 6.73223C1.20107 6.26339 1.83696 6 2.5 6C3.16304 6 3.79893 6.26339 4.26777 6.73223C4.73661 7.20107 5 7.83696 5 8.5C5 9.328 5.672 10 6.5 10H13.5C13.8978 10 14.2794 9.84196 14.5607 9.56066C14.842 9.27936 15 8.89782 15 8.5C15 7.12 16.12 6 17.5 6Z'
      fill={color}
    />
  </SvgIcon>
)

const LivingRoomDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M15 0H5C4.20435 0 3.44129 0.316071 2.87868 0.87868C2.31607 1.44129 2 2.20435 2 3V4.028C3.18604 3.89543 4.37617 4.23942 5.30859 4.98431C6.24102 5.7292 6.83936 6.81397 6.972 8H13.028C13.1606 6.81397 13.759 5.7292 14.6914 4.98431C15.6238 4.23942 16.814 3.89543 18 4.028V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM17.5 6C18.163 6 18.7989 6.26339 19.2678 6.73223C19.7366 7.20107 20 7.83696 20 8.5V13C20.0003 13.6207 19.8081 14.2261 19.4499 14.733C19.0917 15.2398 18.5852 15.6231 18 15.83V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C16.7348 18 16.4804 17.8946 16.2929 17.7071C16.1054 17.5196 16 17.2652 16 17V16H4V17C4 17.2652 3.89464 17.5196 3.70711 17.7071C3.51957 17.8946 3.26522 18 3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V15.83C1.41484 15.6231 0.908257 15.2398 0.550056 14.733C0.191856 14.2261 -0.00032496 13.6207 4.12473e-07 13V8.5C4.12473e-07 7.83696 0.263393 7.20107 0.732233 6.73223C1.20107 6.26339 1.83696 6 2.5 6C3.16304 6 3.79893 6.26339 4.26777 6.73223C4.73661 7.20107 5 7.83696 5 8.5C5 9.328 5.672 10 6.5 10H13.5C13.8978 10 14.2794 9.84196 14.5607 9.56066C14.842 9.27936 15 8.89782 15 8.5C15 7.12 16.12 6 17.5 6Z'
      fill={color}
    />
  </SvgIcon>
)

const LivingRoomIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <LivingRoomDarkIcon {...props} />
    : <LivingRoomLightIcon {...props} />
}

export default LivingRoomIcon
