import { styled } from '@mui/material/styles'

const Label = styled('div')(({ theme, customStyles }) => ({
  color: theme.palette.font.black,
  font: theme.font.rubik.subtitle.medium,
  fontWeight: '600',
  padding: `${theme.spacing(3)} 0 ${theme.spacing(1)} ${theme.spacing(2)}`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
    ...customStyles,
  },
}))

const HomeLabel = ({ children, customStyles, ...props }) => {

  return <Label {...props} customStyles={customStyles}>
    { children }
  </Label>
}

export default HomeLabel
