import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { Backdrop } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled(props => <Backdrop {...props}/>)(({ theme }) => ({
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const CustomBackdrop = ({ show, message }) => {

  return <Root open={show} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
    <Player
      autoplay
      loop
      src='/animations/Loading.json'
      style={{ maxWidth: '250px' }}
    >
      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
    </Player>
    {message}
  </Root>
}

export default CustomBackdrop
