import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(props => <TextField {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  fontWeight: 500,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.gray[300]}`,
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& input': {
    border: 'none',
    font: theme.font.roboto.paragraph.medium,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  '& fieldset': {
    display: 'none',
  },
}))

const CustomTextField = props => {
  return <StyledTextField size='small' {...props} />
}

export default CustomTextField
