import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { getProductPurchaseService } from '@services/productPurchase/getProductPurchase'
import { updateProductPurchaseService } from '@services/productPurchase/updateProductPurchase'
import { getProductPurchaseClabeService } from '@services/productPurchase/getProductPurchaseClabe'
import { payWithCardProductsService } from '@services/productPurchase/payWithCardProducts'
import { payWithCreditCardProductsService } from '@services/productPurchase/payWithCreditCardProducts'
import { payWithTransferProductsService } from '@services/productPurchase/payWithTransferProducts'

export default function useProductPurchase() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getProductPurchase  = useCallback((originalPurchaseId, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getProductPurchaseService(apolloClient, { originalPurchaseId, options })
        resolve(response.productPurchase)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const updateProductPurchase = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await updateProductPurchaseService(apolloClient, { variables, options })
        resolve(response.updateProductPurchase)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getProductPurchaseClabe  = useCallback((purchaseId, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getProductPurchaseClabeService(apolloClient, { purchaseId, options })
        resolve(response.getProductPurchaseClabe)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const payWithCard  = useCallback((purchaseId, paymentMethod, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await payWithCardProductsService(apolloClient, {
          variables: { purchaseId, paymentMethod },
          options,
        })
        resolve(response.payWithCardProducts)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const payWithActnetCard  = useCallback((purchaseId, paymentMethod, cvv, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await payWithCreditCardProductsService(apolloClient, {
          variables: { purchaseId, paymentMethod, cvv },
          options,
        })
        resolve(response.payWithCreditCardProducts)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const payWithTransfer  = useCallback((purchaseId, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await payWithTransferProductsService(apolloClient, {
          variables: { purchaseId },
          options,
        })
        resolve(response.payWithTransferProducts)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    getProductPurchase,
    updateProductPurchase,
    getProductPurchaseClabe,
    payWithCard,
    payWithActnetCard,
    payWithTransfer,
  }
}
