/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getCountriesListService } from '@services/country/getCountriesList'
import { getTicketeroCountriesListService } from '@services/country/getTicketeroCountriesList'

export default function useCountry() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getCountries= useCallback((filters, options ) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await getCountriesListService(apolloClient, { variables, options })
        resolve(response.countries)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getTicketeroCountries = useCallback(options => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getTicketeroCountriesListService(apolloClient, { options })
        resolve(response.ticketeroCountries)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    getCountries,
    getTicketeroCountries,
  }
}
