import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import { InformationIcon } from '@features/App/Components/Icons'
import { useTheme } from '@features/App/hooks'

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: theme.spacing(2),
  height: theme.spacing(2),
  padding: theme.spacing(0),
  '.MuiSvgIcon-root': {
    width: theme.spacing(2),
  },
}))

const CustomTooltipHover = props => {

  const { theme } = useTheme()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsTooltipOpen(true)
  }

  const handleMouseLeave = () => {
    setIsTooltipOpen(false)
  }

  return (
    <React.Fragment>
      <CustomIconButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InformationIcon color='#626771' />
      </CustomIconButton>
      <Tooltip
        PopperProps={{
          disablePortal: true,
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'rgb(255, 255, 255)',
              borderRadius: '12px',
              boxShadow: '0 6px 30px rgb(175, 175, 175, .89)',
              padding: '10px',
              font: theme.font.rubik.paragraph.small,
              color: theme.palette.gray[700],
              '& p': {
                margin: theme.spacing(0),
              },
            },
          },
        }}
        placement='bottom'
        open={isTooltipOpen}
        title={<p>{props.bodyText}</p>}
      />
    </React.Fragment>
  )
}

export default CustomTooltipHover
