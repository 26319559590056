import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: theme.spacing(2),
  height: theme.spacing(2),
  padding: theme.spacing(0),
  '.MuiSvgIcon-root': {
    width: theme.spacing(2),
  },
}))

const DescriptionBox = styled(props => <Box {...props}/>)(({ theme }) => ({
  width: '100%', 
  gap: theme.spacing(1), 
  display: 'flex',
  flexDirection: 'column',
}))

const TitleLabel = styled(props => <Box {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '700',
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.gray[700],
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

const Label = styled(Box)(({ theme }) => ({
  color: theme.palette.gray[500],
  font: theme.font.roboto.paragraph.small,
}))

const TooltipSecurityDeposit = props => {

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsTooltipOpen(true)
  }

  const handleMouseLeave = () => {
    setIsTooltipOpen(false)
  }

  return <Tooltip
    PopperProps={{
      disablePortal: true,
      sx: {
        '& .MuiTooltip-tooltip': {
          backgroundColor: 'rgb(255, 255, 255)',
          borderRadius: '12px',
          boxShadow: '0 6px 30px rgb(175, 175, 175, .89)',
          padding: '10px',

        },
      },
    }}
    placement='bottom'
    open={isTooltipOpen}
    title={
      <DescriptionBox> 
        <TitleLabel>
          <InfoOutlinedIcon sx={{ color: '#212121' }}/>
          {'     '} {props.titleText}
        </TitleLabel>
        <Label>{props.bodyText}</Label>
      </DescriptionBox>
    }
  >
    <CustomIconButton
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InfoOutlinedIcon color='#212121' />
    </CustomIconButton>
  </Tooltip>
}

export default TooltipSecurityDeposit
