import { getProductPurchaseClabe } from '@graphql/productPurchase.graphql'

export const getProductPurchaseClabeService = async (apolloClient, { purchaseId, options }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: getProductPurchaseClabe,
      variables: { purchase: purchaseId },
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
