import { getBoxEventPurchasesForClient } from '@graphql/purchase.graphql'

export const getPurchasesForClientListService = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: getBoxEventPurchasesForClient,
      variables,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
