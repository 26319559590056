import { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { updatedBoxes } from '@graphql/publication.graphql'

export default function useBoxesUpdateSubscription(eventId){

  const [lastHash, setLastHash] = useState(null)
  const [updatedEvent, setUpdatedEvent] = useState(null)

  const UpdatingEventSubscription = useSubscription(updatedBoxes, {
    shouldResubscribe: true,
    variables: {
      eventId: eventId,
    },
  })

  useEffect(() => {
    if (!UpdatingEventSubscription) return
    if (UpdatingEventSubscription.loading) return
    if (!UpdatingEventSubscription.data) return
    if (!UpdatingEventSubscription.data.updatedBoxes) return
    if (UpdatingEventSubscription.data.updatedBoxes.hash === lastHash) return
    setUpdatedEvent(UpdatingEventSubscription.data.updatedBoxes.boxEvents)
    setLastHash(UpdatingEventSubscription.data.updatedBoxes.hash)
    if (window?.gonative?.webview) window.gonative.webview.clearCache()
  }, [UpdatingEventSubscription])

  return updatedEvent
}
