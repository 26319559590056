import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useCallback, useContext, useEffect } from 'react'
import { Context as LocaleContext } from '@features/Locale'

require('dayjs/locale/es')
require('dayjs/locale/en')
require('dayjs/locale/pt')
require('dayjs/locale/fr')

export default function LocalizationContextProvider({ children }) {

  const { locale } = useContext(LocaleContext)

  const dayjsLocales = {
    es: async () => await import('dayjs/locale/es'),
    en: async () => await import('dayjs/locale/en'),
    fr: async () => await import('dayjs/locale/fr'),
    pt: async () => await import('dayjs/locale/pt'),
  }

  const loadLocale = useCallback( language => {
    const _language = Object.keys(dayjsLocales).indexOf(language) !== -1 ? language : 'es'
    dayjsLocales[_language]().then(() => dayjs.locale(_language))
  }, [dayjs])

  useEffect(() => {
    const _locale = Object.keys(dayjsLocales).indexOf(locale) !== -1 ? locale : 'es'
    dayjs.locale(_locale)
    loadLocale(locale)
  }, [locale])

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    { children }
  </LocalizationProvider>
}
