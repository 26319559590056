import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getTicketeroSuggestService } from '@services/search/getTicketeroSuggest'

export default function useSearch() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)

  const getTicketeroSuggest = useCallback((filters, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getTicketeroSuggestService(apolloClient, { variables, options })
        resolve(response.ticketeroSuggest)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    getTicketeroSuggest,
  }
}
