import { useContext } from 'react'
import Context from '@context/Locale'

export default function useGlossary() {
  const locale = useContext(Context)

  const getNestedTranslations = (object = {}, words = []) => {
    const currentWord = words.shift()

    if (words.length > 0) return getNestedTranslations(object[currentWord], words)

    return object[currentWord]
  }

  const glossary = (label, replace = {}) => {
    let localeString = getNestedTranslations(locale.glossary, label.split('.'))
    // let localeString = locale.glossary[label]
    if (!localeString) return
    for (const clave in replace)
      if (replace.hasOwnProperty(clave)) {
        const regex = new RegExp('\\*\\|' + clave + '\\|\\*', 'g')
        localeString = localeString.replace(regex, replace[clave])
      }

    return localeString
  }

  return {
    glossary,
  }
}
