import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { MinusIcon, PlusIcon } from '@icons/index'

const Root = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: theme.spacing(12),
}))

const Circle = styled(props => <Box {...props} />)(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(3),
  width: theme.spacing(3),
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: disabled ? theme.palette.gray[500] : theme.palette.secondary.main,
  '& svg': {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '& path': {
      stroke: theme.palette.gray[100],
    },
  },
}))

const Label = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
  fontWeight: '500',
}))

const NumberCounter = ({ number, onChange, min = 0, max, error, ...props }) => {

  const disabledMinus = min !== undefined && min !== null && number === min
  const disabledPlus = max !== undefined && max !== null && number === max

  return (
    <Root>
      <Circle
        disabled={disabledMinus}
        onClick={e => {
          const newValue = number === min ? number : number - 1
          if (newValue !== number) {
            onChange(newValue)
          }
        }}
      >
        <MinusIcon width={'16px'} height={'16px'} />
      </Circle>
      <Label sx={{color: error ? 'red' : 'inherit'}}>{number}</Label>
      <Circle
        disabled={disabledPlus}
        onClick={e => {
          const newValue = number === max ? number : number + 1
          if (newValue !== number) {
            onChange(newValue)
          }
        }}
      >
        <PlusIcon width={'16px'} height={'16px'} />
      </Circle>
    </Root>
  )
}

export default NumberCounter
