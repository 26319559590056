import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'
import ArrowLeftIcon from './Icons/ArrowLeftIcon'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'

import baseConfig from '@baseConfig'
import { useError, useGlossary } from '../hooks'

const Root = styled(props => <Box {...props}/>)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  font: theme.font.rubik.paragraph.large,
  fontWeight: '500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%',
}))

const IconContainer = styled(props => <Box {...props}/>)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  '& svg path': {
    fill: theme.palette.gray[700],
  },
}))

const CustomIconButton = styled(props => <IconButton {...props}/>)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  '& svg path': {
    fill: theme.palette.gray[700],
  },
}))

const GoBack = ({ title = '', showCopy=false, ...props }) => {

  const router = useRouter()
  const { showSuccess } = useError()
  const { glossary } = useGlossary()

  const handleBack = () => {
    router.back()
  }

  const handleCopy = link => {
    navigator.clipboard.writeText(link).then(() => {
      showSuccess(glossary('Copied'));
    }).catch((err) => {
      console.error('Failed to copy link: ', err);
    })
  }

  return <Root>
    <IconContainer
      onClick={handleBack}
    >
      <ArrowLeftIcon/>
    </IconContainer>
    <Box>
      { title }
    </Box>
    {!showCopy && <IconContainer/>}
   {showCopy && 
    <CustomIconButton onClick={() => { handleCopy(`https://${baseConfig.rootDomain}${router.asPath}`)}}>
      <ShareRoundedIcon/>
    </CustomIconButton>
    }
  </Root>
}

export default GoBack
