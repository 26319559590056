import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { useGlossary } from '@App/hooks'
import { FilterChipButton } from '@App/Components'

const BoxEventTypeFilter = () => {

  const { glossary } = useGlossary()
  const open = false

  return (
    <FilterChipButton
      label={ glossary('BoxType') }
      icon={
        open ?
          <KeyboardArrowUpOutlinedIcon fontSize={'small'}/> :
          <KeyboardArrowDownOutlinedIcon fontSize={'small'}/>
      }
      onClick={() => {}}
      highlighted={false}/>
  )
}

export default BoxEventTypeFilter
