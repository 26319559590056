import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const LocalizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(LocalizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export default function useDate() {

  const format = (date, format = 'L', locale = null, tz=null) => {
    let _date = dayjs(date)
    if(tz) _date = _date.tz(tz)
    return locale ? _date.locale(locale).format(format) : _date.format(format)
  }

  const formatToDDMMYY = (date, tz=null) => {
    let _date = dayjs(date)
    if(tz) _date = _date.tz(tz)
    return _date.format('DD/MM/YY')
  }

  const formatDateES = (date, tz=null) => {
    let _date = dayjs(date)
    if(tz) _date = _date.tz(tz)
    return _date.format('D [de] MMMM')  // Custom format for "10 de octubre"
  }

  return {
    format,
    formatToDDMMYY,
    formatDateES,
  }
}
