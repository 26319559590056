import { styled } from '@mui/material/styles'
import { Switch } from '@mui/material'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 46,
  height: '100%',
  maxHeight: theme.spacing(3),
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      //transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 46 / 2,
    opacity: 1,
    backgroundColor: theme.palette.gray[300],
    boxSizing: 'border-box',
  },
}))

export default CustomSwitch
