import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ArrowLeftLightIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M19.0002 11.0001C19.5524 11.0001 20.0002 11.4478 20.0002 12.0001C20.0002 12.5524 19.5524 13.0001 19.0002 13.0001H8.00016L12.793 17.793C13.1836 18.1835 13.1807 18.8196 12.7902 19.2101C12.3996 19.6006 11.7636 19.6035 11.373 19.213L4.86726 12.7072C4.47674 12.3167 4.47674 11.6835 4.86726 11.293L11.373 4.78719C11.7636 4.39666 12.3996 4.39955 12.7902 4.79008C13.1807 5.1806 13.1836 5.81666 12.793 6.20719L8.00016 11.0001H19.0002Z'
      fill={color}
    />
  </SvgIcon>
)

const ArrowLeftDarkIcon = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M19.0002 11.0001C19.5524 11.0001 20.0002 11.4478 20.0002 12.0001C20.0002 12.5524 19.5524 13.0001 19.0002 13.0001H8.00016L12.793 17.793C13.1836 18.1835 13.1807 18.8196 12.7902 19.2101C12.3996 19.6006 11.7636 19.6035 11.373 19.213L4.86726 12.7072C4.47674 12.3167 4.47674 11.6835 4.86726 11.293L11.373 4.78719C11.7636 4.39666 12.3996 4.39955 12.7902 4.79008C13.1807 5.1806 13.1836 5.81666 12.793 6.20719L8.00016 11.0001H19.0002Z'
      fill={color}
    />
  </SvgIcon>
)

const ArrowLeftIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ArrowLeftDarkIcon {...props} /> : <ArrowLeftLightIcon {...props} />
}

export default ArrowLeftIcon
