import { styled } from '@mui/material/styles'
import { Autocomplete, Paper, Popper } from '@mui/material'

const StyledAutocomplete = styled(Autocomplete, { shouldForwardProp: prop => prop !== 'classes' })(
  ({ theme }) => ({
    '& .MuiFormControl-root': {
      height: '100%',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& svg': {
      fill: theme.palette.gray[500],
    },
    '& fieldset': {
      border: '1px solid',
      borderColor: theme.palette.gray[300],
    },
    '& input': {
      color: theme.palette.font.black,
    },
    '& .MuiInputLabel-formControl': {
      color: theme.palette.gray[500],
      '&.MuiInputLabel-shrink': {
        color: theme.palette.gray[500],
      },
    },
    '& .MuiChip-root': {
      color: theme.palette.font.black,
      backgroundColor: theme.palette.components.white,
    },
    '& .MuiAutocomplete-tag': {
      color: theme.palette.font.black,
    },
    '& .Mui-error': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
      '& input': {
        color: theme.palette.error.main,
      },
      '& .MuiInputLabel-formControl': {
        color: theme.palette.error.main,
      },
      '& svg': {
        fill: theme.palette.error.main,
      },
    },
  })
)

const StyledPaper = styled(props => <Paper {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.components.chips.white,
    backdropFilter: 'blur(3px)',
    color: theme.palette.font.black,
    '& *::-webkit-scrollbar': {
      width: theme.spacing(1),
      backgroundColor: 'transparent',
    },
    '& *::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(180,180,180,0.2)',
    },
  })
)

const StyledPopper = styled(props => <Popper {...props} />)(
  ({ theme }) => ({
    color: `${theme.palette.font.black} !important`,
    '& *':{
      color: `${theme.palette.font.black} !important`,
    },
  })
)

const CustomAutocomplete = props => {

  return <StyledAutocomplete
    size={'small'}
    PaperComponent={StyledPaper}
    PopperComponent={StyledPopper}
    {...props}
  />
}

export default CustomAutocomplete
