import { updateUser } from '@graphql/user.graphql'

export const updateUserService = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: updateUser,
      variables,
      ...options,
    })
    return resp.data
  } catch (error) {
    throw new Error(error)
  }
}
