import { getTaxRegimes } from '@graphql/cfdi.graphql'

export const getTaxRegimesService = async (apolloClient, { options }) => {
  try {
    const resp = await apolloClient.query({
      query: getTaxRegimes,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
