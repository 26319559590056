import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import { getTaxRegimesService } from '@services/cfdi/getTaxRegimes'
import { getCfdiUsesService } from '@services/cfdi/getCfdiUses'
import { createPurchaseCfdiService } from '@services/cfdi/createPurchaseCfdi'
import { createProductPurchaseCfdiService } from '@services/cfdi/createProductPurchaseCfdi'
import { sendCfdiMailService } from '@services/cfdi/sendCfdiMail'

export default function useCfdi() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const getTaxRegimes =  useCallback((options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getTaxRegimesService(apolloClient, { options })
        resolve(response.getTaxRegimes)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const getCfdiUses =  useCallback((options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getCfdiUsesService(apolloClient, { options })
        resolve(response.getCfdiUses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const createPurchaseCfdi = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await createPurchaseCfdiService(apolloClient, { variables, options })
        resolve(response.createPurchaseCfdi)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const createProductPurchaseCfdi = useCallback((variables, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await createProductPurchaseCfdiService(apolloClient, { variables, options })
        resolve(response.createProductPurchaseCfdi)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  const sendCfdiMail =  useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await sendCfdiMailService(apolloClient, { id, options })
        resolve(response.sendCfdiMail)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    getTaxRegimes,
    getCfdiUses,
    createPurchaseCfdi,
    createProductPurchaseCfdi,
    sendCfdiMail,
  }
}
