import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { getEventWaitListService } from '../services/waitList/getEventWaitListService'
import { waitlistSignUp } from '../services/waitList/waitlistSignUp'

export const useWaitList = () => {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [loadingSource, setLoadingSource] = useState(null)
  
  const getEventWaitList = useCallback((filters, options = {})  => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        setLoadingSource('list')
        const variables = filters || {}
        const response = await getEventWaitListService(apolloClient, { variables, options })
        resolve(response.getWaitListsStatistics)
        setLoading(false)
        setLoadingSource(null)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const userWaitlistSignUp = useCallback( variables => {
      return new Promise(async (resolve, reject) => {
        try {
          setLoading(true)
          const response = await waitlistSignUp(apolloClient, variables)
          setLoading(false)
          resolve(response)
        } catch (error) {
          reject(error)
        }
      })
    }, [apolloClient])
  

  return {
    loading, 
    loadingSource,
    getEventWaitList,
    userWaitlistSignUp,
  }
}
