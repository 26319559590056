/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const InfoLightIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M12 8V12'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M12 16H12.01'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const InfoDarkIcon = ({ color = '#8FA8BD', stroke = 2, ...props }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M12 8V12'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M12 16H12.01'
      stroke={color}
      strokeWidth={stroke}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </SvgIcon>
)

const InfoIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <InfoDarkIcon {...props} />
    : <InfoLightIcon {...props} />
}

export default InfoIcon
