/* eslint-disable no-console */
import { ApolloProvider } from '@apollo/client'
import { CacheProvider } from '@emotion/react'
import { getCookie } from 'cookies-next'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import tawkTo from 'tawkto-react'

import AppContextProvider from '@context/App/AppContextProvider'
import baseConfig from '../../base.config'
import SubscriptionsProvider from '../context/SubscriptionsProvider'
import UploadContextProvider from '../features/Upload/context/UploadContextProvider'
import createEmotionCache from '../lib/createEmotionCache'

import UploadProvider from '../features/Upload-v2/Context/store'

import LocaleContextProvider from '@context/Locale/LocaleContextProvider'
import LocalizationContextProvider from '@context/LocalizationContextProvider'
import ThemeContextProvider from '@context/ThemeContextProvider'
import { createWithApollo, useApollo } from '@lib/apolloClient'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
// import ReactGA from 'react-ga4'
// import TiktokPixel from 'tiktok-pixel'
import { SNACKBAR_STYLE } from '@constants/snackbarStyle'
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@features/App/Components/Icons/animated'
import Router from 'next/router'
import nProgress from 'nprogress'
import '../../public/css/main.css'
import { LocationProvider } from '@features/Filters'
import TawkToMessenger from '@features/App/Components/TawkToMessenger'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const paypalOptions = {
  'client-id': baseConfig.paypalId,
  intent: 'capture',
}

const StadiboxApp = props => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const locale = pageProps?.locale
  const agentId = getCookie('agent')
  const { apolloClient } = useApollo(agentId)

  const getLayout = Component.getLayout || (page => page)

  useEffect(() => {
    nProgress.configure({ showSpinner: false })

    Router.events.on('routeChangeStart', url => {
      nProgress.start()
      // Median.onReady(() => {
      //   window?.median?.webview?.clearCache()
      // })
    })

    Router.events.on('routeChangeError', url => nProgress.done())
    Router.events.on('routeChangeComplete', url => nProgress.done())

    // TODO: uncomment this for start traking data in production
    // React facebook pixel
    const loadFacebookPixel = async () => {
      const ReactFacebookPixel = (await import('react-facebook-pixel')).default
      if (typeof ReactFacebookPixel === 'undefined' || !ReactFacebookPixel) return
      ReactFacebookPixel.init('393478178388305')
      ReactFacebookPixel.pageView()
    }
    loadFacebookPixel().catch(error => console.error(error))

    // eslint-disable-next-line no-unused-vars
    // TiktokPixel.init('CG4CSR3C77UDBVQ97LB0').then(r => {})
    // TiktokPixel.track('PageView')

    const loadReactGa = async () => {
      // ReactGA.initialize('G-LQ86ZW73EX')
    }
    loadReactGa().catch(error => console.error(error))
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{baseConfig.defaultTitle}</title>
        <link rel={'canonical'} href={`${locale === 'es' ? '' : locale}`} />
        <link rel={'alternate'} hrefLang={locale} href={`${baseConfig.rootDomain}/${locale === 'es' ? '' : locale}`} />
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
        <meta name='msapplication-TileColor' content='#F1F3F6' />
        <meta name='theme-color' content='#16A2CD' />
      </Head>
      {/* <TawkToMessenger /> */}
      <ApolloProvider client={apolloClient}>
        <GoogleOAuthProvider clientId='453855202091-vdgufg6m27vb4gpa0anpk4gt04d5escd.apps.googleusercontent.com'>
          <SnackbarProvider
            maxSnack={4}
            syle={{ marginTop: 70 }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            autoHideDuration={2500}
            preventDuplicate={true}
            iconVariant={{
              error: <ErrorIcon playsOnAppears={true} />,
              warning: <WarningIcon playsOnAppears={true} />,
              success: <SuccessIcon playsOnAppears={true} />,
              info: <InfoIcon playsOnAppears={true} />,
            }}
            sx={SNACKBAR_STYLE}
          >
            <PayPalScriptProvider options={paypalOptions}>
              <AppContextProvider>
                <UploadProvider client={apolloClient}>
                  <ThemeContextProvider>
                    <SubscriptionsProvider>
                      <LocaleContextProvider>
                        <UploadContextProvider>
                          <LocalizationContextProvider>
                            <LocationProvider>{getLayout(<Component {...pageProps} />)}</LocationProvider>
                          </LocalizationContextProvider>
                        </UploadContextProvider>
                      </LocaleContextProvider>
                    </SubscriptionsProvider>
                  </ThemeContextProvider>
                </UploadProvider>
              </AppContextProvider>
            </PayPalScriptProvider>
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </CacheProvider>
  )
}

export default createWithApollo(StadiboxApp)
