import { createContext } from 'react'
import baseConfig from '@baseConfig'
import defaultGlossary from '@features/Locale/es.json'

const Context = createContext({
  currentLocale: baseConfig.defaultLocale,
  glossary: defaultGlossary,
})

export default Context
