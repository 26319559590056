/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const GreenArrowLigth = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='29' height='32' viewBox='0 0 29 32' fill='none' {...props}>
    <g filter='url(#filter0_d_6726_25803)'>
    <path d='M8.9232 27.4055L2.28073 2.55015C1.81597 0.811082 3.70558 -0.608748 5.24661 0.321616L25.9744 12.8356C27.586 13.8086 27.0833 16.2663 25.2191 16.5283L17.2785 17.6443C16.5315 17.7493 15.9071 18.2656 15.6638 18.9797L12.7485 27.5342C12.1107 29.4057 9.43368 29.3156 8.9232 27.4055Z'
    fill={color}/>
    </g>
    <defs>
    <filter id='filter0_d_6726_25803' x='0.750609' y='0.0273438' width='27.6501' height='31.7786' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
    <feFlood flood-opacity='0' result='BackgroundImageFix'/>
    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
    <feOffset dy='1.45838'/>
    <feGaussianBlur stdDeviation='0.729188'/>
    <feComposite in2='hardAlpha' operator='out'/>
    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/>
    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6726_25803'/>
    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_6726_25803' result='shape'/>
    </filter>
    </defs>
  </SvgIcon>
) 

const GreenArrowDark = ({ color = '#8FA8BD', ...props }) => (
  <SvgIcon  width='29' height='32' viewBox='0 0 29 32' fill='none' {...props}>
    <g filter='url(#filter0_d_6726_25803)'>
    <path d='M8.9232 27.4055L2.28073 2.55015C1.81597 0.811082 3.70558 -0.608748 5.24661 0.321616L25.9744 12.8356C27.586 13.8086 27.0833 16.2663 25.2191 16.5283L17.2785 17.6443C16.5315 17.7493 15.9071 18.2656 15.6638 18.9797L12.7485 27.5342C12.1107 29.4057 9.43368 29.3156 8.9232 27.4055Z'
    fill={color}/>
    </g>
    <defs>
    <filter id='filter0_d_6726_25803' x='0.750609' y='0.0273438' width='27.6501' height='31.7786' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'>
    <feFlood flood-opacity='0' result='BackgroundImageFix'/>
    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
    <feOffset dy='1.45838'/>
    <feGaussianBlur stdDeviation='0.729188'/>
    <feComposite in2='hardAlpha' operator='out'/>
    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/>
    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6726_25803'/>
    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_6726_25803' result='shape'/>
    </filter>
    </defs>
  </SvgIcon>

)

const GreenArrowIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ?
    <GreenArrowDark {...props} />
    : <GreenArrowLigth {...props} />
}

export default GreenArrowIcon
