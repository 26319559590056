import { listTickets, listAsyncTickets } from '@graphql/tickets.graphql'

export const getTicketsListService = async (apolloClient, { event, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: listTickets,
      variables: { event },
      ...options,
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}

export const getAsyncTicketsListService = async (apolloClient, { event, options = {} }) => {
  try {
    const resp = await apolloClient.query({
      query: listAsyncTickets,
      variables: { event },
      ...options,
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}