/* eslint-disable max-len */
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'
import { SvgIcon } from '@mui/material'

const HistoryRentLightIcon = ({ color = '#212121', ...props }) => (
  <SvgIcon width='14' height='15' viewBox='0 0 14 15' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6 6.23398L2.05 3.95065L1.33333 4.36732V5.06732L6 7.76732L10.6667 5.06732V4.36732L9.95 3.95065L6 6.23398ZM5.33333 13.484L0.666667 10.8007C0.455556 10.6784 0.291778 10.5173 0.175333 10.3173C0.0588889 10.1173 0.000444444 9.8951 0 9.65065V4.35065C0 4.10621 0.0584444 3.88398 0.175333 3.68398C0.292222 3.48398 0.456 3.32287 0.666667 3.20065L5.33333 0.517318C5.54444 0.395096 5.76667 0.333984 6 0.333984C6.23333 0.333984 6.45555 0.395096 6.66667 0.517318L11.3333 3.20065C11.5444 3.32287 11.7084 3.48398 11.8253 3.68398C11.9422 3.88398 12.0004 4.10621 12 4.35065V7.45065C11.7 7.30621 11.3807 7.1951 11.042 7.11732C10.7033 7.03954 10.356 7.00065 10 7.00065C8.71111 7.00065 7.61111 7.45621 6.7 8.36732C5.78889 9.27843 5.33333 10.3784 5.33333 11.6673C5.33333 12.0229 5.36956 12.3647 5.442 12.6927C5.51444 13.0207 5.62267 13.3344 5.76667 13.634C5.68889 13.6118 5.614 13.5924 5.542 13.576C5.47 13.5595 5.40044 13.5289 5.33333 13.484ZM10 15.0007C9.07778 15.0007 8.29178 14.6755 7.642 14.0253C6.99222 13.3751 6.66711 12.5891 6.66667 11.6673C6.66667 10.7451 6.99178 9.9591 7.642 9.30932C8.29222 8.65954 9.07822 8.33443 10 8.33398C10.9222 8.33398 11.7084 8.6591 12.3587 9.30932C13.0089 9.95954 13.3338 10.7455 13.3333 11.6673C13.3333 12.5895 13.0082 13.3758 12.358 14.026C11.7078 14.6762 10.9218 15.0011 10 15.0007ZM10.3333 11.534V9.66732H9.66667V11.8007L11.1 13.234L11.5667 12.7673L10.3333 11.534Z'
      fill={color}
    />
  </SvgIcon>
)

const HistoryRentDarkIcon = ({ color = '#212121', ...props }) => (
  <SvgIcon width='14' height='15' viewBox='0 0 14 15' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M6 6.23398L2.05 3.95065L1.33333 4.36732V5.06732L6 7.76732L10.6667 5.06732V4.36732L9.95 3.95065L6 6.23398ZM5.33333 13.484L0.666667 10.8007C0.455556 10.6784 0.291778 10.5173 0.175333 10.3173C0.0588889 10.1173 0.000444444 9.8951 0 9.65065V4.35065C0 4.10621 0.0584444 3.88398 0.175333 3.68398C0.292222 3.48398 0.456 3.32287 0.666667 3.20065L5.33333 0.517318C5.54444 0.395096 5.76667 0.333984 6 0.333984C6.23333 0.333984 6.45555 0.395096 6.66667 0.517318L11.3333 3.20065C11.5444 3.32287 11.7084 3.48398 11.8253 3.68398C11.9422 3.88398 12.0004 4.10621 12 4.35065V7.45065C11.7 7.30621 11.3807 7.1951 11.042 7.11732C10.7033 7.03954 10.356 7.00065 10 7.00065C8.71111 7.00065 7.61111 7.45621 6.7 8.36732C5.78889 9.27843 5.33333 10.3784 5.33333 11.6673C5.33333 12.0229 5.36956 12.3647 5.442 12.6927C5.51444 13.0207 5.62267 13.3344 5.76667 13.634C5.68889 13.6118 5.614 13.5924 5.542 13.576C5.47 13.5595 5.40044 13.5289 5.33333 13.484ZM10 15.0007C9.07778 15.0007 8.29178 14.6755 7.642 14.0253C6.99222 13.3751 6.66711 12.5891 6.66667 11.6673C6.66667 10.7451 6.99178 9.9591 7.642 9.30932C8.29222 8.65954 9.07822 8.33443 10 8.33398C10.9222 8.33398 11.7084 8.6591 12.3587 9.30932C13.0089 9.95954 13.3338 10.7455 13.3333 11.6673C13.3333 12.5895 13.0082 13.3758 12.358 14.026C11.7078 14.6762 10.9218 15.0011 10 15.0007ZM10.3333 11.534V9.66732H9.66667V11.8007L11.1 13.234L11.5667 12.7673L10.3333 11.534Z'
      fill={color}
    />
  </SvgIcon>
)

const HistoryRentIcon = props => {
  const { darkMode } = useContext(Context)

  return darkMode ? <HistoryRentDarkIcon {...props} /> : <HistoryRentLightIcon {...props} />
}

export default HistoryRentIcon
