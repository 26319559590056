import { Box, FormControl, FormHelperText } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomNumericField from './CustomNumericField'

const FormControlStyled = styled(props => <FormControl {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  gap: theme.spacing(1),
  flexDirection: 'column',
}))

const InputContainer = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
}))

const Label = styled(props => <Box {...props}/>)(({ theme }) => ({
  color: theme.palette.gray[700],
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const StyledCustomNumericField = styled(props => <CustomNumericField {...props}/>)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.gray[300]}`,
    borderRadius:theme.spacing(0.5),
    height: '100%',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& input': {
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    font: theme.font.roboto.paragraph.medium,
    fontWeight: '500',
    textAlign: 'left',
  },
}))

const CustomNumericInput = ({ label, value, onChange, error, errorMessage, ...props }) => {
  return <FormControlStyled error={error}>
    <Label>
      {label}
    </Label>
    <InputContainer>
      <StyledCustomNumericField
        value={value}
        onChange={onChange}
        error={error}
      />
      { error && <FormHelperText>{errorMessage}</FormHelperText> }
    </InputContainer>
  </FormControlStyled>
}

export default CustomNumericInput
