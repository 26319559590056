/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const ImageLigthIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path d='M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M19 13L14 8L3 19' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>

  </SvgIcon>
)

const ImageDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path d='M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M19 13L14 8L3 19' stroke='#212121' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>

  </SvgIcon>
)

const ImageIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <ImageLigthIcon {...props} /> : <ImageDarkIcon {...props} />
}

export default ImageIcon
