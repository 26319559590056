import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const PayPalLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M10.4297 12.99C10.5297 12.99 12.8497 13.09 14.2297 12.75H14.2397C15.8297 12.36 18.0397 11.24 18.6097 7.58C18.6097 7.58 19.8797 3 13.5797 3H8.16967C7.67967 3 7.25967 3.36 7.17967 3.84L4.87967 18.4C4.82967 18.7 5.06967 18.98 5.36967 18.98H8.79967L9.63967 13.66C9.69967 13.28 10.0297 12.99 10.4297 12.99Z'
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M19.4896 8.29004C18.6796 12.02 16.1296 13.99 12.0696 13.99H10.5996L9.56956 20.51C9.52956 20.77 9.72956 21 9.98956 21H11.8896C12.2296 21 12.5296 20.75 12.5796 20.41C12.6596 20.01 13.0996 17.09 13.1896 16.59C13.2396 16.25 13.5396 16 13.8796 16H14.3196C17.1396 16 19.3496 14.85 19.9996 11.54C20.2596 10.2 20.1196 9.10004 19.4896 8.29004Z'
      fill={color}
    />
  </SvgIcon>
)

const PayPalDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M10.4297 12.99C10.5297 12.99 12.8497 13.09 14.2297 12.75H14.2397C15.8297 12.36 18.0397 11.24 18.6097 7.58C18.6097 7.58 19.8797 3 13.5797 3H8.16967C7.67967 3 7.25967 3.36 7.17967 3.84L4.87967 18.4C4.82967 18.7 5.06967 18.98 5.36967 18.98H8.79967L9.63967 13.66C9.69967 13.28 10.0297 12.99 10.4297 12.99Z'
      fill={color}
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M19.4896 8.29004C18.6796 12.02 16.1296 13.99 12.0696 13.99H10.5996L9.56956 20.51C9.52956 20.77 9.72956 21 9.98956 21H11.8896C12.2296 21 12.5296 20.75 12.5796 20.41C12.6596 20.01 13.0996 17.09 13.1896 16.59C13.2396 16.25 13.5396 16 13.8796 16H14.3196C17.1396 16 19.3496 14.85 19.9996 11.54C20.2596 10.2 20.1196 9.10004 19.4896 8.29004Z'
      fill={color}
    />
  </SvgIcon>
)

const PayPalIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <PayPalDarkIcon {...props} /> : <PayPalLightIcon {...props} />
}

export default PayPalIcon
