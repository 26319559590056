import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const UploadIconLight = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='20' height='23' viewBox='0 0 20 23' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0.666626 22.6667H19.3333V20H0.666626V22.6667ZM0.666626 9.33333H5.99996V17.3333H14V9.33333H19.3333L9.99996 0L0.666626 9.33333Z' 
      fill={color}
    />
  </SvgIcon>
)

const UploadIconDark = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='20' height='23' viewBox='0 0 20 23' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M0.666626 22.6667H19.3333V20H0.666626V22.6667ZM0.666626 9.33333H5.99996V17.3333H14V9.33333H19.3333L9.99996 0L0.666626 9.33333Z' 
      fill={color}
    />
  </SvgIcon>
)

const UploadIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <UploadIconDark {...props} /> : <UploadIconLight {...props} />
}

export default UploadIcon
