import { styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'

const StyledDrawer = styled(props => <Drawer {...props}/>)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.grey[200],
    width: '100vw',
  },
}))

const CustomDrawer = ({ children, ...props }) => {

  return <StyledDrawer {...props}>
    { children }
  </StyledDrawer>
}

export default CustomDrawer
