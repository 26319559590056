import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const CashLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M4.79844 5.2998C4.16192 5.2998 3.55147 5.55266 3.10138 6.00275C2.65129 6.45284 2.39844 7.06328 2.39844 7.6998V12.4998C2.39844 13.1363 2.65129 13.7468 3.10138 14.1969C3.55147 14.6469 4.16192 14.8998 4.79844 14.8998V7.6998H16.7984C16.7984 7.06328 16.5456 6.45284 16.0955 6.00275C15.6454 5.55266 15.035 5.2998 14.3984 5.2998H4.79844ZM7.19844 12.4998C7.19844 11.8633 7.45129 11.2528 7.90138 10.8027C8.35147 10.3527 8.96192 10.0998 9.59844 10.0998H19.1984C19.835 10.0998 20.4454 10.3527 20.8955 10.8027C21.3456 11.2528 21.5984 11.8633 21.5984 12.4998V17.2998C21.5984 17.9363 21.3456 18.5468 20.8955 18.9969C20.4454 19.4469 19.835 19.6998 19.1984 19.6998H9.59844C8.96192 19.6998 8.35147 19.4469 7.90138 18.9969C7.45129 18.5468 7.19844 17.9363 7.19844 17.2998V12.4998ZM14.3984 17.2998C15.035 17.2998 15.6454 17.0469 16.0955 16.5969C16.5456 16.1468 16.7984 15.5363 16.7984 14.8998C16.7984 14.2633 16.5456 13.6528 16.0955 13.2027C15.6454 12.7527 15.035 12.4998 14.3984 12.4998C13.7619 12.4998 13.1515 12.7527 12.7014 13.2027C12.2513 13.6528 11.9984 14.2633 11.9984 14.8998C11.9984 15.5363 12.2513 16.1468 12.7014 16.5969C13.1515 17.0469 13.7619 17.2998 14.3984 17.2998Z'
      fill={color}
    />
  </SvgIcon>
)

const CashDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M4.79844 5.2998C4.16192 5.2998 3.55147 5.55266 3.10138 6.00275C2.65129 6.45284 2.39844 7.06328 2.39844 7.6998V12.4998C2.39844 13.1363 2.65129 13.7468 3.10138 14.1969C3.55147 14.6469 4.16192 14.8998 4.79844 14.8998V7.6998H16.7984C16.7984 7.06328 16.5456 6.45284 16.0955 6.00275C15.6454 5.55266 15.035 5.2998 14.3984 5.2998H4.79844ZM7.19844 12.4998C7.19844 11.8633 7.45129 11.2528 7.90138 10.8027C8.35147 10.3527 8.96192 10.0998 9.59844 10.0998H19.1984C19.835 10.0998 20.4454 10.3527 20.8955 10.8027C21.3456 11.2528 21.5984 11.8633 21.5984 12.4998V17.2998C21.5984 17.9363 21.3456 18.5468 20.8955 18.9969C20.4454 19.4469 19.835 19.6998 19.1984 19.6998H9.59844C8.96192 19.6998 8.35147 19.4469 7.90138 18.9969C7.45129 18.5468 7.19844 17.9363 7.19844 17.2998V12.4998ZM14.3984 17.2998C15.035 17.2998 15.6454 17.0469 16.0955 16.5969C16.5456 16.1468 16.7984 15.5363 16.7984 14.8998C16.7984 14.2633 16.5456 13.6528 16.0955 13.2027C15.6454 12.7527 15.035 12.4998 14.3984 12.4998C13.7619 12.4998 13.1515 12.7527 12.7014 13.2027C12.2513 13.6528 11.9984 14.2633 11.9984 14.8998C11.9984 15.5363 12.2513 16.1468 12.7014 16.5969C13.1515 17.0469 13.7619 17.2998 14.3984 17.2998Z'
      fill={color}
    />
  </SvgIcon>
)

const CashIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <CashDarkIcon {...props} /> : <CashLightIcon {...props} />
}

export default CashIcon
