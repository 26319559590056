import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const TwitterLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M20.0255 8.71851V9.19888C20.0197 10.4829 19.7777 11.7556 19.3107 12.9581C18.8213 14.213 18.1023 15.3731 17.1879 16.3832C16.2253 17.4411 15.0451 18.2945 13.7222 18.8893C12.2316 19.5364 10.6131 19.8642 8.97845 19.85C7.90785 19.8518 6.84262 19.704 5.81593 19.4114C4.82063 19.1336 3.87218 18.7186 3 18.1792C3.30325 18.2074 3.61374 18.2214 3.93144 18.221C4.80901 18.2238 5.68069 18.0826 6.5091 17.8033C7.31968 17.5313 8.08012 17.1366 8.76182 16.6338C7.9466 16.6137 7.15857 16.347 6.5091 15.8715C5.86038 15.4125 5.38194 14.7645 5.14445 14.0232C5.38019 14.0654 5.61949 14.0864 5.85926 14.0859C6.20278 14.0863 6.54493 14.0442 6.87733 13.9606C6.30076 13.8498 5.75916 13.6102 5.29606 13.261C4.8239 12.911 4.44211 12.4605 4.18052 11.9447C3.91441 11.4281 3.77018 10.8611 3.75815 10.2843V10.2217C4.29776 10.5259 4.90936 10.6913 5.53433 10.7021C5.00447 10.3592 4.56949 9.89678 4.26718 9.35496C3.95709 8.80758 3.79682 8.19307 3.80148 7.56937C3.79928 6.90953 3.97865 6.26088 4.32132 5.68971C5.29972 6.84642 6.51812 7.79318 7.89831 8.46924C9.27849 9.1453 10.79 9.53576 12.3359 9.61551C12.2776 9.33356 12.2486 9.04672 12.2492 8.75924C12.2432 8.09892 12.4229 7.44939 12.7691 6.87963C13.1111 6.31284 13.6 5.84145 14.1879 5.51169C14.7738 5.17418 15.4441 4.99727 16.1265 5.00003C16.6592 4.9988 17.1863 5.10398 17.6747 5.30895C18.1631 5.51393 18.6021 5.81426 18.9641 6.19098C19.8369 6.02699 20.6728 5.71591 21.4335 5.27205C21.2937 5.70888 21.0695 6.11628 20.7728 6.47288C20.4793 6.82587 20.1237 7.12635 19.7223 7.36051C20.5002 7.26742 21.2594 7.06328 21.975 6.75484C21.4485 7.51369 20.7888 8.17819 20.0255 8.71851V8.71851Z'
      fill={color}
    />
  </SvgIcon>
)

const TwitterDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M20.0255 8.71851V9.19888C20.0197 10.4829 19.7777 11.7556 19.3107 12.9581C18.8213 14.213 18.1023 15.3731 17.1879 16.3832C16.2253 17.4411 15.0451 18.2945 13.7222 18.8893C12.2316 19.5364 10.6131 19.8642 8.97845 19.85C7.90785 19.8518 6.84262 19.704 5.81593 19.4114C4.82063 19.1336 3.87218 18.7186 3 18.1792C3.30325 18.2074 3.61374 18.2214 3.93144 18.221C4.80901 18.2238 5.68069 18.0826 6.5091 17.8033C7.31968 17.5313 8.08012 17.1366 8.76182 16.6338C7.9466 16.6137 7.15857 16.347 6.5091 15.8715C5.86038 15.4125 5.38194 14.7645 5.14445 14.0232C5.38019 14.0654 5.61949 14.0864 5.85926 14.0859C6.20278 14.0863 6.54493 14.0442 6.87733 13.9606C6.30076 13.8498 5.75916 13.6102 5.29606 13.261C4.8239 12.911 4.44211 12.4605 4.18052 11.9447C3.91441 11.4281 3.77018 10.8611 3.75815 10.2843V10.2217C4.29776 10.5259 4.90936 10.6913 5.53433 10.7021C5.00447 10.3592 4.56949 9.89678 4.26718 9.35496C3.95709 8.80758 3.79682 8.19307 3.80148 7.56937C3.79928 6.90953 3.97865 6.26088 4.32132 5.68971C5.29972 6.84642 6.51812 7.79318 7.89831 8.46924C9.27849 9.1453 10.79 9.53576 12.3359 9.61551C12.2776 9.33356 12.2486 9.04672 12.2492 8.75924C12.2432 8.09892 12.4229 7.44939 12.7691 6.87963C13.1111 6.31284 13.6 5.84145 14.1879 5.51169C14.7738 5.17418 15.4441 4.99727 16.1265 5.00003C16.6592 4.9988 17.1863 5.10398 17.6747 5.30895C18.1631 5.51393 18.6021 5.81426 18.9641 6.19098C19.8369 6.02699 20.6728 5.71591 21.4335 5.27205C21.2937 5.70888 21.0695 6.11628 20.7728 6.47288C20.4793 6.82587 20.1237 7.12635 19.7223 7.36051C20.5002 7.26742 21.2594 7.06328 21.975 6.75484C21.4485 7.51369 20.7888 8.17819 20.0255 8.71851V8.71851Z'
      fill={color}
    />
  </SvgIcon>
)

const TwitterIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <TwitterDarkIcon {...props} /> : <TwitterLightIcon {...props} />
}

export default TwitterIcon
