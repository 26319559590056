import { styled } from '@mui/material/styles'
import CustomButton from './CustomButton'

const StyledBlueButton = styled(props => <CustomButton {...props}/>)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  border: '2px solid white',
  '&:hover': {
    border: '2px solid white',
    backgroundColor: theme.palette.gray[10],
  },
}))

const WhiteOutlinedButton = ({ children, ...props }) => {

  return <StyledBlueButton {...props} variant={'outlined'}>
    { children }
  </StyledBlueButton>
}

export default WhiteOutlinedButton
