import { useApolloClient } from '@apollo/client'
import { logoutService } from '../services/auth/auth'

export default function useAuth() {
  const apolloClient = useApolloClient()

  const onLogout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await logoutService(apolloClient)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }

  return {
    onLogout,
  }
}
