import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const SeatTakenLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M8.09031 5.41012C7.31031 4.63012 7.31031 3.36012 8.09031 2.58012C8.87031 1.80012 10.1403 1.80012 10.9203 2.58012C11.7003 3.36012 11.7003 4.63012 10.9203 5.41012C10.1303 6.20012 8.87031 6.20012 8.09031 5.41012ZM6.50031 16.0001V7.00012H4.50031V16.0001C4.50031 18.7601 6.7403 21.0001 9.5003 21.0001H15.5003V19.0001H9.5003C7.84031 19.0001 6.50031 17.6601 6.50031 16.0001ZM20.5003 20.0701L15.4303 15.0001H12.0003V11.3201C13.4003 12.4701 15.6003 13.4801 17.5003 13.4801V11.3201C15.8403 11.3401 13.8903 10.4501 12.8303 9.28012L11.4303 7.73012C11.2403 7.52012 11.0003 7.35012 10.7403 7.23012C10.4503 7.09012 10.1203 7.00012 9.7803 7.00012H9.7503C8.5103 7.00012 7.5003 8.01012 7.5003 9.25012V15.0001C7.5003 16.6601 8.8403 18.0001 10.5003 18.0001H15.5703L19.0703 21.5001L20.5003 20.0701Z'
      fill={color}
    />
  </SvgIcon>
)

const SeatTakenDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M8.09031 5.41012C7.31031 4.63012 7.31031 3.36012 8.09031 2.58012C8.87031 1.80012 10.1403 1.80012 10.9203 2.58012C11.7003 3.36012 11.7003 4.63012 10.9203 5.41012C10.1303 6.20012 8.87031 6.20012 8.09031 5.41012ZM6.50031 16.0001V7.00012H4.50031V16.0001C4.50031 18.7601 6.7403 21.0001 9.5003 21.0001H15.5003V19.0001H9.5003C7.84031 19.0001 6.50031 17.6601 6.50031 16.0001ZM20.5003 20.0701L15.4303 15.0001H12.0003V11.3201C13.4003 12.4701 15.6003 13.4801 17.5003 13.4801V11.3201C15.8403 11.3401 13.8903 10.4501 12.8303 9.28012L11.4303 7.73012C11.2403 7.52012 11.0003 7.35012 10.7403 7.23012C10.4503 7.09012 10.1203 7.00012 9.7803 7.00012H9.7503C8.5103 7.00012 7.5003 8.01012 7.5003 9.25012V15.0001C7.5003 16.6601 8.8403 18.0001 10.5003 18.0001H15.5703L19.0703 21.5001L20.5003 20.0701Z'
      fill={color}
    />
  </SvgIcon>
)

const SeatTakenIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <SeatTakenDarkIcon {...props} /> : <SeatTakenLightIcon {...props} />
}

export default SeatTakenIcon
